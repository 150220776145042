import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Badge, Spinner } from "react-bootstrap";
import axios from "axios";
import moment from "moment";

import { useQuery } from "../../hooks/useQuery";
import { URL } from "../../api";

const Details = ({ appointment }) => {
  const status = {
    Approved: <Badge variant="success">Approved</Badge>,
    Pending: <Badge variant="warning">Pending</Badge>,
    Cancelled: <Badge variant="danger">Cancelled</Badge>,
  };
  return (
    <div className="text-light p-4 bg-secondary">
      <h4>Details</h4>

      <h5 className="mt-4">
        <span className="mr-3">Date:</span>
        {moment(appointment.dateAndTime).format(
          "dddd, MMMM Do, YYYY [at] h:mm a"
        )}
      </h5>

      <h5 className="mt-4 d-flex flex-wrap align-items-center">
        <span className="mr-3">Status:</span>{" "}
        {status[appointment?.status ?? "Pending"]}
      </h5>
    </div>
  );
};

const AppointmentOperation = () => {
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(null);
  const [appointment, setAppointment] = useState(null);
  const { id } = useParams();
  const query = useQuery();

  const operation = query.get("operation");

  useEffect(() => {
    axios
      .get(`${URL}/appointments/appointment/${id}?operation=${operation}`)
      .then((res) => {
        setAppointment(res.data.appointment);
        setLoading(false);
      })
      .catch(() => {
        setHasError(true);
      });
  }, []);

  return (
    <div className="container my-4">
      <h1 className="text-center text-primary mb-3">Appointment</h1>

      {loading ? (
        <div className="d-flex justify-content-center my-5">
          <Spinner
            animation="border"
            variant="secondary"
            style={{ width: "4rem", height: "4rem" }}
          />
        </div>
      ) : (
        <>
          {appointment && (
            <>
              <h5 className="text-secondary my-4">
                Appointment Has Been Updated, View Below:
              </h5>
              <Details appointment={appointment} />
            </>
          )}
          {hasError && (
            <>
              <h4 className="bg-danger text-light p-4 mt-5 mb-4 text-center">
                Cannot update appointment at this time, please login to your
                account and try there
              </h4>
              <div className="d-flex justify-content-center">
                <Link to="/login" className="btn btn-lg btn-secondary">
                  Login
                </Link>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default AppointmentOperation;
