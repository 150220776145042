import React from "react";

const StudentStatus = ({ user }) => {
  const paymentURL = {
    FULL_AMOUNT: {
      "Basic Package": "https://link.waveapps.com/jxx5zc-rnmyek",
      "Preferred Package": "https://link.waveapps.com/6x4vnd-y7egg5",
    },
    PARTIAL_AMOUNT: {
      "Basic Package": "https://link.waveapps.com/jxd4x3-czd32x",
      "Preferred Package": "https://link.waveapps.com/6afh9b-k2934w",
    },
  };

  const URL = ({ paymentAmount, packageName }) => `
      ${paymentURL[paymentAmount][packageName]}?first_name=${
    user?.firstName
  }&last_name=${user?.lastName}&email=${
    user?.email
  }&address=${user?.address?.streetAddress?.replace(/ /g, "+")}&city=${
    user?.address?.city
  }&region=${user?.address?.province}&postal=${
    user?.address?.postalCode
  }&country=CA`;

  return (
    <div className="student-status">
      <div>
        <h4>
          Looks like your account status is{" "}
          <span className="bg-warning text-dark p-1">Pending</span>, meaning you
          can't book your appointments just yet. If you have not already done
          so, please make a payment by clicking on of the links below:{" "}
        </h4>
        <div className="student-status-links">
          <a
            href={URL({
              paymentAmount: "FULL_AMOUNT",
              packageName: user?.config.selectedPackage?.name,
            })}
            target="_blank"
            rel="noopener noreferrer"
            className="text-secondary"
          >
            Make a Full Payment
          </a>
          <a
            href={URL({
              paymentAmount: "PARTIAL_AMOUNT",
              packageName: user?.config.selectedPackage?.name,
            })}
            target="_blank"
            rel="noopener noreferrer"
            className="text-secondary"
          >
            Make a Partial Payment
          </a>
        </div>
      </div>
    </div>
  );
};

export default StudentStatus;
