import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import { GrEdit } from "react-icons/gr";
import moment from "moment";

import InstructorAndSession from "./booking/sections/InstructorAndSession";
import DateAndTime from "./booking/sections/DateAndTime";
import PickupLocation from "./booking/sections/PickupLocation";

const AppointmentEditModal = ({
  appointment: currentAppointment,
  show,
  close,
  role,
  onEdit,
}) => {
  const { instructors = [], sessionTypes = [] } = useSelector(
    (state) => state.schedule.settings
  );

  const [appointment, setAppointment] = useState({
    _id: currentAppointment._id,
    pickupLocation: currentAppointment.pickupLocation,
    instructorID: currentAppointment.instructorID._id,
    instructorName: `${currentAppointment.instructorID.firstName} ${currentAppointment.instructorID.lastName}`,
    dateAndTime: new Date(currentAppointment.dateAndTime),
    sessionID: currentAppointment.sessionID._id,
    sessionName: currentAppointment.sessionID.name,
    publicNotes: currentAppointment.publicNotes,
  });

  const onChange = (e) => {
    const id = e.target.id;
    const value = e.target.value;

    setAppointment((prevState) => ({ ...prevState, [id]: value }));
  };

  const onSelect = (e) => {
    const id = e.target.id;
    const value = e.target.value;
    const text = e.target.selectedOptions;

    setAppointment((prevState) => ({
      ...prevState,
      [id]: value,
      [id === "instructorID" ? "instructorName" : "sessionName"]:
        text[0].innerText,
    }));
  };

  const onAddressChange = (e) => {
    const id = e.target.id;
    const value = e.target.value;

    setAppointment((prevState) => ({
      ...prevState,
      pickupLocation: { ...prevState.pickupLocation, [id]: value },
    }));
  };

  const onAddressAutoFill = (address) => {
    setAppointment((prevState) => ({
      ...prevState,
      pickupLocation: address,
    }));
  };

  const onOwnAddressSelect = (address) => {
    address
      ? setAppointment((prevState) => ({
          ...prevState,
          pickupLocation: address,
        }))
      : setAppointment((prevState) => ({
          ...prevState,
          pickupLocation: {
            streetAddress: "",
            city: "",
            province: "",
            postalCode: "",
          },
        }));
  };

  const handleDateSelect = (date) => {
    setAppointment((prevState) => ({ ...prevState, dateAndTime: date }));
  };
  const handleTimeSelect = (time) => {
    const addedHoursDate = moment(appointment.dateAndTime)
      .startOf("day")
      .add(time, "hours")
      .toDate();

    setAppointment((prevState) => ({
      ...prevState,
      dateAndTime: addedHoursDate,
    }));
  };

  const handleClose = () => {
    close();
  };

  const handleEdit = () => {
    onEdit(appointment);
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <GrEdit className="text-danger mb-1" /> Edit Appointment
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <InstructorAndSession
          instructorList={instructors}
          sessionList={sessionTypes}
          onSelect={onSelect}
          selectedInstructor={appointment.instructorID}
          selectedSession={appointment.sessionID}
        />

        <DateAndTime
          instructor={appointment.instructorID}
          handleDateSelect={handleDateSelect}
          handleTimeSelect={handleTimeSelect}
          date={appointment.dateAndTime}
        />

        <PickupLocation
          address={appointment.pickupLocation}
          notes={appointment.publicNotes}
          onAddressChange={onAddressChange}
          onChange={onChange}
          onOwnAddressSelect={onOwnAddressSelect}
          onAddressAutoFill={onAddressAutoFill}
        />
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" onClick={handleEdit}>
          Edit
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AppointmentEditModal;
