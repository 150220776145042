import React, { useState } from "react";
import { Form, Col } from "react-bootstrap";
import DateTimePicker from "react-datetime-picker";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

const TestInfoSection = () => {
  const [dateAndTime, setDateAndTime] = useState(null);
  const { register, setValue } = useFormContext();
  const { t } = useTranslation(null, {
    keyPrefix:
      "register.steps.packageSelection.carRental.additionalInformation",
  });

  const handleSetDate = (date) => {
    setValue("testInfo.dateAndTime", date);
    setDateAndTime(date);
  };

  return (
    <Form.Row>
      <Col xl={3}>
        <Form.Group>
          <Form.Label style={{ display: "block" }}>
            {t("dateAndTime.label")}
          </Form.Label>

          <DateTimePicker onChange={handleSetDate} value={dateAndTime} />
        </Form.Group>
      </Col>

      <Col xl={7}>
        <Form.Group>
          <Form.Label>{t("location.label")}</Form.Label>
          <Form.Control
            type="text"
            placeholder={t("location.placeholder")}
            {...register("testInfo.location")}
          />
        </Form.Group>
      </Col>

      <Col xl={2}>
        <Form.Group controlId="testInfo.type">
          <Form.Label>{t("testType.label")}</Form.Label>
          <Form.Control
            as="select"
            onChange={(e) => setValue("testInfo.type", e.target.value)}
            defaultValue=""
          >
            <option disabled value="">
              {t("testType.placeholder")}
            </option>
            <option value="G2">G2</option>
            <option value="G">G</option>
          </Form.Control>
        </Form.Group>
      </Col>
    </Form.Row>
  );
};

export default TestInfoSection;
