import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import Select from "react-select";
import { Controller, useFormContext } from "react-hook-form";

const ScheduleItem = ({ index }) => {
  const dayOptions = [
    { label: "Monday", value: 1 },
    { label: "Tuesday", value: 2 },
    { label: "Wednesday", value: 3 },
    { label: "Thursday", value: 4 },
    { label: "Friday", value: 5 },
    { label: "Saturday", value: 6 },
    { label: "Sunday", value: 0 },
  ];

  const timeOptions = [
    { label: "12:00am", value: 0 },
    { label: "1:00am", value: 1 },
    { label: "2:00am", value: 2 },
    { label: "3:00am", value: 3 },
    { label: "4:00am", value: 4 },
    { label: "5:00am", value: 5 },
    { label: "6:00am", value: 6 },
    { label: "7:00am", value: 7 },
    { label: "8:00am", value: 8 },
    { label: "9:00am", value: 9 },
    { label: "10:00am", value: 10 },
    { label: "11:00am", value: 11 },
    { label: "12:00pm", value: 12 },
    { label: "1:00pm", value: 13 },
    { label: "2:00pm", value: 14 },
    { label: "3:00pm", value: 15 },
    { label: "4:00pm", value: 16 },
    { label: "5:00pm", value: 17 },
    { label: "6:00pm", value: 18 },
    { label: "7:00pm", value: 19 },
    { label: "8:00pm", value: 20 },
    { label: "9:00pm", value: 21 },
    { label: "10:00pm", value: 22 },
    { label: "11:00pm", value: 23 },
  ];

  return (
    <Row>
      <Col lg>
        <Form.Group>
          <Form.Label>Day of the Week</Form.Label>

          <Controller
            name={`config.schedule.${index}.day`}
            render={({ field: { value, onChange } }) => (
              <Select
                placeholder="Select a Day in the Week"
                options={dayOptions}
                onChange={({ value }) => onChange(value)}
                value={dayOptions.find((day) => day.value === value)}
              />
            )}
          />
        </Form.Group>
      </Col>

      <Col lg>
        <Form.Group>
          <Form.Label>From</Form.Label>

          <Controller
            name={`config.schedule.${index}.from`}
            render={({ field: { value, onChange } }) => (
              <Select
                placeholder="From..."
                options={timeOptions}
                onChange={({ value }) => onChange(value)}
                value={timeOptions.find((time) => time.value === value)}
              />
            )}
          />
        </Form.Group>
      </Col>

      <Col lg>
        <Form.Group>
          <Form.Label>To</Form.Label>

          <Controller
            name={`config.schedule.${index}.to`}
            render={({ field: { value, onChange } }) => (
              <Select
                placeholder="To..."
                options={timeOptions}
                onChange={({ value }) => onChange(value)}
                value={timeOptions.find((time) => time.value === value)}
              />
            )}
          />
        </Form.Group>
      </Col>
    </Row>
  );
};

export default ScheduleItem;
