import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import { StepContext } from "..";
import { stepsList } from "../../../utils/register";

const GetStarted = () => {
  const { t } = useTranslation(null, { keyPrefix: "register" });
  const { onStepChange } = useContext(StepContext);

  return (
    <div>
      <h3 className="mb-4">{t("steps.gettingStarted.title")}</h3>

      <h5>{t("steps.gettingStarted.subtitle")}</h5>

      <div className="register-get-started-section">
        {stepsList()
          .slice(1)
          .map(({ id, title, required }) => (
            <div
              className="get-started-item"
              key={id}
              onClick={() => onStepChange(id)}
            >
              <div>{id}.</div>

              <div>
                <span>{title}</span>
                {!required && (
                  <small className="d-block text-success">
                    * {t("steps.driversLicense.optional")}
                  </small>
                )}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default GetStarted;
