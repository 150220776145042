import React from "react";
import { Form, Col, Button } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import SignatureCanvas from "react-signature-canvas";
import { useTranslation } from "react-i18next";

const ReleaseSection = () => {
  const {
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation(null, {
    keyPrefix: "register.steps.releaseStatement",
  });

  let signatureRef = null;

  const signatureActions = {
    onSign: (signatureRef) => {
      const signatureBase64 = signatureRef
        .getTrimmedCanvas()
        .toDataURL("image/jpg");

      setValue("eSignature", signatureBase64, { shouldValidate: true });
    },
    clearSignature: (signatureRef) => {
      signatureRef.clear();

      setValue("eSignature", "", { shouldValidate: true });
    },
  };

  const handleAcceptReleaseStatement = (e) => {
    const didAccept = e.target.checked;

    setValue("acceptedReleaseStatement", didAccept, { shouldValidate: true });
  };

  const selectedPackage = getValues("selectedPackage");

  const releaseStatementText =
    selectedPackage && [2, 3].includes(selectedPackage?.id)
      ? "statement.text.private"
      : "statement.text.nonPrivate";

  return (
    <>
      <Form.Row>
        <Col lg>
          <Form.Group>
            <Form.Check
              type="checkbox"
              onChange={handleAcceptReleaseStatement}
              label={t(releaseStatementText)}
            />
            {errors.acceptedReleaseStatement && (
              <p className="text-danger font-weight-bold">
                * {t("statement.error")}
              </p>
            )}
          </Form.Group>
        </Col>
      </Form.Row>

      <Form.Row>
        <Col lg>
          <Form.Group>
            <Form.Label>{t("signature.label")}</Form.Label>
            <div className="">
              <SignatureCanvas
                penColor="black"
                canvasProps={{
                  width: 320,
                  height: 200,
                  className: "sigCanvas canvas",
                }}
                ref={(ref) => {
                  signatureRef = ref;
                }}
                onEnd={() => signatureActions.onSign(signatureRef)}
              />
            </div>

            {errors.eSignature && (
              <p className="text-danger font-weight-bold">
                * {t("signature.error")}
              </p>
            )}

            <Button
              variant="primary"
              size="sm"
              onClick={() => signatureActions.clearSignature(signatureRef)}
            >
              {t("signature.button")}
            </Button>
          </Form.Group>
        </Col>
      </Form.Row>
    </>
  );
};

export default ReleaseSection;
