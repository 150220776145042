import React from "react";
import { Form, Col, Button } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import SignatureCanvas from "react-signature-canvas";
import Error from "../../layout/InputError";

const SignatureArea = ({ index }) => {
  const {
    setValue,
    formState: { errors },
  } = useFormContext();

  let studentSignatureRef = null;
  let instructorSignatureRef = null;

  const onSign = (name, ref) => {
    const signatureBase64 = ref.getTrimmedCanvas().toDataURL("image/jpg");

    setValue(`sessions.${index}.${name}`, signatureBase64, {
      shouldValidate: true,
    });
  };

  const clearSignature = (name, ref) => {
    setValue(`sessions.${index}.${name}`, "");
    ref.clear();
  };

  const { student, instructor } = errors?.sessions?.[index] ?? {};

  return (
    <Form.Row>
      <Col md className="d-flex justify-content-center">
        <div>
          <Form.Label>Student Signature</Form.Label>

          <div className="session-signature-item mb-3">
            <SignatureCanvas
              onEnd={() => onSign("student.signature", studentSignatureRef)}
              penColor="black"
              canvasProps={{
                className: "sigCanvas canvas",
                width: 320,
                height: 250,
              }}
              ref={(ref) => {
                studentSignatureRef = ref;
              }}
            />
            <Button
              variant="outline-secondary"
              size="sm"
              onClick={() =>
                clearSignature("student.signature", studentSignatureRef)
              }
              className="mt-2"
            >
              Clear Signature
            </Button>

            {student?.signature && (
              <Error message={student.signature.message} />
            )}
          </div>
        </div>
      </Col>
      <Col md className="d-flex justify-content-center">
        <div>
          <Form.Label>Instructor Signature</Form.Label>

          <div className="session-signature-item mb-3">
            <SignatureCanvas
              onEnd={() =>
                onSign("instructor.signature", instructorSignatureRef)
              }
              penColor="black"
              canvasProps={{
                className: "sigCanvas canvas",
                width: 320,
                height: 250,
              }}
              ref={(ref) => {
                instructorSignatureRef = ref;
              }}
            />
            <Button
              variant="outline-secondary"
              size="sm"
              onClick={() =>
                clearSignature("instructorSignature", instructorSignatureRef)
              }
              className="mt-2"
            >
              Clear Signature
            </Button>

            {instructor?.signature && (
              <Error message={instructor.signature.message} />
            )}
          </div>
        </div>
      </Col>
    </Form.Row>
  );
};

export default SignatureArea;
