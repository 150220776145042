import React, { useState, forwardRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import {
  Modal,
  Button,
  ListGroup,
  Form,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import moment from "moment";
import { AiFillClockCircle, AiFillDelete } from "react-icons/ai";
import Select from "react-select";
import TimeRangePicker from "@wojtekmaj/react-timerange-picker";

import { addTimeOff, deleteTimeOff } from "../../actions/authActions";

const TimeOffModal = ({ show, handleClose, loading }) => {
  const dispatch = useDispatch();
  const { timeOff = [] } = useSelector((state) => state.auth.profile?.config);

  const upcomingTimeOff = timeOff.filter(
    (range) => new Date(range?.endDate) > new Date()
  );

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [every, setEvery] = useState(0);
  const [daysOfTheWeek, setDaysOfTheWeek] = useState([]);
  const [timeRange, setTimeRange] = useState([]);

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleAdd = async () => {
    const data = {
      startDate,
      endDate,
      every,
      daysOfTheWeek,
      timeRange,
    };

    dispatch(addTimeOff(data));
  };

  const handleDelete = async (id) => {
    dispatch(deleteTimeOff(id));
  };

  const handleEveryChange = (e) => {
    const value = parseInt(e.target.value);

    if (value < 0) return;

    setEvery(value);
  };

  const DateSelect = forwardRef(({ onClick, onChange, value, text }, ref) => (
    <Form.Control
      type="text"
      placeholder={text}
      ref={ref}
      onClick={onClick}
      onChange={onChange}
      value={
        moment(value).isValid()
          ? moment(value).format("dddd, MMMM Do, h:mm a")
          : ""
      }
      required
    />
  ));

  const EndDateSelect = forwardRef(({ onClick }, ref) => (
    <Button variant="outline-secondary" onClick={onClick} ref={ref} size="sm">
      Select End Date
    </Button>
  ));

  return (
    <Modal show={show} onHide={handleClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title className="text-secondary">
          <AiFillClockCircle /> Time Off
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-secondary">
        <div>
          <div className="add-time-off">
            <h5 className="text-primary mb-3">Add New Time Off</h5>

            <div className="add-time-wrapper my-3">
              <div className="add-time-item">
                <span>From:</span>{" "}
                <DatePicker
                  selected={startDate}
                  onChange={handleStartDateChange}
                  showTimeSelect
                  startDate={startDate}
                  endDate={endDate}
                  dateFormat="MMMM d, yyyy h:mm aa"
                  customInput={<DateSelect text="Select Start Date" />}
                  minDate={new Date()}
                  popperModifiers={{
                    preventOverflow: {
                      enabled: true,
                    },
                  }}
                />
              </div>
              <div className="add-time-item">
                <span>To:</span>{" "}
                <DatePicker
                  selected={endDate}
                  onChange={handleEndDateChange}
                  showTimeSelect
                  startDate={startDate}
                  endDate={endDate}
                  dateFormat="MMMM d, yyyy h:mm aa"
                  customInput={<DateSelect text="Select End Date" />}
                  minDate={startDate || new Date()}
                  popperModifiers={{
                    preventOverflow: {
                      enabled: true,
                    },
                  }}
                />
              </div>

              <div className="add-time-item">
                <span>Every:</span>

                <div
                  style={{
                    display: "grid",
                    gap: "1rem",
                    gridTemplateColumns: "1fr 1fr 3fr",
                  }}
                >
                  <Button
                    variant={every === 1 ? "primary" : "outline-primary"}
                    onClick={() => setEvery(1)}
                  >
                    Other Day
                  </Button>
                  <Button
                    variant={every === 3 ? "primary" : "outline-primary"}
                    onClick={() => setEvery(3)}
                  >
                    3 Days
                  </Button>
                  <InputGroup>
                    <FormControl
                      placeholder="Day..."
                      value={every}
                      type="number"
                      onChange={handleEveryChange}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>day</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </div>
              </div>

              <div className="add-time-item">
                <span>Day(s) of the Week:</span>
                <Select
                  placeholder="Select Day(s) of the Week..."
                  options={[
                    "Sunday",
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday",
                  ].map((day, i) => ({ label: day, value: i }))}
                  isMulti
                  value={daysOfTheWeek}
                  onChange={(option) => setDaysOfTheWeek(option)}
                />
              </div>

              <div className="add-time-item">
                <span>Time Range:</span>

                <div>
                  <TimeRangePicker onChange={setTimeRange} value={timeRange} />
                </div>
              </div>
            </div>

            <Button
              variant="primary"
              size="sm"
              disabled={!startDate || !endDate}
              onClick={handleAdd}
              block
            >
              Add New Time Off
            </Button>

            <Button
              variant="secondary"
              size="sm"
              disabled={!startDate && !endDate}
              onClick={() => {
                setStartDate(null);
                setEndDate(null);
              }}
              block
            >
              Clear Dates
            </Button>
          </div>

          <div className="upcoming-time-off">
            <h5 className="text-primary mt-5">Upcoming Times Off</h5>

            <div
              style={{
                height: "200px",
                overflowY: "scroll",
                border: "1px solid rgba(0,0,0,0.2)",
              }}
            >
              <ListGroup>
                {upcomingTimeOff.length === 0 && (
                  <ListGroup.Item className="text-center">
                    No Upcoming Times Off
                  </ListGroup.Item>
                )}
                {upcomingTimeOff.length > 0 &&
                  upcomingTimeOff.map((date) => (
                    <ListGroup.Item key={date.id}>
                      <div className="time-item">
                        <div>
                          {moment(date.startDate).isSame(date.endDate) ? (
                            <u>
                              {" "}
                              {moment(date.startDate).format(
                                "dd, MMMM Do h:mm a h:mm a"
                              )}
                            </u>
                          ) : (
                            <>
                              <u>
                                {moment(date.startDate).format(
                                  "ddd, MMM Do h:mm a"
                                )}
                              </u>{" "}
                              to{" "}
                              <u>
                                {moment(date.endDate).format(
                                  "ddd, MMM Do h:mm a"
                                )}
                              </u>
                            </>
                          )}
                        </div>

                        <h6 className="text-danger">
                          <AiFillDelete
                            style={{ cursor: "pointer" }}
                            onClick={() => handleDelete(date.id)}
                          />
                        </h6>
                      </div>
                    </ListGroup.Item>
                  ))}
              </ListGroup>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        {/* <Button variant="primary" disabled={loading} onClick={onSubmit}>
          {loading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            "Add Time Off"
          )}
        </Button> */}
      </Modal.Footer>
    </Modal>
  );
};

export default TimeOffModal;
