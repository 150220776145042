import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  Button,
  Card,
  Accordion,
  Badge,
  ListGroup,
  Table,
} from "react-bootstrap";
import moment from "moment";
import { AiFillCalendar, AiFillClockCircle } from "react-icons/ai";
import { FaRegStickyNote } from "react-icons/fa";
import { GrCircleInformation } from "react-icons/gr";

export default class Sessions extends Component {
  static propTypes = {
    list: PropTypes.array,
  };

  render() {
    const { list } = this.props;

    return (
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th></th>
            <th>Date</th>
            <th>Time</th>
            <th>Status</th>
            <th>Notes</th>
            <th>Student Signature</th>
            <th>Instructor Signature</th>
          </tr>
        </thead>
        <tbody>
          {list
            .sort((a, b) => a.title - b.title)
            .filter((item) => item.status !== "")
            .map((item, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>{`${moment(item.start).format("dddd, MMMM Do, YYYY")}`}</td>
                <td>
                  {moment()
                    .startOf("day")
                    .add(moment(item.start).hour(), "hours")
                    .add(moment(item.start).minute(), "minutes")
                    .format("h:mm a")}{" "}
                  to{" "}
                  {moment()
                    .startOf("day")
                    .add(moment(item.end).hour(), "hours")
                    .add(moment(item.end).minute(), "minutes")
                    .format("h:mm a")}
                </td>
                <td>
                  <Badge
                    variant={
                      item.status === "completed" ? "success" : "warning"
                    }
                  >
                    {item.status}
                  </Badge>
                </td>
                <td style={{ width: "225px" }}>{item.notes}</td>
                <td>
                  <img
                    src={item.student?.signature}
                    alt="Student Signature"
                    style={{ maxWidth: "100px" }}
                  />
                </td>
                <td>
                  <img
                    src={item.instructor?.signature}
                    alt="Instructor Signature"
                    style={{ maxWidth: "100px" }}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    );
  }

  //   return (
  //     <div className="session-list">
  //       {list
  //         .sort((a, b) => a.title - b.title)
  //         .filter((item) => item.status !== "")
  //         .map((item) => (
  //           <div key={item._id} style={{ margin: "2rem 0", padding: "0 2rem" }}>
  //             <Card>
  //               <Card.Header>{item.title}</Card.Header>

  //               <Card.Body>
  //                 <div className="session-item-detail">
  //                   <label htmlFor="student">Student: </label>{" "}
  //                   {`${item.student?.id?.firstName} ${item.student?.id?.lastName}`}
  //                 </div>

  //                 <div className="session-item-detail">
  //                   <label htmlFor="student">Instructor: </label>{" "}
  //                   {`${item.instructor?.id?.firstName} ${item.instructor?.id?.lastName}`}
  //                 </div>
  //                 <div className="session-item-detail">
  //                   <AiFillCalendar />{" "}
  //                   {moment(item.start).format("dddd, MMMM Do, YYYY")}
  //                 </div>

  //                 <div className="session-item-detail">
  //                   <AiFillClockCircle />{" "}
  //                   {moment()
  //                     .startOf("day")
  //                     .add(moment(item.start).hour(), "hours")
  //                     .add(moment(item.start).minute(), "minutes")
  //                     .format("h:mm a")}{" "}
  //                   to{" "}
  // {moment()
  //   .startOf("day")
  //   .add(moment(item.end).hour(), "hours")
  //   .add(moment(item.end).minute(), "minutes")
  //   .format("h:mm a")}
  //                 </div>

  //                 <div className="session-item-detail">
  //                   <p>
  //                     <GrCircleInformation />{" "}
  //                     <Badge
  //                       variant={
  //                         item.status === "Completed" ? "success" : "warning"
  //                       }
  //                     >
  //                       {item.status}
  //                     </Badge>
  //                   </p>
  //                 </div>

  //                 <div className="session-item-detail">
  //                   <FaRegStickyNote />
  //                   <br />
  //                   {item.notes || "No Notes..."}
  //                 </div>

  //                 {item.student?.signature && (
  //                   <div className="session-item-detail">
  //                     Student Signature:
  //                     <br />
  //                     <img
  //                       src={item.student?.signature}
  //                       alt="Student Signature"
  //                       className="session-signature"
  //                     />
  //                   </div>
  //                 )}

  //                 {item.instructor?.signature && (
  //                   <div className="session-item-detail">
  //                     Your Signature:
  //                     <br />
  //                     <img
  //                       src={item.instructor?.signature}
  //                       alt="Student Initials"
  //                       className="session-signature"
  //                     />
  //                   </div>
  //                 )}
  //               </Card.Body>
  //             </Card>
  //           </div>
  //         ))}
  //       {/* <ListGroup.Item>Cras justo odio</ListGroup.Item> */}
  //     </div>
  //   );
  // }
}

// const Sessions = ({ list }) => {
//   return (
//     <Accordion>
//       {list
//         .sort((a, b) => a.title - b.title)
//         .filter((item) => item.status === "Completed")
//         .map((item) => (
//           <Card key={item._id}>
//             <Card.Header>
//               <Accordion.Toggle
//                 as={Button}
//                 variant="link"
//                 eventKey={item._id}
//                 style={{ fontSize: "clamp(1.25rem, 2vw, 1.5rem)" }}
//               >
//                 {item.title}
//               </Accordion.Toggle>
//             </Card.Header>

//             <Accordion.Collapse eventKey={item._id}>
//               <Card.Body>
//                 <div className="session-item-detail">
//                   <AiFillCalendar />{" "}
//                   {moment(item.start).format("dddd, MMMM Do, YYYY")}
//                 </div>

//                 <div className="session-item-detail">
//                   <AiFillClockCircle />{" "}
//                   {moment()
//                     .startOf("day")
//                     .add(moment(item.start).hour(), "hours")
//                     .add(moment(item.start).minute(), "minutes")
//                     .format("h:mm a")}{" "}
//                   to{" "}
//                   {moment()
//                     .startOf("day")
//                     .add(moment(item.end).hour(), "hours")
//                     .add(moment(item.end).minute(), "minutes")
//                     .format("h:mm a")}
//                 </div>

//                 <div className="session-item-detail">
//                   <p>
//                     <GrCircleInformation />{" "}
//                     <Badge
//                       variant={
//                         item.status === "Completed" ? "success" : "warning"
//                       }
//                     >
//                       {item.status}
//                     </Badge>
//                   </p>
//                 </div>

//                 <div className="session-item-detail">
//                   <FaRegStickyNote />
//                   <br />
//                   {item.notes || "No Notes..."}
//                 </div>

//                 {item.student?.signature && (
//                   <div className="session-item-detail">
//                     Student Signature:
//                     <br />
//                     <img
//                       src={item.student?.signature}
//                       alt="Student Signature"
//                       className="session-signature"
//                     />
//                   </div>
//                 )}

//                 {item.instructor?.signature && (
//                   <div className="session-item-detail">
//                     Your Signature:
//                     <br />
//                     <img
//                       src={item.instructor?.signature}
//                       alt="Student Initials"
//                       className="session-signature"
//                     />
//                   </div>
//                 )}
//               </Card.Body>
//             </Accordion.Collapse>
//           </Card>
//         ))}
//     </Accordion>
//   );
// };

// export default Sessions;
