import {
  GET_TOTAL_HOURS,
  FILTER_HOURS,
  SORT_HOURS,
  SET_PAYROLL_LOADING,
  SET_ERRORS,
  CLEAR_ERRORS
} from "../actions/types";

const initialState = {
  hours: [],
  sortedHours: [],
  filteredHours: [],
  totalHours: 0,
  payrollError: null,
  payrollLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_TOTAL_HOURS:
      return {
        ...state,
        hours: action.payload,
        payrollLoading: false
      };

    case FILTER_HOURS:
      return {
        ...state,
        filteredHours: state.hours.filter(item => item === action.payload),
        payrollLoading: false
      };

    case SORT_HOURS:
      return {
        ...state,
        sortedHours: state.hours.sort((a, b) => a - b),
        payrollLoading: false
      };

    case SET_PAYROLL_LOADING:
      return {
        ...state,
        payrollLoading: true
      };

    case SET_ERRORS:
      return {
        ...state,
        payrollError: action.payload,
        payrollLoading: false
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        payrollError: null
      };

    default:
      return state;
  }
};
