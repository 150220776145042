import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";

import {
  AiOutlineUsergroupAdd,
  AiOutlineUsergroupDelete,
} from "react-icons/ai";

import { getAppointments } from "../../../actions/scheduleActions";
import Button from "../../layout/ButtonWithIcon";

const AppointmentBar = () => {
  const dispatch = useDispatch();
  const { role } = useSelector((state) => state.auth?.profile?.config);
  const loading = useSelector((state) => state.schedule?.scheduleLoading);
  const instructors =
    useSelector((state) => state.schedule?.settings?.instructors) || [];

  const [selected, setSelected] = useState([]);

  useEffect(() => {
    dispatch(
      getAppointments(
        role,
        selected.map((curr) => curr.value)
      )
    );
  }, [selected, dispatch, role]);

  const mapInstructors = () =>
    instructors.map((instructor) => ({
      label: `${instructor.firstName} ${instructor.lastName}`,
      value: instructor._id,
    }));

  const selectAll = () => setSelected(mapInstructors());

  const clear = () => {
    setSelected([]);
  };

  return (
    <div className="mb-3">
      <div style={{ padding: "1rem", border: "1px solid lightgrey" }}>
        <h5 className="text-secondary mb-3">Instructors Select</h5>
        <Select
          isMulti
          options={instructors.map((instructor) => ({
            label: `${instructor.firstName} ${instructor.lastName}`,
            value: instructor._id,
          }))}
          value={selected}
          placeholder="Select Instructors"
          onChange={setSelected}
          isLoading={loading}
          isDisabled={loading}
        />

        <div className="mt-3 d-flex">
          <Button
            text="Select All"
            icon={AiOutlineUsergroupAdd}
            variant="primary"
            onClick={selectAll}
            disabled={selected.length === instructors.length}
            className="mr-3"
          />
          <Button
            text="Clear"
            icon={AiOutlineUsergroupDelete}
            variant="secondary"
            onClick={clear}
            disabled={selected.length === 0}
          />
        </div>
      </div>
    </div>
  );
};

export default AppointmentBar;
