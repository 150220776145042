import React from "react";

import RequestForm from "./RequestForm";

const ResetPasswordRequest = () => {
  return (
    <div className="container mx-auto mt-4 mb-4">
      <h1 className="text-center text-secondary mb-3">Reset Password</h1>

      <RequestForm />
    </div>
  );
};

export default ResetPasswordRequest;
