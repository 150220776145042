import React from "react";
import { Badge } from "react-bootstrap";
import {
  AiFillCalendar,
  AiFillClockCircle,
  AiOutlineUser,
  AiFillCar,
  AiOutlineLink,
} from "react-icons/ai";
import { FaSearchLocation, FaRegStickyNote } from "react-icons/fa";
import { GrCircleInformation } from "react-icons/gr";
import moment from "moment";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const AppointmentItemDetails = ({
  appointment,
  role,
  showStatus,
  showSessionLog,
}) => {
  const getStatusVariant = (status) => {
    if (status === "Approved") return "success";

    if (status === "Cancelled") return "danger";

    if (status === "Pending") return "warning";
  };

  const { pickupLocation = {} } = appointment;
  const { streetAddress, city, province, postalCode } = pickupLocation;

  const pickupLocationURL = new URL("https://maps.google.com");
  pickupLocationURL.searchParams.set(
    "q",
    `${streetAddress} ${city} ${province} ${postalCode}`
  );

  return (
    <div className="appointment-content">
      {showStatus && (
        <div className="item">
          <GrCircleInformation className="icon" />

          <Badge variant={getStatusVariant(appointment.status)}>
            {appointment.status}
          </Badge>
        </div>
      )}

      <div className="item">
        <AiFillCar className="icon" />

        <strong>{appointment.sessionID?.name}</strong>
      </div>

      <div className="item">
        <AiOutlineUser className="icon" />

        <span>
          <strong>
            {role === "student" && (
              <>
                Appointment with {appointment.instructorID?.firstName}{" "}
                {appointment.instructorID?.lastName}
              </>
            )}

            {role === "instructor" && (
              <>
                Appointment with{" "}
                <Link to={`/users/${appointment.studentID._id}`}>
                  {appointment.studentID?.firstName}{" "}
                  {appointment.studentID?.lastName}
                </Link>
              </>
            )}

            {role === "admin" && (
              <>
                Instructor{" "}
                <Link to={`/users/${appointment.instructorID._id}`}>
                  {appointment.instructorID?.firstName}{" "}
                  {appointment.instructorID?.lastName}
                </Link>{" "}
                and Student{" "}
                <Link to={`/users/${appointment.studentID._id}`}>
                  {appointment.studentID?.firstName}{" "}
                  {appointment.studentID?.lastName}
                </Link>
              </>
            )}
          </strong>
        </span>
      </div>

      <div className="item">
        <AiFillCalendar className="icon" />
        <strong>
          {moment(appointment.dateAndTime).format("dddd, MMMM Do, YYYY")}
        </strong>
        <small>({moment(appointment.dateAndTime).from(new Date())})</small>
      </div>

      <div className="item">
        <AiFillClockCircle className="icon" />
        <strong>{moment(appointment.dateAndTime).format("h:mm a")}</strong>
        <small>
          (For{" "}
          {parseInt(appointment?.duration) === 1
            ? `${appointment?.duration} hour`
            : `${appointment?.duration} hours`}
          )
        </small>
      </div>

      <div className="item">
        <FaSearchLocation className="icon" />

        <span>
          Pickup Location:{" "}
          <strong>
            <a
              href={pickupLocationURL.href}
              target="_blank"
              rel="noopener noreferrer"
            >
              {`${appointment.pickupLocation?.streetAddress} ${appointment.pickupLocation?.city}, ${appointment.pickupLocation?.province}, ${appointment.pickupLocation?.postalCode}`}
            </a>
          </strong>
        </span>
      </div>

      <div className="item">
        <FaRegStickyNote className="icon" />
        <span>
          Notes: <strong>{appointment.publicNotes}</strong>
        </span>
      </div>

      {showSessionLog && (
        <div className="item">
          <AiOutlineLink className="icon" />
          <Link
            to={`/newsessionlog?sessionLog=${appointment.sessionID?.order}&student=${appointment.studentID?._id}`}
          >
            Go to Session Log #{appointment.sessionID?.order + 1}
          </Link>
        </div>
      )}
    </div>
  );
};

AppointmentItemDetails.propTypes = {
  appointment: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
  showStatus: PropTypes.bool,
  showSessionLog: PropTypes.bool,
};

AppointmentItemDetails.defaultProps = {
  showStatus: false,
  showSessionLog: true,
};

export default AppointmentItemDetails;
