import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Table, Spinner, Button } from "react-bootstrap";
import moment from "moment";

const BookingTimes = ({ handleSlotSelect, autoSelect }) => {
  const loading = useSelector((state) => state.schedule.scheduleLoading);
  const { instructorAvailability: availability = {} } = useSelector(
    ({ schedule }) => schedule.settings
  );

  const [automaticSelect, setAutomaticSelect] = useState(autoSelect);

  const [timeSlots, setTimeSlots] = useState([]);

  const [selectedTime, setSelectedTime] = useState("");

  const handleClick = (time) => {
    setSelectedTime(time);
    handleSlotSelect(time);
  };

  const differentTimeSelect = () => {
    setAutomaticSelect(false);
  };

  useEffect(() => {
    if (availability.availableThisDay) {
      const computedSlots = [];
      const filteredDates = availability.timeslots.filter(
        (time) => time.available
      );

      for (let i = 0; i < filteredDates.length; i += 5) {
        const split = filteredDates.slice(i, i + 5);

        computedSlots.push({ id: i, block: split });
      }

      setTimeSlots(computedSlots);

      if (automaticSelect && filteredDates.length > 0) {
        handleClick(filteredDates[0]?.time);
      }
    }
  }, [availability]);

  useEffect(() => {
    if (!availability.availableThisDay) {
      setTimeSlots([]);
      setSelectedTime("");
    }
  }, [availability.availableThisDay]);

  return automaticSelect && timeSlots.length > 0 ? (
    <div style={{ marginBottom: "2rem" }}>
      {loading ? (
        <Spinner animation="border" variant="primary" />
      ) : (
        <>
          <div>
            We have selected{" "}
            <strong>{moment(selectedTime).format("h:mm a")}</strong> as the best
            available time for you for this day
          </div>
          <Button
            style={{ marginTop: "1rem" }}
            size="sm"
            onClick={differentTimeSelect}
          >
            Select Different Time
          </Button>
        </>
      )}
    </div>
  ) : (
    <div
      style={{
        height: "100%",
        maxHeight: "250px",
        overflowY: "auto",
        marginBottom: "1rem",
      }}
    >
      <Table className="time-select-table text-center" responsive bordered>
        <tbody>
          {timeSlots.length === 0 && !loading && (
            <tr>
              <td colSpan="3" className="disabled">
                No Slots Available This Day
              </td>
            </tr>
          )}

          {loading && (
            <tr>
              <td colSpan="3" className="disabled">
                <Spinner animation="border" variant="primary" />
              </td>
            </tr>
          )}

          {availability.availableThisDay &&
            !loading &&
            timeSlots.map(({ id, block }) => (
              <tr key={id}>
                {block.map(({ available, time, label }) =>
                  available ? (
                    <td
                      key={time}
                      className={
                        time.toString() === selectedTime.toString()
                          ? "selected"
                          : "bg-secondary text-light"
                      }
                      onClick={() => handleClick(time)}
                    >
                      {label}
                    </td>
                  ) : (
                    <td key={time} className="bg-danger text-dark">
                      {moment(time).format("h:mm a")}
                    </td>
                  )
                )}
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  );
};

export default BookingTimes;
