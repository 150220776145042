import React from "react";
import moment from "moment";
import { Table, Badge, Row, Col } from "react-bootstrap";
import {
  AiOutlineUser,
  AiOutlineMail,
  AiOutlinePhone,
  AiFillCalendar,
  AiOutlineHome,
  AiOutlineIdcard,
  AiOutlineClockCircle,
  AiOutlineFolder,
  AiOutlineDollarCircle,
  AiOutlineFile,
} from "react-icons/ai";
import { HiOutlineCake } from "react-icons/hi";
import { FaGraduationCap } from "react-icons/fa";
import { MdPayment } from "react-icons/md";
import { useTranslation } from "react-i18next";

import { packages } from "../../utils/register";

const UserItemDetails = ({ user }) => {
  // const userPackage = packages().find(
  //   (packageItem) => packageItem.id === user.config?.selectedPackage?.id
  // );

  const userPackage = user.config?.selectedPackage;

  const { t } = useTranslation(null, {
    keyPrefix: "register.steps.releaseStatement",
  });

  const releaseStatement = [2, 3].includes(userPackage?.id)
    ? t("statement.text.private")
    : t("statement.text.nonPrivate");

  const { elearning_metadata } = user.config;

  return (
    <div className="appointment-content">
      <div className="item">
        {user.username}{" "}
        {user.config?.role === "student" && user.config?.isPrivate ? (
          <Badge variant="success">Private Student</Badge>
        ) : (
          <>({user.config?.role})</>
        )}
      </div>

      <div className="item">
        <AiOutlineUser className="icon" />

        <span>
          Name: <strong>{`${user.firstName} ${user.lastName}`}</strong>
        </span>
      </div>

      {user.dateOfBirth && (
        <div className="item">
          <HiOutlineCake className="icon" />

          <span>
            Date of Birth:{" "}
            <strong>{moment(user.dateOfBirth).format("MMMM Do, YYYY")}</strong>
          </span>
        </div>
      )}

      <div className="item">
        <AiOutlineMail className="icon" />

        <span>
          Email:{" "}
          <strong>
            <a href={`mailto:${user.email}`}>{user.email}</a>
          </strong>
        </span>
      </div>

      <div className="item">
        <AiOutlinePhone className="icon" />

        <span>
          Phone Number:{" "}
          <strong>
            <a href={`tel:${user.phoneNumber}`}>{user.phoneNumber}</a>
          </strong>
        </span>
      </div>

      <div className="item">
        <AiOutlineHome className="icon" />

        <span>
          Address:{" "}
          <strong>{`${user.address?.streetAddress}, ${user.address?.city}, ${user.address?.province}, ${user.address?.postalCode}`}</strong>
        </span>
      </div>

      <div className="item">
        <AiOutlineClockCircle className="icon" />

        <span>
          Registration Date:{" "}
          <strong>
            {moment(user.registrationDate).format("dddd, MMMM Do, YYYY")}
          </strong>
        </span>
      </div>

      {user?.driversLicense?.number && (
        <div className="item">
          <AiOutlineIdcard className="icon" />

          <span>Drivers License Information:</span>

          <Table bordered responsive>
            <thead>
              <tr>
                <th>License Number</th>
                <th>Exipry Date</th>
                <th>Image Front</th>
                <th>Image Back</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>
                  <strong>{user.driversLicense?.number}</strong>
                </td>
                <td>
                  <strong>
                    {moment(user.driversLicense?.expiryDate).format(
                      "YYYY/MM/DD"
                    )}
                  </strong>
                </td>
                <td>
                  {user.driversLicense?.imageFront && (
                    <img
                      style={{ width: "200px" }}
                      src={user.driversLicense?.imageFront}
                      alt="Drivers License"
                    />
                  )}
                </td>
                <td>
                  {user.driversLicense?.imageBack && (
                    <img
                      style={{ width: "200px" }}
                      src={user.driversLicense?.imageBack}
                      alt="Drivers License"
                    />
                  )}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      )}

      {userPackage && (
        <div className="item">
          <AiOutlineFolder className="icon" />
          <span>Package:</span>
          <Table bordered responsive size="sm">
            <thead>
              <tr>
                <th>Name</th>
                <th>Description</th>
                <th>Price</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>
                  <strong>{userPackage.name}</strong>
                </td>
                <td>
                  <strong>{userPackage.description}</strong>
                </td>
                <td>
                  <strong>${Number(userPackage.price).toFixed(2)}</strong>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      )}

      {user.config?.paymentMethod && (
        <div className="item">
          <MdPayment className="icon" />

          <span>
            Payment Method: <strong>{user.config?.paymentMethod}</strong>
          </span>
        </div>
      )}

      {user.config?.eSignature && (
        <div className="item">
          <AiOutlineFile className="icon" />

          <span>Release Statement and Signature:</span>

          <Row>
            <Col lg={9} className="mt-3">
              <small style={{ fontSize: "1rem" }}>{releaseStatement}</small>
            </Col>

            <Col
              lg={3}
              className="d-flex align-items-center justify-content-center mt-3"
            >
              <img
                src={user.config?.eSignature}
                alt="Signature"
                style={{
                  border: "1px solid lightgrey",
                  padding: "0.5rem",
                  maxWidth: "200px",
                }}
              />
            </Col>
          </Row>
        </div>
      )}

      {user.config?.schedule?.length > 0 && (
        <div className="item">
          <AiFillCalendar className="icon" />

          <span>Schedule:</span>
          <Table bordered responsive>
            <thead>
              <tr>
                {user.config?.schedule
                  .sort((a, b) => a.day - b.day)
                  .map(({ day }) => {
                    const d = [
                      "Sundays",
                      "Mondays",
                      "Tuesdays",
                      "Wednesdays",
                      "Thursdays",
                      "Fridays",
                      "Saturdays",
                    ];

                    return <th key={day}>{d[parseInt(day)]}</th>;
                  })}
              </tr>
            </thead>

            <tbody>
              <tr>
                {user.config?.schedule.map((item, i) => (
                  <td key={i}>
                    <strong>
                      {`${moment()
                        .startOf("day")
                        .add(item.from, "hours")
                        .format("h a")} to
                      ${moment()
                        .startOf("day")
                        .add(item.to, "hours")
                        .format("h a")}`}
                    </strong>
                  </td>
                ))}
              </tr>
            </tbody>
          </Table>
        </div>
      )}

      {user.config?.timeOff?.length > 0 && (
        <div className="item">
          <AiFillCalendar className="icon" />

          <span>Time Off:</span>
          <Table bordered responsive>
            <thead>
              <tr>
                <th>From</th>
                <th>To</th>
                <th>Requested On</th>
              </tr>
            </thead>

            <tbody>
              {user.config?.timeOff?.map((item, i) => (
                <tr key={i}>
                  {moment(item.startDate).isSame(item.endDate, "day") ? (
                    <td colSpan="2" className="text-center">
                      {moment(item.startDate).format("MMMM Do, YYYY")}
                    </td>
                  ) : (
                    <>
                      <td>{moment(item.startDate).format("MMMM Do, YYYY")}</td>
                      <td>{moment(item.endDate).format("MMMM Do, YYYY")}</td>
                    </>
                  )}

                  <td>{moment(item.timestamp).format("MMMM Do, YYYY")}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}

      {user.config?.payments?.length > 0 && (
        <div className="item">
          <AiOutlineDollarCircle className="icon" />

          <span>Payment Records:</span>
          <Table bordered responsive size="sm">
            <thead>
              <tr>
                <th>Method</th>
                <th>Amount</th>
                <th>Date</th>
                <th>Notes</th>
              </tr>
            </thead>

            <tbody>
              {user.config?.payments?.map((payment) => (
                <tr key={payment.id}>
                  <td>{payment.method}</td>
                  <td>${Number(payment.amount).toFixed(2)}</td>
                  <td> {moment(payment.date).format("dddd, MMMM Do, YYYY")}</td>
                  <td>{payment.notes}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}

      {elearning_metadata && (
        <div className="item">
          <FaGraduationCap className="icon" />

          <span>E-Learning:</span>

          <div style={{ width: "100%" }}>
            <div className="item mb-3">
              <span>Start Date:</span>
              <strong>
                {moment(elearning_metadata?.startDate).format(
                  "dddd, MMMM Do, YYYY"
                )}
              </strong>
            </div>

            <div className="item mb-4">
              <span>End Date:</span>
              <strong>
                {moment(elearning_metadata?.endDate).format(
                  "dddd, MMMM Do, YYYY"
                )}
              </strong>
            </div>

            <div className="item mb-3">
              <strong>Online Instructor</strong>

              <Table bordered responsive size="sm">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>License Number</th>
                    <th>License Expiry Date</th>
                    <th>Final Mark</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>{elearning_metadata?.online?.instructor?.name}</td>
                    <td>
                      {
                        elearning_metadata?.online?.instructor?.driversLicense
                          ?.number
                      }
                    </td>
                    <td>
                      {" "}
                      {moment(
                        elearning_metadata?.online?.instructor?.driversLicense
                          ?.expiryDate
                      ).format("DD-MM-yyyy")}
                    </td>
                    <td>{elearning_metadata?.online?.finalMark}</td>
                  </tr>
                </tbody>
              </Table>
            </div>

            <div className="item mb-3">
              <strong>In-Person Instructor</strong>

              <Table bordered responsive size="sm">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>License Number</th>
                    <th>License Expiry Date</th>
                    <th>Final Mark</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>{elearning_metadata?.inPerson?.instructor?.name}</td>
                    <td>
                      {
                        elearning_metadata?.inPerson?.instructor?.driversLicense
                          ?.number
                      }
                    </td>
                    <td>
                      {" "}
                      {moment(
                        elearning_metadata?.inPerson?.instructor?.driversLicense
                          ?.expiryDate
                      ).format("DD-MM-yyyy")}
                    </td>
                    <td>{elearning_metadata?.inPerson?.finalMark}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserItemDetails;
