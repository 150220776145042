import React from "react";
import { Popover, OverlayTrigger } from "react-bootstrap";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import {
  AiOutlineUser,
  AiFillClockCircle,
  AiFillCalendar,
} from "react-icons/ai";
import moment from "moment";
import { EventColors } from "../../utils/eventColors";

const EventPopover = ({ popoverProps, title, description }) => (
  <Popover {...popoverProps}>
    <Popover.Title as="h3">{title}</Popover.Title>
    <Popover.Content>
      <h6>
        <AiOutlineUser /> <strong>{description?.studentName}</strong>
      </h6>

      <h6>
        <AiFillCalendar />{" "}
        <strong>
          {moment(description?.dateAndTime).format("dddd, MMMM Do YYYY")}
        </strong>
      </h6>

      <h6>
        <AiFillClockCircle />{" "}
        <strong> {moment(description?.dateAndTime).format("h:mm a")}</strong>
      </h6>
    </Popover.Content>
  </Popover>
);

const Event = (e) => {
  const { event, backgroundColor, borderColor, textColor } = e;

  return (
    <OverlayTrigger
      trigger={["hover", "click", "focus"]}
      placement="auto"
      overlay={(props) => (
        <EventPopover
          popoverProps={props}
          title={event.title}
          description={{
            dateAndTime: event?.extendedProps?.dateAndTime,
            studentName: event?.extendedProps?.studentName,
          }}
        />
      )}
    >
      <div
        style={{
          cursor: "pointer",
          overflow: "hidden",
          backgroundColor,
          borderColor,
          color: textColor,
          padding: "0px 4px",
        }}
      >
        {event.title}
      </div>
    </OverlayTrigger>
  );
};

const DayCell = ({ dayNumberText }) => {
  return <div>{dayNumberText}</div>;
};

const Calendar = ({ appointments, onAdd, onEdit, onEventClick }) => {
  const appts = [
    ...appointments.map((appointment) => {
      const color = EventColors[appointment.status];

      const defaultColor = "#002872";

      return {
        title: appointment?.sessionID?.name,
        start: appointment?.dateAndTime,
        end: moment(appointment?.dateAndTime)
          .add(appointment?.duration, "hours")
          .toDate(),
        backgroundColor: color.backgroundColor || defaultColor,
        textColor: color.textColor || "white",
        extendedProps: {
          appointment,
          dateAndTime: appointment.dateAndTime,
          studentName: `${appointment?.studentID?.firstName} ${appointment?.studentID?.lastName}`,
        },
      };
    }),
  ];

  return (
    <FullCalendar
      plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
      initialView="dayGridMonth"
      headerToolbar={{
        left: "prev,next today",
        right: "title",
      }}
      footerToolbar={{
        left: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
      }}
      dayMaxEventRows
      views={{
        timeGrid: {
          dayMaxEventRows: 3,
        },
        dayGrid: {
          dayMaxEventRows: 3,
        },
      }}
      eventColor="#002872"
      eventContent={Event}
      eventClick={({ event }) =>
        onEventClick(event?.extendedProps?.appointment)
      }
      dateClick={({ date }) => onAdd(date)}
      dayCellClassNames="pointer"
      dayCellContent={DayCell}
      events={appts}
    />
  );
};

export default Calendar;
