import React from "react";
import { Tabs, Tab } from "react-bootstrap";
import { useFieldArray, useFormContext } from "react-hook-form";
import { AiFillDelete } from "react-icons/ai";

import Button from "../../../layout/ButtonWithIcon";
import PaymentItem from "./PaymentItem";

const Payments = () => {
  const { control } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "config.payments",
  });

  return (
    <>
      <h4 className="mt-4">Payment Records</h4>
      <Button
        text="Add New Payment Record"
        onClick={() => append()}
        className="mb-3"
        size="sm"
        variant="secondary"
      />
      <Tabs>
        {fields.map((field, i) => {
          return (
            <Tab
              key={field.id}
              title={
                <div className="d-flex">
                  <span className="mr-2">{`Record ${i + 1}`} </span>
                  <Button
                    icon={AiFillDelete}
                    variant="outline-danger"
                    size="sm"
                    onClick={() => remove(i)}
                  />
                </div>
              }
              eventKey={`session${i}`}
              className="mt-2"
            >
              <PaymentItem index={i} />
            </Tab>
          );
        })}
      </Tabs>
    </>
  );
};

export default Payments;
