import React from "react";
import { Button, ButtonGroup, Spinner } from "react-bootstrap";
import {
  BsFillArrowLeftCircleFill,
  BsFillArrowRightCircleFill,
  BsArrowRight,
} from "react-icons/bs";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { stepsList } from "../../../utils/register";

const StepButtons = ({ currentStep, onStepChange, onSubmit }) => {
  const {
    formState: { errors },
  } = useFormContext();
  const loading = useSelector((state) => state.auth.authLoading);
  const { t } = useTranslation(null, { keyPrefix: "register.steps.buttons" });

  const btnStyle = { width: "250px" };

  return (
    <div className="mt-4 d-flex justify-content-center">
      {currentStep === 0 && (
        <Button
          variant="primary"
          size="lg"
          onClick={() => onStepChange(1)}
          style={btnStyle}
          className="d-flex align-items-center justify-content-center"
        >
          {t("getStarted")} <BsArrowRight className="ml-2" />
        </Button>
      )}

      {currentStep > 0 && currentStep + 1 !== stepsList().length && (
        <ButtonGroup style={btnStyle}>
          <Button
            className="d-flex align-items-center"
            variant="primary"
            size="lg"
            onClick={() => onStepChange(currentStep - 1)}
          >
            <BsFillArrowLeftCircleFill className="mr-2" /> {t("previous")}
          </Button>
          <Button
            className="d-flex align-items-center"
            variant="secondary"
            size="lg"
            onClick={() => onStepChange(currentStep + 1)}
          >
            {t("next")} <BsFillArrowRightCircleFill className="ml-2" />
          </Button>
        </ButtonGroup>
      )}

      {currentStep + 1 === stepsList().length && (
        <div className="final-step-buttons">
          <Button
            variant="secondary"
            size="lg"
            style={{ ...btnStyle }}
            onClick={() => onStepChange(currentStep - 1)}
          >
            <BsFillArrowLeftCircleFill className="mr-2" /> Previous
          </Button>
          <Button
            variant="primary"
            size="lg"
            style={btnStyle}
            onClick={onSubmit}
            disabled={Object.keys(errors).length > 0 || loading}
          >
            {loading ? (
              <Spinner variant="light" animation="border" size="sm" />
            ) : (
              "Register Account"
            )}
          </Button>
        </div>
      )}
    </div>
  );
};

export default StepButtons;
