import React from "react";

import { Table as BSTable } from "react-bootstrap";

const Table = ({ data, headers }) => {
  return (
    <BSTable striped bordered hover responsive className="mb-0">
      <thead>
        <tr>
          {headers.map((header) => (
            <th key={header.label}>{header.label}</th>
          ))}
        </tr>
      </thead>

      <tbody>
        {data.length === 0 && (
          <tr>
            <td colSpan={headers.length} className="text-center">
              No Appointments Found...
            </td>
          </tr>
        )}
        {data.map((item) => {
          return (
            <tr key={item.id}>
              {headers.map(({ key }) => (
                <td key={key}>{item[key]}</td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </BSTable>
  );
};

export default Table;
