import React, { createContext, useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

import LanguageSelector from "./LanguageSelector";
import Steps from "./steps";
import { LANGUAGE } from "../../utils/languages";
import { packages, registerSchema } from "../../utils/register";

export const LanguageContext = createContext();
export const StepContext = createContext();

const Register = ({ location }) => {
  const [language, setLanguage] = useState(LANGUAGE.ENGLISH);
  const [step, setStep] = useState(0);
  const methods = useForm({ resolver: yupResolver(registerSchema) });
  const { t } = useTranslation(null, { keyPrefix: "register" });

  const params = new URLSearchParams(location.search);
  const packageParam = params.get("package");
  const languageParam = params.get("language");

  useEffect(() => {
    if (languageParam) i18n.changeLanguage(languageParam);

    if (packageParam) {
      const foundPackage = packages().find(
        (packageItem) => packageItem.id === parseInt(packageParam)
      );

      if (foundPackage) methods.setValue("selectedPackage", foundPackage);
    }
  }, []);

  const handleStepChange = (newStep) => {
    const isMobile = window.matchMedia(
      "only screen and (max-width: 760px)"
    ).matches;

    if (isMobile)
      document.querySelector(".registration-steps-wrapper").scrollIntoView();

    setStep(newStep);
  };

  return (
    <LanguageContext.Provider value={{ language }}>
      <StepContext.Provider
        value={{ currentStep: step, onStepChange: handleStepChange }}
      >
        <FormProvider {...methods}>
          <div className="container mx-auto mt-4 mb-4">
            <h1 className="text-center text-secondary">{t("title")}</h1>

            <LanguageSelector
              current={language}
              onChange={(lang) => setLanguage(lang)}
            />

            <Steps
              language={language}
              current={step}
              onStepChange={handleStepChange}
            />
          </div>
        </FormProvider>
      </StepContext.Provider>
    </LanguageContext.Provider>
  );
};

export default Register;
