import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import SessionLogForm from "../form";

const EditSessionLog = () => {
  const { id } = useParams();
  const session = useSelector((state) =>
    state.sessionLogs.sessionLogs.find((log) => log._id === id)
  );

  const cleanedSession = {
    ...session,
    student: session.student._id,
    instructor: session.instructor._id,
  };

  return (
    <div className="container my-4">
      <h1 className="text-center text-primary mb-3">Edit Session Log</h1>
      <SessionLogForm type="edit" session={cleanedSession} />
    </div>
  );
};

export default EditSessionLog;
