import React, { useState } from "react";
import { Form, Button, Spinner } from "react-bootstrap";

import { requestResetPassword } from "../../api/";
import { toastify } from "../../utils/actionsHandler";

const RequestForm = ({ token }) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    try {
      const res = await requestResetPassword(email);

      toastify(res.data.message, "success", 5000);
    } catch (error) {
      toastify("An error has occured", "danger");
    }

    setLoading(false);
  };

  return (
    <Form
      onSubmit={handleSubmit}
      style={{ width: "100%", maxWidth: "400px", margin: "auto" }}
    >
      <Form.Group controlId="password">
        <Form.Label>Enter Your Email</Form.Label>
        <Form.Control
          type="email"
          placeholder="Your Email Address..."
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          required
        />
      </Form.Group>

      <Button variant="primary" type="submit" disabled={loading}>
        {loading ? (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        ) : (
          "Reset Password"
        )}
      </Button>
    </Form>
  );
};

export default RequestForm;
