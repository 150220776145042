import { object, string, date, array, number } from "yup";

export const UserSchema = object({
  username: string().required("Username is required"),
  password: string().optional(),
  firstName: string().required("First name is required"),
  lastName: string().required("Last name is required"),
  email: string().required("Email is required"),
  phoneNumber: string().optional(),
  dateOfBirth: date().optional(),
  address: object({
    streetAddress: string().required("Street address is required"),
    city: string().required("City is required"),
    province: string().required("Province is required"),
    postalCode: string().required("Postal code is required"),
  }),
  driversLicense: object({
    number: string().optional(),
    expiryDate: date().optional(),
    imageFront: string().optional(),
  }).optional(),
  config: object({
    schedule: array().optional(),
    selectedPackage: object({}).optional(),
    payments: array().optional().default([]),
    associatedInstructor: string().optional().nullable(),
    isPrivate: string().optional(),
    role: string().required("User role is require"),
    elearning_metadata: object({
      startDate: date().optional(),
      endDate: date().optional(),
      inPerson: object({
        finalMark: number().optional(),
        instructor: object({
          name: string().optional(),
          driversLicense: object({
            number: string().optional(),
            expiryDate: date().optional(),
          }),
        }),
      }).optional(),
      online: object({
        finalMark: number().optional(),
        instructor: object({
          name: string().optional(),
          driversLicense: object({
            number: string().optional(),
            expiryDate: date().optional(),
          }),
        }),
      }).optional(),
    })
      .default({})
      .nullable()
      .optional(),
  }),
});
