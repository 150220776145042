import React, { useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import { DatePicker } from "@material-ui/pickers";
import moment from "moment";

const OnlineCourse = () => {
  const { setValue } = useFormContext();
  const [show, setShow] = useState();

  const btnText = show
    ? "Clear E-Learning Information"
    : "Add E-Learning Information";

  const handleClick = () => {
    const shouldShow = !show;

    if (!shouldShow) setValue("config.elearning_metadata", {});

    setShow(shouldShow);
  };

  return (
    <>
      <h4>E-Learning</h4>
      <Button
        variant="secondary"
        size="sm"
        onClick={handleClick}
        className="mb-2"
      >
        {btnText}
      </Button>

      {show && (
        <>
          <Row>
            <Col md>
              <Form.Group>
                <Form.Label>Start Date</Form.Label>

                <Controller
                  name="config.elearning_metadata.startDate"
                  render={({ field: { value, onChange } }) => (
                    <DatePicker
                      onChange={onChange}
                      TextFieldComponent={({ onClick }) => {
                        return (
                          <Form.Control
                            onChange={() => {}}
                            onClick={onClick}
                            value={
                              value &&
                              moment(value).format("dddd, MMMM Do, YYYY")
                            }
                            placeholder="Start Date..."
                          />
                        );
                      }}
                      value={value}
                    />
                  )}
                />
              </Form.Group>
            </Col>

            <Col md>
              <Form.Group>
                <Form.Label>End Date</Form.Label>

                <Controller
                  name="config.elearning_metadata.endDate"
                  render={({ field: { value, onChange } }) => (
                    <DatePicker
                      onChange={onChange}
                      TextFieldComponent={({ onClick }) => {
                        return (
                          <Form.Control
                            onChange={() => {}}
                            onClick={onClick}
                            value={
                              value &&
                              moment(value).format("dddd, MMMM Do, YYYY")
                            }
                            placeholder="End Date..."
                          />
                        );
                      }}
                      value={value}
                    />
                  )}
                />
              </Form.Group>
            </Col>
          </Row>

          <h5>Online</h5>
          <Row>
            <Col xl>
              <Form.Group>
                <Form.Label>Final Mark</Form.Label>

                <Controller
                  name="config.elearning_metadata.online.finalMark"
                  render={({ field }) => (
                    <Form.Control
                      placeholder="Final Mark..."
                      type="number"
                      min="0"
                      max="100"
                      {...field}
                    />
                  )}
                />
              </Form.Group>
            </Col>

            <Col xl>
              <Form.Group>
                <Form.Label>Instructor Name</Form.Label>

                <Controller
                  name="config.elearning_metadata.online.instructor.name"
                  render={({ field }) => (
                    <Form.Control placeholder="Name..." {...field} />
                  )}
                />
              </Form.Group>
            </Col>

            <Col xl>
              <Form.Group>
                <Form.Label>Instructor License Number</Form.Label>

                <Controller
                  name="config.elearning_metadata.online.instructor.driversLicense.number"
                  render={({ field }) => (
                    <Form.Control placeholder="License Number..." {...field} />
                  )}
                />
              </Form.Group>
            </Col>

            <Col xl>
              <Form.Group>
                <Form.Label>Instructor License Expiry Date</Form.Label>

                <Controller
                  name="config.elearning_metadata.online.instructor.driversLicense.expiryDate"
                  render={({ field: { value, onChange } }) => (
                    <DatePicker
                      onChange={onChange}
                      TextFieldComponent={({ onClick }) => {
                        return (
                          <Form.Control
                            onChange={() => {}}
                            onClick={onClick}
                            value={
                              value &&
                              moment(value).format("dddd, MMMM Do, YYYY")
                            }
                            placeholder="Expiry Date..."
                          />
                        );
                      }}
                      value={value}
                    />
                  )}
                />
              </Form.Group>
            </Col>
          </Row>

          <h5>In Person</h5>
          <Row>
            <Col xl>
              <Form.Group>
                <Form.Label>Final Mark</Form.Label>

                <Controller
                  name="config.elearning_metadata.inPerson.finalMark"
                  render={({ field }) => (
                    <Form.Control
                      placeholder="Final Mark..."
                      type="number"
                      min="0"
                      max="100"
                      {...field}
                    />
                  )}
                />
              </Form.Group>
            </Col>

            <Col xl>
              <Form.Group>
                <Form.Label>Instructor Name</Form.Label>

                <Controller
                  name="config.elearning_metadata.inPerson.instructor.name"
                  render={({ field }) => (
                    <Form.Control placeholder="Name..." {...field} />
                  )}
                />
              </Form.Group>
            </Col>

            <Col lg>
              <Form.Group>
                <Form.Label>Instructor License Number</Form.Label>

                <Controller
                  name="config.elearning_metadata.inPerson.instructor.driversLicense.number"
                  render={({ field }) => (
                    <Form.Control placeholder="License Number..." {...field} />
                  )}
                />
              </Form.Group>
            </Col>

            <Col xl>
              <Form.Group>
                <Form.Label>Instructor License Expiry Date</Form.Label>

                <Controller
                  name="config.elearning_metadata.inPerson.instructor.driversLicense.expiryDate"
                  render={({ field: { value, onChange } }) => (
                    <DatePicker
                      onChange={onChange}
                      TextFieldComponent={({ onClick }) => {
                        return (
                          <Form.Control
                            onChange={() => {}}
                            onClick={onClick}
                            value={
                              value &&
                              moment(value).format("dddd, MMMM Do, YYYY")
                            }
                            placeholder="Expiry Date..."
                          />
                        );
                      }}
                      value={value}
                    />
                  )}
                />
              </Form.Group>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default OnlineCourse;
