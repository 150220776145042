import {
  GET_APPOINTMENTS,
  GET_APPOINTMENT_SETTINGS,
  GET_STATUSES,
  GET_INSTRUCTOR_AVAILABILITY,
  ADD_APPOINTMENT,
  EDIT_APPOINTMENT,
  DELETE_APPOINTMENT,
  SORT_APPOINTMENTS,
  FILTER_APPOINTMENTS,
  SET_CURRENT_APPOINTMENT,
  CLEAR_CURRENT_APPOINTMENT,
  SET_SCHEDULE_LOADING,
  SCHEDULE_MODAL_CONTROL,
  SET_ERRORS,
  CLEAR_ERRORS,
  LOGOUT,
} from "../actions/types";

const initialState = {
  appointments: [],
  statuses: [],
  sortedAppointments: [],
  currentAppointment: {
    instructorID: "",
    date: null,
    time: "",
    session: null,
    pickupLocation: {
      streetAddress: "",
      city: "",
      province: "",
      postalCode: "",
    },
    publicNotes: "",
    privateNotes: "",
    status: "",
  },
  settings: {},
  scheduleErrors: null,
  message: null,
  scheduleLoading: false,
};

const scheduleReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_APPOINTMENTS:
      return {
        ...state,
        appointments: action.payload,
        scheduleLoading: false,
      };
    case GET_APPOINTMENT_SETTINGS:
      return {
        ...state,
        settings: { ...state.settings, ...action.payload },
        scheduleLoading: false,
      };
    case GET_INSTRUCTOR_AVAILABILITY:
      return {
        ...state,
        settings: { ...state.settings, instructorAvailability: action.payload },
        scheduleLoading: false,
      };

    case GET_STATUSES:
      return {
        ...state,
        statuses: action.payload,
        scheduleLoading: false,
      };

    case ADD_APPOINTMENT:
      return {
        ...state,
        appointments: action.payload,
        scheduleLoading: false,
      };

    case EDIT_APPOINTMENT:
      return {
        ...state,
        appointments: state.appointments.map((appointment) =>
          appointment._id === action.payload._id
            ? { ...action.payload, current: true }
            : appointment
        ),
        scheduleLoading: false,
      };

    case DELETE_APPOINTMENT:
      return {
        ...state,
        appointments: state.appointments.filter(
          (appointment) => appointment._id !== action.payload._id
        ),
        scheduleLoading: false,
      };

    case SORT_APPOINTMENTS:
      return {
        ...state,
        sortedAppointments: state.appointments.sort(
          (a, b) => a[action.payload] - b[action.payload]
        ),
        scheduleLoading: false,
      };

    case FILTER_APPOINTMENTS:
      return {
        ...state,

        //Not quite right, need to filter based on the object values instead
        filteredAppointments: state.appointments.filter((appointment) =>
          appointment.contains(action.payload)
        ),
        scheduleLoading: false,
      };

    case SET_CURRENT_APPOINTMENT:
      return {
        ...state,
        currentAppointment: { ...state.currentAppointment, ...action.payload },
        scheduleLoading: false,
      };

    case CLEAR_CURRENT_APPOINTMENT:
      return {
        ...state,
        currentAppointment: null,
        scheduleLoading: false,
      };

    case SCHEDULE_MODAL_CONTROL:
      return {
        ...state,
        openScheduleModal: !state.openScheduleModal,
        scheduleLoading: false,
      };

    case SET_SCHEDULE_LOADING:
      return {
        ...state,
        scheduleLoading: true,
      };

    case SET_ERRORS:
      return {
        ...state,
        errors: action.payload,
        scheduleLoading: false,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        errors: null,
      };

    case LOGOUT:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default scheduleReducer;
