import React from "react";
import { Modal, Button, Popover, OverlayTrigger } from "react-bootstrap";
import Details from "../AppointmentItemDetails";

import { AiOutlineFileText } from "react-icons/ai";

const AppointmentDetailsModal = ({
  show,
  appointment,
  role,
  onClose,
  onEdit,
  onCancel,
}) => {
  const confirmPopover = (
    <Popover>
      <Popover.Title as="h3">Cancel Appointment</Popover.Title>
      <Popover.Content>
        <p>Are you sure you want to cancel this appointment?</p>
        <Button variant="danger" size="sm" onClick={onCancel}>
          Cancel Appointment
        </Button>
      </Popover.Content>
    </Popover>
  );

  return (
    <Modal show={show} onHide={onClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          <AiOutlineFileText /> Appointment Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Details appointment={appointment} role={role} showStatus />
      </Modal.Body>
      <Modal.Footer className="justify-content-between">
        <div>
          <OverlayTrigger
            trigger="click"
            placement="auto"
            overlay={confirmPopover}
            rootClose
          >
            <Button variant="danger">Cancel Appointment</Button>
          </OverlayTrigger>
        </div>

        <div>
          <Button
            variant="secondary"
            onClick={onClose}
            style={{ marginRight: "4px" }}
          >
            Close
          </Button>
          <Button variant="primary" onClick={() => onEdit(appointment)}>
            Edit Appointment
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default AppointmentDetailsModal;
