import React from "react";
import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";

const ControlArea = ({ handleEdit, handleTimeOff }) => {
  const { role } = useSelector((state) => state.auth?.profile?.config);
  return (
    <>
      <Button block variant="primary" onClick={handleEdit}>
        Edit Profile
      </Button>

      {role === "instructor" && (
        <Button block variant="secondary" onClick={handleTimeOff}>
          Book Time Off
        </Button>
      )}
    </>
  );
};

export default ControlArea;
