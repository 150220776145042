import React from "react";

import { useSelector } from "react-redux";
import { Image } from "react-bootstrap";

import {
  HiOutlineMail,
  HiPhone,
  HiUser,
  HiOutlineLocationMarker,
} from "react-icons/hi";
import { AiFillIdcard } from "react-icons/ai";

import profilePhoto from "../../assets/images/profile-photo.webp";

const ProfileCard = () => {
  const { profile } = useSelector((state) => state.auth);

  return (
    <div
      className="profile-card d-flex flex-wrap p-4"
      style={{ width: "100%", margin: "auto", gap: "1rem" }}
    >
      <Image
        fluid
        src={profilePhoto}
        style={{ width: "100%", maxWidth: "300px" }}
      />

      <div style={{ width: "650px" }}>
        <h3 className="text-center text-primary mb-4">
          {profile.firstName} {profile.lastName}
        </h3>

        <span className="profile-item">
          <HiUser className="icon" />{" "}
          {`${profile.username} (${profile.config.role})`}
        </span>

        <span className="profile-item">
          <HiOutlineMail className="icon" />
          <a href={`mailto:${profile.email}`}>{profile.email}</a>
        </span>

        <span className="profile-item">
          <HiPhone className="icon" />
          <a href={`tel:${profile.phoneNumber}`}>{profile.phoneNumber}</a>
        </span>

        <span className="profile-item">
          <HiOutlineLocationMarker className="icon" />
          {profile.address.streetAddress}
          <br />
          {`${profile.address.city}, ${profile.address.province} ${profile.address.postalCode}`}
        </span>

        {profile.driversLicense && (
          <span className="profile-item">
            <AiFillIdcard className="icon" /> {profile.driversLicense.number}
            <br />
            {new Date(profile.driversLicense.expiryDate).toLocaleDateString()}
          </span>
        )}
      </div>
    </div>
  );
};

export default ProfileCard;
