import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import UserForm from "../form";

const EditUser = () => {
  const { id } = useParams();
  const user = useSelector((state) =>
    state.users.users.find((user) => user._id === id)
  );

  return (
    <div className="container my-4">
      <h1 className="text-center text-primary mb-3">Editing User</h1>

      <UserForm type="edit" value={user} />
    </div>
  );
};

export default EditUser;
