import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import AppointmentItemDetails from "../AppointmentItemDetails";
import { bookAppointment } from "../../../actions/scheduleActions";

const Confirmation = ({ appointment }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.profile);
  const { role, isPrivate } = user.config;
  const { scheduleLoading: loading } = useSelector((state) => state.schedule);
  const [isBooked, setIsBooked] = useState(false);
  const history = useHistory();

  const confirmAppointment = async () => {
    const filteredAppointmentData = {
      instructorID: appointment.instructorID,
      sessionID: appointment.sessionID,
      dateAndTime: appointment.dateAndTime,
      pickupLocation: appointment.pickupLocation,
      publicNotes: appointment.publicNotes,
      duration: appointment.duration,
    };

    await dispatch(bookAppointment(filteredAppointmentData, role));

    isPrivate ? history.push("/book/confirm") : history.push("/appointments");
  };

  const instructorName = appointment.instructorName.split(" ");

  return (
    <>
      <AppointmentItemDetails
        showStatus={false}
        showSessionLog={false}
        appointment={{
          ...appointment,
          sessionID: { name: appointment.sessionName },
          instructorID: {
            firstName: instructorName[0],
            lastName: instructorName[1],
          },
        }}
        role={role}
      />

      <Button
        onClick={confirmAppointment}
        className="mt-3"
        variant="secondary"
        size="lg"
        block
        disabled={loading || isBooked}
      >
        {!loading ? (
          "Confirm Appointment"
        ) : (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        )}
      </Button>
    </>
  );
};

export default Confirmation;
