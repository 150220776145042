import React from "react";
import { Form } from "react-bootstrap";
import { provinces } from "../../utils/provinces";
import AutoComplete from "../layout/AutoComplete";

const ProfileEditForm = ({
  profileData,
  handleChange,
  handleAutoComplete,
  handleAddressChange,
}) => {
  return (
    <Form>
      <Form.Group controlId="email">
        <Form.Label>Email address</Form.Label>
        <Form.Control
          type="email"
          placeholder="Your new email address"
          onChange={handleChange}
          value={profileData.email}
        />
      </Form.Group>

      <Form.Group controlId="phoneNumber">
        <Form.Label>Phone Number</Form.Label>
        <Form.Control
          type="text"
          placeholder="Your new phone number"
          onChange={handleChange}
          value={profileData.phoneNumber}
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Search for Address</Form.Label>

        <AutoComplete onChange={handleAutoComplete} />

        <Form.Text className="text-muted">
          Start typing your address here and click on the appropriate
          suggestion. If it does not auto-populate the information below, please
          enter it manually.
        </Form.Text>
      </Form.Group>

      <Form.Group controlId="streetAddress">
        <Form.Label>Street Address</Form.Label>
        <Form.Control
          type="text"
          placeholder="Your new address"
          onChange={handleAddressChange}
          value={profileData.address.streetAddress}
        />
      </Form.Group>

      <Form.Group controlId="city">
        <Form.Label>City</Form.Label>
        <Form.Control
          type="text"
          placeholder="Your city"
          onChange={handleAddressChange}
          value={profileData.address.city}
        />
      </Form.Group>

      <Form.Group controlId="province">
        <Form.Label>Province</Form.Label>
        <Form.Control
          as="select"
          required
          value={profileData.address.province}
          onChange={handleAddressChange}
        >
          {provinces.map((province) => (
            <option key={province.id} value={province.text}>
              {province.text}
            </option>
          ))}
        </Form.Control>
      </Form.Group>

      <Form.Group controlId="postalCode">
        <Form.Label>Postal Code</Form.Label>
        <Form.Control
          type="text"
          placeholder="Your postal code"
          required
          onChange={handleAddressChange}
          value={profileData.address.postalCode}
        />
      </Form.Group>
    </Form>
  );
};

export default ProfileEditForm;
