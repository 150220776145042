import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const ConfirmMessage = () => {
  const user = useSelector((state) => state.auth.profile);
  const appointments = useSelector((state) => state.schedule.appointments);
  const { isPrivate, selectedPackage } = user.config;

  const loadMessage = () => {
    const instructorEmail =
      appointments[appointments.length - 1]?.instructorID?.email;

    if (isPrivate)
      return (
        <>
          <h4 style={{ lineHeight: 1.5 }} className="mb-4">
            Your appointment will be pending until payment is received and the
            instructor has approved the appointment.
          </h4>

          <h4 style={{ lineHeight: 1.5 }} className="mb-4">
            Payment is required in order to confirm your appointment. Please
            send an e-transfer to:{" "}
            <a href={`mailto:${instructorEmail}`}>{instructorEmail}</a> in the
            amount of $50 per lesson. If you require to pay by credit/debit,
            there will be a 3% transaction fee added to your total. Please
            contact by text or call us at{" "}
            <a href="tel:905-517-0798">905-517-0798</a> to arrange payment by
            credit/debit or cash.
          </h4>
        </>
      );

    if (selectedPackage.id === 3)
      return (
        <>
          <h4 style={{ lineHeight: 1.5 }} className="my-5 text-center">
            If your test is within 24 hours, please call or text us at{" "}
            <a href="tel:905-517-0798">905-517-0798</a>
          </h4>
        </>
      );
  };

  return (
    <div className="container my-4">
      <h1 className="text-center text-primary mb-3">Booking Confirmed</h1>

      {loadMessage()}

      <div className="d-flex justify-content-center">
        <Link to="/appointments" className="btn btn-lg btn-secondary">
          View Appointments
        </Link>
      </div>
    </div>
  );
};

export default ConfirmMessage;
