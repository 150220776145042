import React, { useState, useEffect } from "react";
import { Form, Col } from "react-bootstrap";
import MaskedInput from "react-text-mask";
import DatePicker from "react-date-picker";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { checkEmail } from "../../api";

import AutoComplete from "../layout/AutoComplete";

import { provinces } from "../../utils/provinces";
import { phoneNumberMask, postalCodeMask } from "../../utils/register";

const UserInfoSection = () => {
  const {
    register,
    setValue,
    getValues,
    setError,
    formState: { errors },
  } = useFormContext();
  const [dateOfBirth, setDateOfBirth] = useState(getValues("dateOfBirth"));
  const { t } = useTranslation(null, { keyPrefix: "register.steps" });

  useEffect(() => {
    setValue("address.province", "Ontario");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDateOfBirthSelect = (date) => {
    setDateOfBirth(date);
    setValue("dateOfBirth", date, { shouldValidate: true });
  };

  const handleAutoComplete = ({
    streetAddress,
    city,
    province,
    postalCode,
  }) => {
    setValue("address.streetAddress", streetAddress, { shouldValidate: true });
    setValue("address.city", city, { shouldValidate: true });
    setValue("address.province", province, { shouldValidate: true });
    setValue("address.postalCode", postalCode, { shouldValidate: true });
  };

  const emailChecker = async (e) => {
    const email = e.target.value;

    try {
      const res = await checkEmail(email);

      res.data.available
        ? setValue("email", email)
        : setError("email", {
            type: "manual",
            message:
              "This email address has already been taken, please provide another one",
          });

      return res.data.available;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Form.Row>
        <Col lg>
          <Form.Group>
            <Form.Label>{t("basicInformation.firstName.label")}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t("basicInformation.firstName.placeholder")}
              {...register("firstName")}
              isInvalid={errors.firstName}
            />
            <Form.Control.Feedback type="invalid">
              {t("basicInformation.firstName.error")}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col lg>
          <Form.Group>
            <Form.Label>{t("basicInformation.lastName.label")}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t("basicInformation.lastName.placeholder")}
              {...register("lastName")}
              isInvalid={errors.lastName}
            />
            <Form.Control.Feedback type="invalid">
              {t("basicInformation.lastName.error")}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Form.Row>

      <Form.Row>
        <Col xl={2}>
          <Form.Group>
            <Form.Label style={{ display: "block" }}>
              {t("basicInformation.dateOfBirth.label")}
            </Form.Label>

            <DatePicker
              onChange={handleDateOfBirthSelect}
              value={dateOfBirth}
            />

            {/* {errors.dateOfBirth && (
              <Form.Text className="text-danger">
                {errors.dateOfBirth?.message}
              </Form.Text>
            )} */}
          </Form.Group>
        </Col>
        <Col md={5}>
          <Form.Group>
            <Form.Label>{t("basicInformation.email.label")}</Form.Label>
            <Form.Control
              type="email"
              placeholder={t("basicInformation.email.placeholder")}
              isInvalid={errors.email}
              {...register("email")}
              onBlur={emailChecker}
            />
            <Form.Control.Feedback type="invalid">
              {t("basicInformation.email.error")}
              {/* {errors.email?.message} */}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col md={5}>
          <Form.Group>
            <Form.Label>{t("basicInformation.phoneNumber.label")}</Form.Label>

            <MaskedInput
              mask={phoneNumberMask}
              placeholder={t("basicInformation.phoneNumber.placeholder")}
              className={`form-control ${errors.phoneNumber && "is-invalid"}`}
              {...register("phoneNumber")}
              onChange={(e) =>
                setValue("phoneNumber", e.target.value, {
                  shouldValidate: true,
                })
              }
            />

            {errors.phoneNumber && (
              <Form.Text className="text-danger">
                {/* {errors.phoneNumber?.message} */}
                {t("basicInformation.phoneNumber.error")}
              </Form.Text>
            )}
          </Form.Group>
        </Col>
      </Form.Row>

      <Form.Row>
        <Col lg>
          <Form.Group>
            <Form.Label>
              {t("basicInformation.address.autofill.label")}
            </Form.Label>
            <AutoComplete
              onChange={handleAutoComplete}
              placeholder={t("basicInformation.address.autofill.placeholder")}
            />
          </Form.Group>
        </Col>
      </Form.Row>

      <Form.Row>
        <Col lg>
          <Form.Group>
            <Form.Label>
              {t("basicInformation.address.street.label")}
            </Form.Label>
            <Form.Control
              type="text"
              placeholder={t("basicInformation.address.autofill.placeholder")}
              {...register("address.streetAddress")}
              isInvalid={errors.address?.streetAddress}
            />
            <Form.Control.Feedback type="invalid">
              {errors.address?.streetAddress?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col lg>
          <Form.Group>
            <Form.Label>{t("basicInformation.address.city.label")}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t("basicInformation.address.city.placeholder")}
              {...register("address.city")}
              isInvalid={errors.address?.city}
            />
            <Form.Control.Feedback type="invalid">
              {/* {errors.address?.city?.message} */}
              {t("basicInformation.address.city.error")}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col lg>
          <Form.Group>
            <Form.Label>
              {t("basicInformation.address.province.label")}
            </Form.Label>
            <Form.Control
              as="select"
              defaultValue="Ontario"
              {...register("address.province")}
              onChange={(e) => setValue("address.province", e.target.value)}
            >
              <option disabled value="">
                {t("basicInformation.address.province.placeholder")}
              </option>
              {provinces.map((province) => (
                <option key={province.id} value={province.value}>
                  {province.text}
                </option>
              ))}
            </Form.Control>
            {errors.address?.province && (
              <Form.Text className="text-danger">
                {t("basicInformation.address.province.error")}
                {/* {errors.address?.province?.message} */}
              </Form.Text>
            )}
          </Form.Group>
        </Col>

        <Col lg>
          <Form.Group>
            <Form.Label>
              {t("basicInformation.address.postalCode.label")}
            </Form.Label>

            <MaskedInput
              mask={postalCodeMask}
              placeholder={t("basicInformation.address.postalCode.placeholder")}
              className={`form-control ${
                errors.address?.postalCode && "is-invalid"
              }`}
              {...register("address.postalCode")}
              onChange={(e) =>
                setValue("address.postalCode", e.target.value, {
                  shouldValidate: true,
                })
              }
              value={getValues("address.postalCode")}
            />

            {errors.address?.postalCode && (
              <Form.Text className="text-danger">
                {t("basicInformation.address.postalCode.error")}
                {/* {errors.address?.postalCode?.message} */}
              </Form.Text>
            )}
          </Form.Group>
        </Col>
      </Form.Row>
    </>
  );
};

export default UserInfoSection;
