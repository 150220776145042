import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import moment from "moment";
import { useTranslation } from "react-i18next";

import { LanguageContext } from "..";

const ReviewInformation = () => {
  const { language } = React.useContext(LanguageContext);
  const {
    getValues,
    trigger,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation(null, {
    keyPrefix: "register.steps.reviewInformation",
  });

  const {
    selectedPackage,
    firstName,
    lastName,
    dateOfBirth,
    email,
    phoneNumber,
    address,
    driversLicense,
    paymentMethod,
    testInfo,
  } = getValues();

  useEffect(() => {
    trigger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const errorsArr = Object.entries(errors);

  return (
    <div>
      {errorsArr.length > 0 ? (
        <div className="bg-danger text-light p-3 mb-3">
          <h4>{t("errors.title")}</h4>

          <div className="divider light" />

          {/* 
            There are only errors in this object, if the error item does not have a message attached to it,
            that means that it is an nested error object, we need to traverse its inner properites
          */}
          <ul>
            {Object.entries(errors).map(([key, error]) =>
              error.message ? (
                <li key={key}>{error.message}</li>
              ) : (
                Object.entries(error).map(([key, error]) => (
                  <li key={key}>{error.message}</li>
                ))
              )
            )}
          </ul>
        </div>
      ) : (
        <>
          <h4 className="my-4">{t("correct.title")}</h4>

          <div className="divider dark" />
          <p>
            <span className="text-muted">{t("correct.name.label")}</span>{" "}
            <strong>
              {firstName} {lastName}
            </strong>
          </p>

          {dateOfBirth && (
            <p>
              <span className="text-muted">
                {t("correct.dateOfBirth.label")}
              </span>
              <strong>{moment(dateOfBirth).format("MMMM Do, YYYY")}</strong>
            </p>
          )}
          <p>
            <span className="text-muted">{t("correct.email.label")}</span>{" "}
            <strong>{email}</strong>
          </p>
          <p>
            <span className="text-muted">{t("correct.phoneNumber.label")}</span>{" "}
            <strong>{phoneNumber}</strong>
          </p>
          <p>
            <span className="text-muted">{t("correct.address.label")}</span>{" "}
            <strong>{`${address?.streetAddress} ${address?.city} ${address?.province}, ${address?.postalCode}`}</strong>
          </p>
          <p>
            <span className="text-muted">
              {t("correct.selectedPackage.label")}
            </span>{" "}
            <strong>{selectedPackage?.title?.rendered}</strong>
          </p>

          {testInfo && (
            <div className="mb-3">
              <span className="text-muted">
                {t("correct.testInformation.label")}
              </span>
              <p className="mt-0 mb-2 ml-3">
                <span className="text-muted">
                  {t("correct.testInformation.location.label")}
                </span>{" "}
                <strong>{testInfo?.location}</strong>
              </p>
              <p className="mt-0 mb-2 ml-3">
                <span className="text-muted">
                  {t("correct.testInformation.type.label")}
                </span>{" "}
                <strong>{testInfo?.type}</strong>
              </p>
              <p className="mt-0 mb-2 ml-3">
                <span className="text-muted">
                  {t("correct.testInformation.dateAndTime.label")}
                </span>{" "}
                <strong>
                  {moment(testInfo?.dateAndTime).format("MMMM Do, YYYY")}
                </strong>
              </p>
            </div>
          )}
          <p>
            <span className="text-muted">
              {t("correct.paymentMethod.label")}
            </span>{" "}
            <strong>{paymentMethod}</strong>
          </p>
          <div>
            <span className="text-muted">
              {t("correct.driversLicense.label")}
            </span>
            <p className="mt-0 mb-2 ml-3">
              <span className="text-muted">
                {t("correct.driversLicense.number.label")}
              </span>{" "}
              <strong>{driversLicense?.number}</strong>
            </p>
            <p className="mt-0 mb-2 ml-3">
              <span className="text-muted">
                {t("correct.driversLicense.expiryDate.label")}
              </span>{" "}
              <strong>
                {moment(driversLicense?.expiry).format("MMMM Do, YYYY")}
              </strong>
            </p>
            {driversLicense?.imageFront && (
              <img
                className="mt-0 mb-3 ml-3"
                src={driversLicense?.imageFront}
                alt="license-thumbnail"
                style={{ width: "100%", maxWidth: "200px" }}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ReviewInformation;
