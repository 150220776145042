import React, { useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { useSelector } from "react-redux";
import Button from "../layout/ButtonWithIcon";
import { AiFillPrinter } from "react-icons/ai";

import Sessions from "./Sessions";
import Details from "./SessionLogItemDetails";

import { setCurrentSessionLog } from "../../actions/sessionLogActions";

const SessionLogItem = () => {
  const sessionRef = useRef();
  const { id } = useParams();
  const session = useSelector((state) =>
    state.sessionLogs.sessionLogs.find((log) => log._id === id)
  );

  const handlePrint = useReactToPrint({
    content: () => sessionRef.current,
  });

  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setCurrentSessionLog(session));
  };

  return (
    <div className="container my-4">
      <h1 className="text-center text-primary mb-3">Session Log</h1>

      <div className="mb-3 d-flex">
        <Link to="/sessionlogs" className="btn btn-outline-secondary mr-1">
          Back to Session Logs
        </Link>{" "}
        <Button
          text="Print Session Log"
          icon={AiFillPrinter}
          className="ml-1"
          variant="outline-secondary"
          onClick={handlePrint}
        />
        <Link
          to={`/sessionlogs/${id}/edit`}
          onClick={handleClick}
          className="btn btn-secondary mx-1"
        >
          Edit Session Log
        </Link>
      </div>
      <div ref={sessionRef} className="session-print-area">
        <Details session={session} onPrint={handlePrint} />
        <Sessions list={session?.sessions} />
      </div>
    </div>
  );
};

export default SessionLogItem;
