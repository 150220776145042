import React, { useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useDispatch } from "react-redux";
import moment from "moment";

import BookingTimes from "../BookingTimes";
import { getInstructorAvailability } from "../../../../actions/scheduleActions";
import { addressToString } from "../../../../utils/address";

const DateAndTime = ({
  instructor,
  date,
  duration,
  address,
  handleDateSelect,
  handleTimeSelect,
  onChange,
}) => {
  const dispatch = useDispatch();

  const convertedAddress = addressToString(address);

  useEffect(() => {
    dispatch(
      getInstructorAvailability({
        instructor,
        day: date,
        duration,
        address: convertedAddress,
      })
    );
  }, []);

  const handleSelect = (date) => {
    handleDateSelect(date);
    dispatch(
      getInstructorAvailability({
        instructor,
        day: date,
        duration,
        address: convertedAddress,
      })
    );
  };

  const handleDurationSelect = (e) => {
    const durationValue = e.target.value;
    onChange(e);
    dispatch(
      getInstructorAvailability({
        instructor,
        day: date,
        duration: durationValue,
        address,
      })
    );
  };

  const handleSlotSelect = (time) => {
    handleTimeSelect(time);
  };

  return (
    <>
      <Row>
        <Col>
          <Form.Group controlId="duration">
            <Form.Label>Appointment Duration</Form.Label>
            <Form.Control
              as="select"
              onChange={handleDurationSelect}
              value={duration || "1"}
            >
              <option value="1">1 Hour</option>
              <option value="2">2 Hours</option>
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col className="text-center mb-5" lg={4}>
          <h4 className="mb-3">Select Date</h4>
          <DatePicker
            selected={date}
            onChange={handleSelect}
            inline
            minDate={new Date()}
          />
          <h5 className="text-primary text-center">
            {moment(date).format("dddd, MMMM Do, YYYY")}
          </h5>
        </Col>

        <Col className="text-center" lg={8}>
          <h4 className="mb-3">Select Time</h4>
          <BookingTimes handleSlotSelect={handleSlotSelect} autoSelect />
        </Col>
      </Row>
    </>
  );
};

export default DateAndTime;
