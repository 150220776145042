import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Modal, Button, Spinner } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
import { useHistory } from "react-router-dom";

import { AiOutlineUserDelete } from "react-icons/ai";
import UserItemDetails from "./UserItemDetails";
import { deleteUser } from "../../actions/userActions";
import SessionLogsList from "../sessionlogs/SessionLogsList";

const UserItem = ({ match }) => {
  const id = match.params.id;
  const detailsRef = useRef();
  const dispatch = useDispatch();
  const history = useHistory();
  const users = useSelector((state) => state.users.users);
  const loading = useSelector((state) => state.users.usersLoading);
  const role = useSelector((state) => state.auth?.profile?.config?.role);

  const [user, setUser] = useState(users.find((user) => user._id === id));
  const [showModal, setShowModal] = useState(false);

  const handlePrint = useReactToPrint({
    content: () => detailsRef.current,
    documentTitle: "New Drivers Users",
    pageStyle: `
    @page {
      size: 80mm 50mm;
    }
    `,
  });

  const handleClose = () => {
    setShowModal(false);
  };

  const handleDeleteUser = async () => {
    await dispatch(deleteUser(user._id));
    history.push("/users");
  };

  return (
    <div className="container my-4">
      <h1 className="text-center text-primary mb-3">User Details</h1>

      <Link to="/users" className="btn btn-outline-secondary mb-3">
        Back to Users List
      </Link>

      <div
        style={{
          display: "flex",
          gap: "1rem",
          marginBottom: "1rem",
          border: "1px solid lightgrey",
          padding: "1rem",
          flexWrap: "wrap",
        }}
      >
        {user.config.role === "student" && (
          <Link
            to={`/appointments?student=${user._id}`}
            className="btn btn-secondary"
          >
            Book Appointment with Student
          </Link>
        )}

        <Button variant="primary" onClick={handlePrint}>
          Print User Profile
        </Button>

        {role === "admin" && (
          <>
            <Link className="btn btn-secondary" to={`/users/${id}/edit`}>
              Edit User
            </Link>

            <Button variant="danger" onClick={() => setShowModal(true)}>
              Delete User
            </Button>
          </>
        )}
      </div>

      <div ref={detailsRef}>
        <UserItemDetails user={user} />
      </div>

      <div className="mt-5">
        <h4>Session Logs</h4>
        <SessionLogsList userID={user?._id} userRole={user?.config?.role} />
      </div>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <AiOutlineUserDelete /> Delete User
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this user?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={handleDeleteUser}
            disabled={loading}
          >
            {loading ? (
              <Spinner animation="border" variant="secondary" />
            ) : (
              "Delete User"
            )}
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UserItem;
