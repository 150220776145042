import React from "react";

const Footer = () => {
  return (
    <div className="bg-primary p-4 text-secondary">
      <p>Copyright &copy;2020 New Drivers Inc.</p>

      <p>Designed By Futurae</p>
    </div>
  );
};

export default Footer;
