import React, { useState, useEffect } from "react";
import { Table, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useHistory } from "react-router";
import { AiFillEye } from "react-icons/ai";

import { getUsers } from "../../actions/userActions";

const Students = () => {
  const dispatch = useDispatch();
  const { usersLoading: loading } = useSelector((state) => state.users);
  const { profile } = useSelector((state) => state.auth);
  const history = useHistory();

  const [users, setUsers] = useState(
    useSelector((state) => state.users.users) || []
  );

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  return (
    <div className="container my-4">
      <h1 className="text-center text-primary mb-5">Your Students</h1>

      <div className="table-wrapper">
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Name</th>
              <th>Date Registered</th>
              <th>Type</th>
              <th>Status</th>
              <th>Certified</th>
              <td></td>
            </tr>
          </thead>

          <tbody>
            {loading && users.length === 0 && (
              <tr>
                <td colSpan={6}>
                  <div className="d-flex justify-content-center">
                    <Spinner animation="border" variant="primary" />
                  </div>
                </td>
              </tr>
            )}

            {!loading && users.length === 0 && (
              <tr>
                <td colSpan={6}>
                  <div className="d-flex justify-content-center text-center">
                    No Students Assigned to You Currently
                  </div>
                </td>
              </tr>
            )}

            {users.map((user) => (
              <tr key={user._id}>
                <td>
                  {user.firstName} {user.lastName}
                </td>
                <td>
                  {moment(user.registrationDate).format("dddd, MMMM Do, YYYY")}
                </td>
                <td>{user.config.isPrivate ? "Private" : "Regular"}</td>
                <td>{user.status}</td>
                <td>{user.isCertified ? "Certified" : "Not Certified"}</td>

                <td>
                  <div className="d-flex">
                    <AiFillEye
                      className="text-secondary"
                      style={{
                        fontSize: "1.5rem",
                        color: "blue",
                        cursor: "pointer",
                      }}
                      onClick={() => history.push(`/users/${user._id}`)}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default Students;
