import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Redirect } from "react-router-dom";
import { getSessionLogs } from "../../../actions/sessionLogActions";

const UserSessions = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { sessionLogs } = useSelector((state) => state.sessionLogs);
  const loggedInUser = useSelector((state) => state.auth.profile);
  const loading = useSelector((state) => state.sessionLogs.sessionLogsLoading);
  const [sessions, setSessions] = useState([]);

  const role = loggedInUser.config.role;

  useEffect(() => {
    dispatch(getSessionLogs(role));
  }, []);

  useEffect(() => {
    setSessions(sessionLogs);
  }, [sessionLogs]);

  if (!id) return <Redirect to="/users" />;

  return (
    <div className="container my-4">
      <h1 className="text-center text-primary mb-3">User Sessions</h1>
      <p>{loading && "loading..."}</p>
    </div>
  );
};

export default UserSessions;
