import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import { Controller } from "react-hook-form";
import Select from "react-select";

const PaymentItem = ({ index }) => {
  const paymentOptions = [
    { label: "Debit/Credit Card", value: "Debit/Credit Card" },
    { label: "E-Transfer", value: "E-Transfer" },
    { label: "Cash", value: "Cash" },
  ];

  return (
    <>
      <Row>
        <Col lg>
          <Form.Group>
            <Form.Label>Payment Method Used</Form.Label>
            <Controller
              name={`config.payments.${index}.method`}
              render={({ field }) => (
                <Select
                  {...field}
                  placeholder="Select Method of Payment"
                  options={paymentOptions}
                  value={paymentOptions.find(
                    (option) => option.value === field.value
                  )}
                  onChange={({ value }) => field.onChange(value)}
                />
              )}
            />
          </Form.Group>
        </Col>

        <Col lg>
          <Form.Group>
            <Form.Label>Amount ($)</Form.Label>
            <Controller
              name={`config.payments.${index}.amount`}
              render={({ field }) => (
                <Form.Control
                  type="decimal"
                  min={1}
                  placeholder="Payment Amount..."
                  {...field}
                />
              )}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col lg>
          <Form.Group>
            <Form.Label>Notes</Form.Label>
            <Controller
              name={`config.payments.${index}.notes`}
              render={({ field }) => (
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Notes..."
                  {...field}
                />
              )}
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

export default PaymentItem;
