import React from "react";
import { useDropzone } from "react-dropzone";
import { useFormContext } from "react-hook-form";

const FileUploadArea = ({ text, options, listFiles }) => {
  const { acceptedFiles, isDragActive, getRootProps, getInputProps } =
    useDropzone(options);

  return (
    <>
      <div {...getRootProps({ className: "file-upload-area" })}>
        <input {...getInputProps()} />
        <p className="m-0">{isDragActive ? "Drop here" : text}</p>
      </div>

      {listFiles && acceptedFiles.map((file) => file.name)}
    </>
  );
};

FileUploadArea.defaultProps = {
  listFiles: false,
};

export default FileUploadArea;
