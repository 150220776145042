//General Types
export const SET_LOADING = "SET_LOADING";
export const SET_ERRORS = "SET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
//-------------

//Authentication Type
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const REGISTER = "REGISTER";
//--------------

//Profile Types
export const GET_PROFILE = "GET_PROFILE";
export const EDIT_PROFILE = "EDIT_PROFILE";
export const ADD_TIME_OFF = "ADD_TIME_OFF";
export const DELETE_TIME_OFF = "DELETE_TIME_OFF";
export const ADD_GOOGLE_AUTH = "ADD_GOOGLE_AUTH";
export const REMOVE_GOOGLE_AUTH = "REMOVE_GOOGLE_AUTH";
//-------

//Schedule Types
export const GET_APPOINTMENTS = "GET_APPOINTMENTS";
export const GET_INSTRUCTOR_AVAILABILITY = "GET_INSTRUCTOR_AVAILABILITY";
export const GET_APPOINTMENT_SETTINGS = "GET_APPOINTMENT_SETTINGS";
export const GET_STATUSES = "GET_STATUSES";
export const ADD_APPOINTMENT = "ADD_APPOINTMENT";
export const EDIT_APPOINTMENT = "EDIT_APPOINTMENT";
export const DELETE_APPOINTMENT = "DELETE_APPOINTMENT";
export const SORT_APPOINTMENTS = "SORT_APPOINTMENTS";
export const FILTER_APPOINTMENTS = "FILTER_APPOINTMENTS";
export const SET_CURRENT_APPOINTMENT = "SET_CURRENT_APPOINTMENT";
export const CLEAR_CURRENT_APPOINTMENT = "CLEAR_CURRENT_APPOINTMENT";
//--------

//Session Logs Types
export const GET_SESSION_LOGS = "GET_SESSION_LOGS";
export const GET_SESSION_TYPES = "GET_SESSION_TYPES";
export const ADD_SESSION_LOG = "ADD_SESSION_LOG";
export const EDIT_SESSION_LOG = "EDIT_SESSION_LOG";
export const DELETE_SESSION_LOG = "DELETE_SESSION_LOG";
export const SORT_SESSION_LOGS = "SORT_SESSION_LOGS";
export const FILTER_SESSION_LOGS = "FILTER_SESSION_LOGS";
export const SET_SESSION_LOGS = "SET_SESSION_LOGS";
export const CLEAR_SESSION_LOGS = "CLEAR_SESSION_LOGS";
export const SET_CURRENT_SESSION_LOG = "SET_CURRENT_SESSION_LOG";
export const CLEAR_CURRENT_SESSION_LOG = "CLEAR_CURRENT_SESSION_LOG";
//-----------

//Payroll Types
export const GET_TOTAL_HOURS = "GET_TOTAL_HOURS";
export const FILTER_HOURS = "FILTER_HOURS";
export const SORT_HOURS = "SORT_HOURS";
//-------

//User Types
export const GET_USERS = "GET_USERS";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const CLEAR_CURRENT_USER = "CLEAR_CURRENT_USER";
export const ADD_USER = "ADD_USER";
export const EDIT_USER = "EDIT_USER";
export const DELETE_USER = "DELETE_USER";
//----------

//Loading Types
export const SET_AUTH_LOADING = "SET_AUTH_LOADING";
export const SET_SCHEDULE_LOADING = "SET_SCHEDULE_LOADING";
export const SET_SESSION_LOGS_LOADING = "SET_SESSION_LOGS_LOADING";
export const SET_PAYROLL_LOADING = "SET_PAYROLL_LOADING";
export const SET_USERS_LOADING = "SET_USERS_LOADING";
//-------------

//Modal Control Types
export const SCHEDULE_MODAL_CONTROL = "SCHEDULE_MODAL_CONTROL";
//-------------------
