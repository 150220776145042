import React from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import BootstrapTable from "react-bootstrap/Table";
import { BsSortAlphaUp, BsSortAlphaDown } from "react-icons/bs";

import { AiFillDelete, AiFillEdit, AiFillEye } from "react-icons/ai";
import Loading from "../layout/Loading";
import { deleteSessionLog } from "../../actions/sessionLogActions";

const Table = ({
  tableProps,
  bodyProps,
  headers,
  page,
  onPrepareRow,
  loading,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onDelete = async (id) => {
    dispatch(deleteSessionLog(id));
  };

  return (
    <div
      style={{
        position: "relative",
        marginBottom: "1rem",
        minHeight: "400px",
        border: "1px solid lightgrey",
      }}
    >
      {loading && <Loading />}
      <BootstrapTable
        striped
        bordered
        hover
        responsive
        {...tableProps}
        className="mb-0"
      >
        <thead>
          {headers.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>
                  <div
                    {...column.getSortByToggleProps()}
                    className="text-secondary"
                    style={{ fontSize: "1.1rem", cursor: "pointer" }}
                  >
                    {column.render("Header")}{" "}
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <BsSortAlphaDown />
                      ) : (
                        <BsSortAlphaUp />
                      )
                    ) : (
                      ""
                    )}
                  </div>
                  {column.canFilter && column.Header !== "Action"
                    ? column.render("Filter")
                    : null}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...bodyProps}>
          {page.length === 0 && (
            <tr>
              <td colSpan={4} className="text-center">
                No Session Logs...
              </td>
            </tr>
          )}
          {page.map((row, i) => {
            onPrepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  const { row, column, getCellProps } = cell;

                  if (column.Header === "Action") {
                    return (
                      <td {...getCellProps()}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            gap: "0.5rem",
                            alignItems: "center",
                          }}
                        >
                          <Link
                            to={{
                              pathname: `/sessionlogs/${row.values.id}`,
                            }}
                            className="text-secondary"
                          >
                            <AiFillEye style={{ fontSize: "2rem" }} />
                          </Link>
                          <Link
                            to={{
                              pathname: `/sessionlogs/${row.values.id}/edit`,
                            }}
                          >
                            <AiFillEdit style={{ fontSize: "2rem" }} />
                          </Link>
                          <AiFillDelete
                            className="text-danger"
                            style={{ fontSize: "2rem", cursor: "pointer" }}
                            onClick={() => onDelete(row.values.id)}
                          />
                        </div>
                      </td>
                    );
                  }

                  return <td {...getCellProps()}>{cell.render("Cell")}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </BootstrapTable>
    </div>
  );
};

export default Table;
