import React, { useState } from "react";

import ProfileCard from "./ProfileCard";
import ControlArea from "./ControlArea";
import ProfileEditModal from "./ProfileEditModal";
import TimeOffModal from "./TimeOffModal";

const Profile = () => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showTimeOffModal, setShowTimeOffModal] = useState(false);

  return (
    <div className="container my-4">
      <h1 className="text-center text-primary mb-3">Profile</h1>
      <ProfileCard />
      <ControlArea
        handleEdit={() => setShowEditModal(true)}
        handleTimeOff={() => setShowTimeOffModal(true)}
      />
      <ProfileEditModal
        show={showEditModal}
        handleClose={() => setShowEditModal(false)}
      />
      <TimeOffModal
        show={showTimeOffModal}
        handleClose={() => setShowTimeOffModal(false)}
      />
    </div>
  );
};

export default Profile;
