import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import AppointmentItemDetails from "./AppointmentItemDetails";
import AppointmentCancelModal from "./AppointmentCancelModal";
import AppointmentEditModal from "./AppointmentEditModal";
import {
  getAppointmentSettings,
  editAppointment,
  cancelAppointment,
} from "../../actions/scheduleActions";

const AppointmentItem = ({ location: { currentAppointment } }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.profile);
  const history = useHistory();
  const [showCancel, setShowCancel] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const role = currentUser.config.role;

  useEffect(() => {
    dispatch(getAppointmentSettings());
  }, []);

  const handleCancelOpen = () => setShowCancel(true);
  const handleCancelClose = () => setShowCancel(false);

  const handleEditOpen = () => setShowEdit(true);
  const handleEditClose = () => setShowEdit(false);

  const handleAppointmentCancel = async () => {
    await dispatch(cancelAppointment(role, currentAppointment._id));
    setShowCancel(false);
  };

  const handleAppointmentEdit = async (appointment) => {
    await dispatch(editAppointment(appointment, role));

    history.push("/appointments");
  };

  return (
    <>
      <AppointmentEditModal
        appointment={currentAppointment}
        show={showEdit}
        onEdit={handleAppointmentEdit}
        close={handleEditClose}
        role={role}
      />

      <AppointmentCancelModal
        appointment={currentAppointment}
        show={showCancel}
        onCancel={handleAppointmentCancel}
        close={handleCancelClose}
        role={role}
      />

      <div className="container my-4">
        <h1 className="text-center text-primary mb-5">Appointment Details</h1>

        <AppointmentItemDetails
          appointment={currentAppointment}
          role={role}
          showStatus
        />

        <div className="mt-4">
          {role !== "student" && currentAppointment.status !== "Cancelled" && (
            <Button
              onClick={handleEditOpen}
              variant="secondary"
              size="lg"
              block
            >
              Edit Appointment
            </Button>
          )}

          {currentAppointment.status !== "Cancelled" && (
            <Button onClick={handleCancelOpen} variant="danger" size="lg" block>
              Cancel Appointment
            </Button>
          )}

          <Link
            to="/appointments"
            className="btn btn-primary btn-block btn-lg mb-5"
          >
            Back to Appointments
          </Link>
        </div>
      </div>
    </>
  );
};

export default AppointmentItem;
