import React, { useState } from "react";
import { Button } from "react-bootstrap";

import Modal from "./GoogleCalendarModal";

const GoogleCalendar = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="mt-4">
      <Button variant="secondary" block onClick={() => setShowModal(true)}>
        Google Calendar
      </Button>

      <Modal show={showModal} onClose={() => setShowModal(false)} />
    </div>
  );
};

export default GoogleCalendar;
