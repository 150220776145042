import React from "react";
import PropTypes from "prop-types";
import { Form, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { AiOutlineFileAdd, AiOutlineEdit } from "react-icons/ai";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import MainInformation from "./MainInformation";
import SessionItems from "./SessionItems";
import {
  addSessionLog,
  editSessionLog,
} from "../../../actions/sessionLogActions";
import Button from "../../layout/ButtonWithIcon";
import { SessionSchema } from "../../../schemas/sessionlog";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const SessionLogForm = ({ type, session }) => {
  const dispatch = useDispatch();
  const formMethods = useForm({
    resolver: yupResolver(SessionSchema),
    defaultValues: session,
  });
  const loading = useSelector(
    ({ sessionLogs }) => sessionLogs.sessionLogsLoading
  );
  const history = useHistory();

  const submitSessionLog = {
    add: addSessionLog,
    edit: editSessionLog,
  }[type];

  const submitButtonType = {
    add: {
      text: "Add Session Log",
      icon: AiOutlineFileAdd,
    },
    edit: {
      text: "Edit Session Log",
      icon: AiOutlineEdit,
    },
  }[type];

  const onSubmit = async (data) => {
    try {
      await dispatch(submitSessionLog({ data, id: session?._id }));
      history.push(
        type === "add" ? "/sessionlogs" : `/sessionlogs/${session?._id}`
      );
    } catch (error) {}
  };

  const { handleSubmit } = formMethods;

  return (
    <FormProvider {...formMethods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Button
          text={
            loading ? (
              <>
                <Spinner
                  animation="border"
                  variant="light"
                  size="sm"
                  className="mr-2"
                />
                {submitButtonType.text}
              </>
            ) : (
              submitButtonType.text
            )
          }
          icon={submitButtonType.icon}
          className="mb-3"
          variant="secondary"
          type="submit"
          disabled={loading}
        />

        <MainInformation />
        <SessionItems />
      </Form>
    </FormProvider>
  );
};

SessionLogForm.propTypes = {
  type: PropTypes.string,
  givenSessions: PropTypes.array,
};

export default SessionLogForm;
