import React, { useState, useEffect } from "react";
import { Card, Spinner, Button } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import TestInfoSection from "./TestInfoSection";
import { getPackages, htmlDecode, packages } from "../../utils/register";
import { toastify } from "../../utils/actionsHandler";

const PackageSection = () => {
  const [packagesList, setPackagesList] = useState([]);
  const [loading, setLoading] = useState(true);
  const {
    setValue,
    formState: { errors },
    getValues,
  } = useFormContext();
  const [currentPackage, setCurrentPackage] = useState(
    getValues("selectedPackage") ?? {}
  );
  const { t } = useTranslation(null, { keyPrefix: "register.steps" });

  const handlePackageSelect = (selectedPackage) => {
    setCurrentPackage(selectedPackage);
    setValue("selectedPackage", selectedPackage);
  };

  const fetchPackages = () => {
    setLoading(true);
    getPackages()
      .then((data) => {
        setPackagesList(data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        toastify(
          "There was a problem retriving the packages, please try again later",
          "danger",
          5000
        );
      });
  };

  useEffect(() => {
    fetchPackages();
  }, []);

  return (
    <>
      {errors.selectedPackage && (
        <p className="text-danger font-weight-bold">
          * {t("packageSelection.error")}
        </p>
      )}
      {loading ? (
        <div className="d-flex justify-content-center align-items-center h-75">
          <Spinner
            animation="border"
            variant="primary"
            style={{ width: "4rem", height: "4rem" }}
          />
        </div>
      ) : (
        <>
          {packagesList.length === 0 ? (
            <div className="d-flex flex-column justify-content-center align-items-center h-75 ">
              <h4 className="mb-4">No Packages Found...</h4>
              <Button variant="outline-secondary" onClick={fetchPackages}>
                Retry
              </Button>
            </div>
          ) : (
            <>
              <ul className="pl-4">
                <li>
                  <small className="d-block">Taxes Not Included</small>
                </li>
                <li>
                  <small className="d-block mb-2">
                    3% transaction fee if paid by credit/debit
                  </small>
                </li>
              </ul>
              <div className="register-package-selection">
                {packagesList.map((item) => (
                  <Card
                    key={item.id}
                    onClick={() => handlePackageSelect(item)}
                    className={item.id === currentPackage.id ? "active" : null}
                  >
                    <Card.Header className="bg-secondary text-light">
                      {item.price !== 0 && (
                        <>
                          <span className="package-price">${item.price}</span> -{" "}
                        </>
                      )}
                      {htmlDecode(item?.title?.rendered)}
                    </Card.Header>

                    <Card.Body>{item.description}</Card.Body>
                  </Card>
                ))}
              </div>
            </>
          )}
        </>
      )}
      {currentPackage.id === 3 && (
        <div className="mt-4">
          <h4>{t("packageSelection.carRental.additionalInformation.title")}</h4>
          <p className="text-success">
            * {t("packageSelection.carRental.additionalInformation.subtitle")}
          </p>
          <TestInfoSection />
        </div>
      )}
    </>
  );
};

export default PackageSection;
