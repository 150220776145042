import React, { useState } from "react";
import { Form, Button, Spinner, InputGroup } from "react-bootstrap";

import { resetPassword } from "../../api";
import { toastify } from "../../utils/actionsHandler";
import { AiFillEye } from "react-icons/ai";

const ResetForm = ({ token }) => {
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    if (password !== password2) {
      setPasswordsMatch(false);
    }

    try {
      const res = await resetPassword(password, token);

      toastify(res.data.message, "success", 4000);
    } catch (error) {
      console.log(error);
      toastify("An error has occured", "danger");
    }

    setLoading(false);
  };

  const hasError = password && password2 && !passwordsMatch;

  if (!token) {
    return (
      <div className="text-center">
        No authorization token provided, please provide one to reset your
        password
      </div>
    );
  }

  return (
    <Form
      onSubmit={handleSubmit}
      style={{ width: "100%", maxWidth: "400px", margin: "auto" }}
    >
      <Form.Group controlId="password">
        <Form.Label>New Password</Form.Label>

        <InputGroup>
          <Form.Control
            type={showPassword ? "text" : "password"}
            placeholder="New Password..."
            onChange={(e) => setPassword(e.target.value)}
            onBlur={() => setPasswordsMatch(password === password2)}
            value={password}
          />
          <InputGroup.Append>
            <InputGroup.Text>
              <AiFillEye
                className={showPassword ? "text-primary" : ""}
                style={{ cursor: "pointer", fontSize: "1.5rem" }}
                onClick={() => setShowPassword((prev) => !prev)}
              />
            </InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
      </Form.Group>

      <Form.Group controlId="password2">
        <Form.Label>Confirm New Password</Form.Label>
        <Form.Control
          type={showPassword ? "text" : "password"}
          placeholder="New Password Again..."
          onChange={(e) => setPassword2(e.target.value)}
          onBlur={() => setPasswordsMatch(password === password2)}
          value={password2}
        />

        {hasError && (
          <Form.Text className="text-danger">Passwords do not match</Form.Text>
        )}
      </Form.Group>

      <Button variant="primary" type="submit" disabled={hasError && loading}>
        {loading ? (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        ) : (
          "Reset Password"
        )}
      </Button>
    </Form>
  );
};

export default ResetForm;
