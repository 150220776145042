import React, { useState } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../actions/authActions";

import logo from "../../assets/images/logo-navbar.png";

const PublicLinks = ({ handleClick, active }) => {
  return (
    <>
      <Link
        className={`nav-link ${active === "/login" && "active"}`}
        to="/login"
        onClick={handleClick}
      >
        Login
      </Link>
      <Link
        className={`nav-link ${active === "/register" && "active"}`}
        to="/register"
        onClick={handleClick}
      >
        Register
      </Link>
      <Link
        className={`nav-link ${active === "/resetpassword" && "active"}`}
        to="/resetpassword"
        onClick={handleClick}
      >
        Reset Password
      </Link>
    </>
  );
};

const StudentLinks = ({ handleClick, handleLogout, active }) => {
  return (
    <>
      <Link
        className={`nav-link ${active === "/appointments" && "active"}`}
        to="/appointments"
        onClick={handleClick}
      >
        Appointments
      </Link>
      <Link
        className={`nav-link ${active === "/profile" && "active"}`}
        to="/profile"
        onClick={handleClick}
      >
        Profile
      </Link>

      <a className="nav-link" href="#!" onClick={handleLogout}>
        Logout
      </a>
    </>
  );
};

const InstructorLinks = ({ handleClick, handleLogout, active }) => {
  return (
    <>
      <Link
        className={`nav-link ${active === "/appointments" && "active"}`}
        to="/appointments"
        onClick={handleClick}
      >
        Calendar
      </Link>
      <Link
        className={`nav-link ${active === "/sessionlogs" && "active"}`}
        to="/sessionlogs"
        onClick={handleClick}
      >
        Session Logs
      </Link>
      {/* <Link
        className={`nav-link ${active === "/students" && "active"}`}
        to="/students"
        onClick={handleClick}
      >
        Students
      </Link> */}
      <Link
        className={`nav-link ${active === "/profile" && "active"}`}
        to="/profile"
        onClick={handleClick}
      >
        Profile
      </Link>

      <a className="nav-link" href="#!" onClick={handleLogout}>
        Logout
      </a>
    </>
  );
};

const AdminLinks = ({ handleClick, handleLogout, active }) => {
  return (
    <>
      <Link
        className={`nav-link ${active === "/users" && "active"}`}
        to="/users"
        onClick={handleClick}
      >
        Users
      </Link>

      <Link
        className={`nav-link ${active === "/payroll" && "active"}`}
        to="/payroll"
        onClick={handleClick}
      >
        Payroll
      </Link>

      <Link
        className={`nav-link ${active === "/appointments" && "active"}`}
        to="/appointments"
        onClick={handleClick}
      >
        Appointments
      </Link>
      <Link
        className={`nav-link ${active === "/sessionlogs" && "active"}`}
        to="/sessionlogs"
        onClick={handleClick}
      >
        Session Logs
      </Link>
      <Link
        className={`nav-link ${active === "/profile" && "active"}`}
        to="/appointment"
        onClick={handleClick}
      >
        Profile
      </Link>

      <a className="nav-link" href="#!" onClick={handleLogout}>
        Logout
      </a>
    </>
  );
};

const Header = () => {
  const {
    isAuthenticated,
    profile: { config = { role: "" } },
  } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [active, setActive] = useState(window.location.pathname);

  const handleClick = (e) => {
    setActive(e.target.pathname);
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <Navbar bg="primary" variant="dark" collapseOnSelect expand="sm">
      <a className="navbar-brand" href="https://new-drivers.ca/">
        <img src={logo} width="85" alt="Logo" />
      </a>
      <Navbar.Toggle aria-controls="navbar" />
      <Navbar.Collapse id="navbar">
        <Nav className="ml-auto">
          {!isAuthenticated && (
            <PublicLinks handleClick={handleClick} active={active} />
          )}

          {config.role === "student" && (
            <StudentLinks
              handleClick={handleClick}
              handleLogout={handleLogout}
              active={active}
            />
          )}
          {config.role === "instructor" && (
            <InstructorLinks
              handleClick={handleClick}
              handleLogout={handleLogout}
              active={active}
            />
          )}

          {config.role === "admin" && (
            <AdminLinks
              handleClick={handleClick}
              handleLogout={handleLogout}
              active={active}
            />
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
