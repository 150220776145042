import React from "react";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";

import PropTypes from "prop-types";

const AutoComplete = ({ onChange, placeholder }) => {
  const handleAutoComplete = async (address) => {
    const { place_id } = address.value;

    try {
      const [result] = await geocodeByPlaceId(place_id);
      const { address_components } = result;

      const streetAddress = `${address_components[0].long_name} ${address_components[1].long_name}`;
      const city = address_components[3].long_name;
      const province = address_components[5].long_name;
      const postalCode = address_components[7]
        ? address_components[7].long_name
        : ""; //Postal code is not always returned from API call

      const computedAddress = {
        streetAddress,
        street: streetAddress,
        city,
        province,
        postalCode,
      };

      onChange(computedAddress);
    } catch (error) {
      console.log(error);
      //ALERT USER THEY HAVE TO ENTER THE DETAILS MANUALLY
    }
  };

  return (
    <GooglePlacesAutocomplete
      apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      selectProps={{
        onChange: handleAutoComplete,
        placeholder,
      }}
      autocompletionRequest={{
        componentRestrictions: {
          country: ["ca"],
        },
        types: ["address"],
      }}
    />
  );
};

AutoComplete.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
};

AutoComplete.defaultProps = {
  placeholder: "Type in New Address Here...",
};

export default AutoComplete;
