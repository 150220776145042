import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { StepContext } from "..";
import StepButtons from "./StepButtons";
import { stepsList } from "../../../utils/register";
import Confirmation from "./Confirmation";
import { register } from "../../../actions/authActions";

const Steps = () => {
  const [completed, setCompleted] = useState(false);
  const dispatch = useDispatch();
  const { handleSubmit } = useFormContext();
  const { t } = useTranslation(null, { keyPrefix: "register" });
  const { currentStep, onStepChange } = useContext(StepContext);

  const submit = async (data) => {
    console.log(data);
    try {
      await dispatch(register(data));
      setCompleted(true);
    } catch (error) {}
  };

  return (
    <div>
      <div className="steps-list">
        {completed && <div className="disabled-overlay" />}
        {stepsList()
          .slice(1)
          .map((step) => (
            <button
              key={step.id}
              onClick={() => onStepChange(step.id)}
              className={step.id === currentStep ? "active" : null}
              disabled={step.id === currentStep}
            >
              {step.title}
              {!step.required && (
                <>
                  <br />
                  <small className="text-success">* Optional</small>
                </>
              )}
            </button>
          ))}
      </div>
      <div className="registration-steps-wrapper">
        {completed ? (
          <Confirmation />
        ) : (
          stepsList().map(
            ({ id, title, Step }) =>
              currentStep === id && (
                <React.Fragment key={id}>
                  {id > 0 && (
                    <h3 className="mb-3 text-secondary">
                      {t("steps.text")} {id}. - {title}
                    </h3>
                  )}
                  <Step />
                </React.Fragment>
              )
          )
        )}
      </div>
      {!completed && (
        <StepButtons
          currentStep={currentStep}
          onStepChange={onStepChange}
          onSubmit={handleSubmit(submit)}
        />
      )}
    </div>
  );
};

Steps.propTypes = {
  current: PropTypes.number.isRequired,
};

export default Steps;
