import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useGoogleLogin } from "react-google-login";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import { AiFillGoogleCircle } from "react-icons/ai";

import {
  registerGoogleAuthorization,
  removeGoogleAuthorization,
} from "../../../actions/authActions";

const GoogleCalendarModal = ({ show, onClose }) => {
  const dispatch = useDispatch();
  const { isConnectedToGoogle = false } = useSelector(
    (state) => state.auth.profile
  );
  const loading = useSelector((state) => state.auth?.authLoading);

  const onSuccess = (res) => {
    // console.log(res);

    //Save to users profile
    if (res) dispatch(registerGoogleAuthorization(res));
  };

  const { signIn } = useGoogleLogin({
    responseType: "code",
    accessType: "offline",
    onSuccess,
    scope: "https://www.googleapis.com/auth/calendar",
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  });

  const handleClick = () => {
    isConnectedToGoogle ? dispatch(removeGoogleAuthorization()) : signIn();
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <AiFillGoogleCircle /> Google Calendar Integration
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3">
          {isConnectedToGoogle
            ? "Your Google Account is Connected to Your New Drivers Account"
            : "Connect your Google account here to sync your appointments with Google Calendar"}
        </div>
        <Button
          variant="primary"
          size="sm"
          onClick={handleClick}
          disabled={loading}
        >
          {isConnectedToGoogle
            ? "Remove Google Account"
            : "Click Here to Connect Google Account"}
        </Button>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          {loading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            "Close"
          )}
        </Button>
        {/* <Button variant="primary" onClick={onClose}>
          Save Changes
        </Button> */}
      </Modal.Footer>
    </Modal>
  );
};

export default GoogleCalendarModal;
