import React from "react";
import { Route, Switch } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import Students from "../components/students";

import Login from "../components/login/Login";
import Register from "../components/register/";
import ResetPassword from "../components/resetpassword";
import ResetPasswordRequest from "../components/resetpassword/ResetPasswordRequest";
import Profile from "../components/profile/Profile";
import Users from "../components/users";
import UserItem from "../components/users/UserItem";
import EditUser from "../components/users/edit";
import AddNewUser from "../components/users/add";
import UserSessions from "../components/users/Sessions";
import Appointments from "../components/appointments/Appointments";
import AppointmentItem from "../components/appointments/AppointmentItem";
import BookAppointment from "../components/appointments/booking/BookAppointment";
import ConfirmMessage from "../components/appointments/booking/ConfirmMessage";
import SessionLogs from "../components/sessionlogs";
import SessionLogItem from "../components/sessionlogs/SessionLogItem";
import EditSessionLog from "../components/sessionlogs/edit";
import AddNewSessionLog from "../components/sessionlogs/add";
import AddPrivateStudent from "../components/appointments/instructor/privatestudent/add";
import Payroll from "../components/payroll";
import AppointmentOperation from "../components/appointments/AppointmentOperation";

const Routes = () => {
  return (
    <Switch>
      <PublicRoute exact path="/" component={Login} />
      <PublicRoute path="/login" component={Login} />
      <PublicRoute path="/register" component={Register} />
      <Route path="/appointment/:id" component={AppointmentOperation} />
      <PublicRoute
        exact
        path="/resetpassword/"
        component={ResetPasswordRequest}
      />
      <PublicRoute path="/resetpassword/:token" component={ResetPassword} />
      <PrivateRoute
        allowed={["student", "instructor", "admin"]}
        path="/profile"
        component={Profile}
      />
      <PrivateRoute
        exact
        allowed={["student", "instructor", "admin"]}
        path="/appointments"
        component={Appointments}
      />

      <PrivateRoute
        allowed={["student", "instructor", "admin"]}
        path="/appointments/:id"
        component={AppointmentItem}
      />

      <PrivateRoute
        exact
        allowed={["student", "instructor", "admin"]}
        path="/book"
        component={BookAppointment}
      />

      <PrivateRoute
        allowed={["student", "instructor", "admin"]}
        path="/book/confirm"
        component={ConfirmMessage}
      />

      <PrivateRoute
        exact
        allowed={["instructor", "admin"]}
        path="/sessionlogs"
        component={SessionLogs}
      />

      <PrivateRoute allowed={["admin"]} path="/payroll" component={Payroll} />

      <PrivateRoute
        exact
        allowed={["instructor", "admin"]}
        path="/sessionlogs/:id"
        component={SessionLogItem}
      />

      <PrivateRoute
        allowed={["instructor", "admin"]}
        path="/sessionlogs/:id/edit"
        component={EditSessionLog}
      />

      <PrivateRoute
        allowed={["instructor", "admin"]}
        path="/newsessionlog"
        component={AddNewSessionLog}
      />

      <PrivateRoute
        allowed={["instructor", "admin"]}
        path="/students"
        component={Students}
      />

      <PrivateRoute allowed={["admin"]} exact path="/users" component={Users} />

      <PrivateRoute
        exact
        allowed={["admin", "instructor"]}
        path="/users/:id"
        component={UserItem}
      />

      <PrivateRoute
        exact
        allowed={["admin"]}
        path="/newUser"
        component={AddNewUser}
      />

      <PrivateRoute
        allowed={["admin"]}
        path="/users/:id/edit"
        component={EditUser}
      />

      <PrivateRoute
        allowed={["admin"]}
        path="/users/:id/sessions"
        component={UserSessions}
      />

      <PrivateRoute
        allowed={["instructor", "admin"]}
        path="/newPrivateStudent"
        component={AddPrivateStudent}
      />
    </Switch>
  );
};

export default Routes;
