import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import Select from "react-select";
import { packages } from "../../../../utils/register";

const PaymentTypeAndPackage = () => {
  const {} = useFormContext();

  const packagesOptions = packages().map((packageItem) => ({
    label: packageItem.name,
    value: packageItem,
  }));

  const paymentOptions = [
    { label: "Debit/Credit Card", value: "Debit/Credit Card" },
    { label: "E-Transfer", value: "E-Transfer" },
    { label: "Cash", value: "Cash" },
  ];

  return (
    <Row>
      <Col lg>
        <Form.Group>
          <Form.Label>Payment Method</Form.Label>

          <Controller
            name="config.paymentMethod"
            render={({ field: { onChange, value, ...rest } }) => (
              <Select
                placeholder="Select a Payment Method"
                options={paymentOptions}
                value={
                  value && paymentOptions.find((item) => item.value === value)
                }
                onChange={({ value }) => onChange(value)}
                {...rest}
              />
            )}
          />
        </Form.Group>
      </Col>

      <Col lg>
        <Form.Group>
          <Form.Label>Package</Form.Label>

          <Controller
            name="config.selectedPackage"
            render={({ field: { onChange, value, ...rest } }) => (
              <Select
                placeholder="Select a Package"
                options={packagesOptions}
                value={
                  value &&
                  packagesOptions.find(
                    (packageItem) => packageItem.value?.id === value.id
                  )
                }
                onChange={({ value }) => onChange(value)}
                {...rest}
              />
            )}
          />
        </Form.Group>
      </Col>
    </Row>
  );
};

export default PaymentTypeAndPackage;
