import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment";

import { getAppointments } from "../../actions/scheduleActions";

import { AiFillEye, AiFillEdit } from "react-icons/ai";

const AppointmentsList = ({ handleEdit }) => {
  const dispatch = useDispatch();
  const appointments = useSelector((state) => state.schedule.appointments);
  const loading = useSelector((state) => state.schedule.scheduleLoading);
  const loggedInUser = useSelector((state) => state.auth.profile);
  const role = loggedInUser.config.role;

  useEffect(() => {
    dispatch(getAppointments(role));
  }, []);

  const getStatusClass = (status) => {
    if (status === "Approved") return "text-light bg-primary";

    if (status === "Cancelled") return "text-light bg-danger";

    if (status === "Pending") return "text-dark bg-warning";
  };

  return (
    <div className="table-wrapper">
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>{role === "student" ? "Instructor" : "Student"}</th>
            <th>Session</th>
            <th>Date</th>
            <th>Time</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          {appointments.length > 0 && loading && (
            <tr>
              <td colSpan="6" className="text-center">
                <Spinner animation="border" variant="primary" />
              </td>
            </tr>
          )}
          {appointments.length === 0 && loading && (
            <tr>
              <td colSpan="6" className="text-center">
                <Spinner animation="border" variant="primary" />
              </td>
            </tr>
          )}
          {appointments.length === 0 && !loading && (
            <tr>
              <td colSpan="6" className="text-center">
                No Upcoming Appointments
              </td>
            </tr>
          )}
          {!loading &&
            appointments.map((appointment) => (
              <tr
                key={appointment._id}
                className={appointment.current && "bg-primary text-light"}
              >
                <td>
                  {role === "student"
                    ? `${appointment.instructorID.firstName} ${appointment.instructorID.lastName}`
                    : `${appointment.studentID.firstName} ${appointment.studentID.lastName}`}
                </td>
                <td>{appointment.sessionID.name}</td>
                <td>
                  {moment(appointment.dateAndTime).format(
                    "dddd, MMMM Do, YYYY"
                  )}
                </td>
                <td>{moment(appointment.dateAndTime).format("h:mm a")}</td>
                <td
                  className={`text-center ${getStatusClass(
                    appointment.status
                  )}`}
                >
                  {appointment.status}
                </td>

                <td className="d-flex justify-content-center">
                  <Link
                    to={{
                      pathname: `/appointments/${appointment._id}`,
                      currentAppointment: appointment,
                    }}
                    className="text-secondary"
                  >
                    <AiFillEye style={{ fontSize: "1.5rem" }} />
                  </Link>

                  {role !== "student" && (
                    <AiFillEdit
                      style={{ fontSize: "1.5rem", cursor: "pointer" }}
                      onClick={() => handleEdit(appointment)}
                    />
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  );
};

export default AppointmentsList;
