import React, { useState } from "react";
import { Form, Spinner, Pagination } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineFileAdd, AiOutlineEdit } from "react-icons/ai";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { useHistory } from "react-router-dom";

import { UserSchema } from "../../../../../schemas/user";
import UserInformation from "../../../../users/form/UserInformation";
import DriversLicenseInformation from "../../../../users/form/driversLicense";
import OnlineCourse from "../../../../users/form/additionalInformation/OnlineCourse";
import Button from "../../../../layout/ButtonWithIcon";
import { packages } from "../../../../../utils/register";
import { createPrivateStudent } from "../../../../../actions/scheduleActions";

const PrivateStudentForm = ({ type, student }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const dispatch = useDispatch();
  const history = useHistory();

  const loading = useSelector((state) => state.users.usersLoading);
  const instructorID = useSelector((state) => state.auth.profile._id);

  const formMethods = useForm({
    resolver: yupResolver(UserSchema),
    defaultValues: {
      username: "unimportant",
      config: {
        role: "student",
        selectedPackage: packages()[2],
        isPrivate: true,
        associatedInstructor: instructorID,
      },
      ...student,
    },
  });

  const handlePageChange = (page) => {
    if (page < 0 || page >= pages.length) return;

    setCurrentPage(page);
  };

  const submitButtonType = {
    add: {
      text: "Add New Private Student",
      icon: AiOutlineFileAdd,
    },
    edit: {
      text: "Edit Private Student",
      icon: AiOutlineEdit,
    },
  }[type];

  const pages = [UserInformation, DriversLicenseInformation, OnlineCourse];

  const CurrentPage = pages[currentPage];

  const onSubmit = async (data) => {
    try {
      await dispatch(type === "add" ? await createPrivateStudent(data) : "");

      history.push("/appointments");
    } catch (error) {}
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <FormProvider {...formMethods}>
        <Form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <Button
            text={
              loading ? (
                <>
                  <Spinner
                    animation="border"
                    variant="light"
                    size="sm"
                    className="mr-2"
                  />
                  {submitButtonType.text}
                </>
              ) : (
                submitButtonType.text
              )
            }
            icon={submitButtonType.icon}
            className="mb-3"
            variant="secondary"
            type="submit"
            disabled={loading}
          />

          <CurrentPage isInstructor />

          <div className="mt-5 d-flex justify-content-center">
            <Pagination>
              <Pagination.First
                onClick={() => handlePageChange(0)}
                disabled={currentPage - 1 < 0}
              />
              <Pagination.Prev
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage - 1 < 0}
              />
              {pages.map((_, i) => (
                <Pagination.Item
                  key={i}
                  onClick={() => setCurrentPage(i)}
                  active={i === currentPage}
                >
                  {i + 1}
                </Pagination.Item>
              ))}
              <Pagination.Next
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage + 1 >= pages.length}
              />
              <Pagination.Last
                onClick={() => handlePageChange(pages.length - 1)}
                disabled={currentPage + 1 >= pages.length}
              />
            </Pagination>
          </div>
        </Form>
      </FormProvider>
    </MuiPickersUtilsProvider>
  );
};

PrivateStudentForm.defaultProps = {
  student: {},
};

export default PrivateStudentForm;
