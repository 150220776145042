import React, { useState, useRef } from "react";
import { Form, Button, Spinner, InputGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { AiFillEye } from "react-icons/ai";

import { login } from "../../actions/authActions";

const Login = ({ location: { state } }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { authLoading, isAuthenticated } = useSelector((state) => state.auth);
  const [showPassword, setShowPassword] = useState(false);

  const usernameRef = useRef(null);
  const passwordRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const username = usernameRef.current.value;
    const password = passwordRef.current.value;

    dispatch(await login(username, password));
  };

  return (
    <div className="form-page container mx-auto mt-4 mb-4">
      <h1 className="text-center text-secondary">Login</h1>

      {state && <h4 className="text-center text-secondary">{state.message}</h4>}

      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Label>Username</Form.Label>
          <Form.Control
            type="text"
            placeholder="Your username"
            ref={usernameRef}
            required
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Password</Form.Label>

          <InputGroup className="mb-2">
            <Form.Control
              type={showPassword ? "text" : "password"}
              placeholder="Your password"
              ref={passwordRef}
              required
            />
            <InputGroup.Append>
              <InputGroup.Text>
                <AiFillEye
                  className={showPassword ? "text-primary" : ""}
                  style={{ cursor: "pointer", fontSize: "1.5rem" }}
                  onClick={() => setShowPassword((prev) => !prev)}
                />
              </InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </Form.Group>

        <Form.Group controlId="formBasicCheckbox">
          <Form.Text as="p">
            Forgot your password?{" "}
            <Link className="text-primary" to="/resetpassword">
              Click here
            </Link>
          </Form.Text>
        </Form.Group>
        <Button variant="primary" type="submit" disabled={authLoading}>
          {!authLoading ? (
            "Login"
          ) : (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
        </Button>
      </Form>
    </div>
  );
};

export default Login;
