import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import { Spinner, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { useReactToPrint } from "react-to-print";

import { getUsers } from "../../actions/userActions";
import ResultCard from "./ResultCard";
import { getAppointments } from "../../api";
import moment from "moment";
import Table from "./Table";

const Payroll = () => {
  const [loading, setLoading] = useState(false);
  const [instructor, setInstructor] = useState(null);
  const [appointments, setAppointments] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const dispatch = useDispatch();
  const { users, usersLoading } = useSelector((state) => state.users);
  const { profile } = useSelector((state) => state.auth);
  const cardRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => cardRef.current,
  });
  const role = profile?.config?.role;

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  useEffect(() => {
    if (instructor && startDate && endDate) {
      setLoading(true);

      getAppointments("admin", [instructor?.value?._id]).then(({ data }) => {
        const filtered = data.appointments.filter((item) =>
          moment(item.dateAndTime).isBetween(startDate, endDate, [])
        );

        setAppointments(filtered);
        setLoading(false);
      });
    }
  }, [instructor, startDate, endDate]);

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const handleClear = () => {
    setInstructor(null);
    setAppointments([]);
    setStartDate(new Date());
    setEndDate(null);
  };

  const instructors = users.filter(
    (user) => user?.config?.role === "instructor"
  );

  const [totalHours, privateHours, nonPrivateHours] = appointments.reduce(
    (acc, curr) => {
      const duration = Number(curr.duration);
      const newTotalHours = acc[0] + duration;
      const newPrivateHours = curr.studentID.config.isPrivate
        ? acc[1] + duration
        : acc[1];
      const newNonPrivateHours = !curr.studentID.config.isPrivate
        ? acc[2] + duration
        : acc[2];

      return [newTotalHours, newPrivateHours, newNonPrivateHours];
    },
    [0, 0, 0]
  );

  const resultPayload = {
    totalHours,
    privateHours,
    nonPrivateHours,
    instructor: instructor?.value,
    startDate,
    endDate,
    appointments: appointments.length,
    loading,
  };

  appointments.sort(
    (a, b) => new Date(b.dateAndTime) - new Date(a.dateAndTime)
  );

  return (
    <div className="container my-4">
      <h1 className="text-center text-primary mb-3 title">Payroll</h1>

      <div ref={cardRef} className="payroll-results-wrapper">
        <Row className="mb-3">
          <Col
            lg={4}
            className="hide-on-print d-flex flex-column justify-content-between"
          >
            <Select
              isLoading={usersLoading}
              options={instructors.map((user) => ({
                label: `${user.firstName} ${user.lastName}`,
                value: user,
              }))}
              onChange={setInstructor}
              value={instructor}
              placeholder={
                usersLoading ? `Loading...` : "Select an Instructor..."
              }
              className="mb-3"
              styles={{
                menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
              }}
            />

            <div className="d-flex justify-content-center">
              <DatePicker
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                inline
                maxDate={new Date()}
              />
            </div>
          </Col>

          <Col lg={8} className="container w-100">
            <Button
              variant="secondary"
              onClick={handlePrint}
              disabled={!instructor?.value || !startDate || !endDate}
              className="hide-on-print mb-3"
            >
              Print Details
            </Button>
            <Button
              variant="outline-secondary"
              onClick={handleClear}
              className="mb-3 ml-2 hide-on-print"
            >
              Clear
            </Button>

            <ResultCard {...resultPayload} />
          </Col>
        </Row>

        {loading ? (
          <>
            <h2 className="my-4 title">Appointment(s)</h2>
            <div className="table-wrapper payroll-table-wrapper">
              <div className="d-flex justify-content-center align-items-center w-100 h-100">
                <Spinner
                  animation="border"
                  variant="primary"
                  style={{ width: "4rem", height: "4rem" }}
                />
              </div>
            </div>
          </>
        ) : (
          <div className="table-wrapper payroll-table-wrapper">
            <Table
              headers={[
                { key: "session", label: "Session" },
                { key: "student", label: "Student" },
                role === "admin" && {
                  key: "instructor",
                  label: "Instructor",
                },
                { key: "date", label: "Date" },
                { key: "time", label: "Time" },
                { key: "duration", label: "Duration" },
              ]}
              data={appointments
                .map(
                  ({
                    sessionID,
                    studentID,
                    instructorID,
                    dateAndTime,
                    duration,
                    _id,
                  }) => ({
                    id: _id,
                    session: sessionID?.name,
                    student: `${studentID?.firstName} ${studentID?.lastName}`,
                    instructor: `${instructorID?.firstName} ${instructorID?.lastName}`,
                    date: moment(dateAndTime).format("dddd, MMMM Do, YYYY"),
                    time: moment(dateAndTime).format("h:mm a"),
                    duration: `${duration} hour(s)`,
                  })
                )
                .sort(
                  (a, b) => new Date(b.dateAndTime) - new Date(a.dateAndTime)
                )}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Payroll;
