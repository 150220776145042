import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import MaskedInput from "react-text-mask";
import { Form, Col } from "react-bootstrap";
import Dropzone from "react-dropzone";
import { AiFillDelete } from "react-icons/ai";
import DatePicker from "react-date-picker";
import { useTranslation } from "react-i18next";

import { driversLicenseMask, MAX_FILE_SIZE } from "../../utils/register";
import { getBase64 } from "../../utils/base64";
import { toastify } from "../../utils/actionsHandler";

const LicenseInfoSection = () => {
  const { setValue, getValues } = useFormContext();
  const [image, setImage] = useState("");
  const [expiryDate, setExpiryDate] = useState(
    getValues("driversLicense.expiry")
  );
  const { t } = useTranslation(null, { keyPrefix: "register.steps" });

  const handleImageAccepted = (file) => {
    const givenFile = file[0];

    getBase64(givenFile, (error, base64Image) => {
      if (error) {
        return;
      }

      setValue("driversLicense.imageFront", base64Image);
      setImage(base64Image);
    });
  };

  const handleUploadRejected = (errorArray) => {
    const errors = errorArray[0].errors;

    const responseMessage = {
      "file-invalid-type": "File must be an Image",
      "file-too-large": `File size must be ${MAX_FILE_SIZE}MBs or below`,
    };

    errors.forEach((error) =>
      toastify(responseMessage[error.code], "danger", 5000)
    );
  };

  const removeImage = () => {
    setImage("");
    setValue("driversLicense.image", null);
  };

  const handleExpiryDateChange = (date) => {
    setValue("driversLicense.expiry", date);
    setExpiryDate(date);
  };

  return (
    <>
      <h5 className="text-success">* {t("driversLicense.optional")}</h5>
      <Form.Row>
        <Col lg>
          <Form.Group controlId="license.number">
            <Form.Label>{t("driversLicense.number.label")}</Form.Label>

            <MaskedInput
              mask={driversLicenseMask}
              placeholder={t("driversLicense.number.placeholder")}
              onChange={(e) =>
                setValue("driversLicense.number", e.target.value)
              }
              className="form-control"
            />
          </Form.Group>
        </Col>

        <Col lg>
          <Form.Group>
            <Form.Label style={{ display: "block" }}>
              {t("driversLicense.expiryDate.label")}
            </Form.Label>

            <DatePicker value={expiryDate} onChange={handleExpiryDateChange} />
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row className="mb-4">
        <Col lg>
          <label>{t("driversLicense.image.label")}</label>
          <Dropzone
            onDropAccepted={handleImageAccepted}
            onDropRejected={handleUploadRejected}
            maxSize={MAX_FILE_SIZE}
            maxFiles={1}
            accept="image/*"
          >
            {({ getRootProps, getInputProps, isDragActive }) => (
              <div {...getRootProps()} className="license-upload">
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p className="text-primary">
                    {t("driversLicense.image.placeholder")}
                  </p>
                ) : (
                  <p className="label">
                    {t("driversLicense.image.placeholder")}
                  </p>
                )}
              </div>
            )}
          </Dropzone>
          {image && (
            <div className="thumbnail mt-3">
              <AiFillDelete className="delete-icon" onClick={removeImage} />

              <img src={image} alt="License Thumbnail" />
            </div>
          )}
        </Col>
      </Form.Row>
    </>
  );
};

export default LicenseInfoSection;
