import React from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const ButtonWithIcon = ({ icon: Icon, text, link, ...rest }) => {
  const history = useHistory();

  return link ? (
    <Button
      {...rest}
      className={`d-flex align-items-center ${
        rest.className && rest.className
      }`}
      onClick={() => history.push(link)}
    >
      {Icon && <Icon className={text && "mr-2"} />} {text}
    </Button>
  ) : (
    <Button
      {...rest}
      className={`d-flex align-items-center ${
        rest.className && rest.className
      }`}
    >
      {Icon && <Icon className={text && "mr-2"} />} {text}
    </Button>
  );
};

export default ButtonWithIcon;
