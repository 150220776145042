import React from "react";
import { AiFillEdit, AiFillEye } from "react-icons/ai";
import { Link } from "react-router-dom";

const UserActions = ({ userID, onEditUser, onRedirect }) => {
  return (
    <div className="user-actions">
      <AiFillEye
        className="text-secondary"
        style={{
          fontSize: "2rem",
          color: "blue",
          cursor: "pointer",
        }}
        onClick={() => onRedirect(userID)}
      />

      <Link to={`/users/${userID}/edit`}>
        <AiFillEdit
          className="text-primary"
          style={{
            fontSize: "2rem",
            cursor: "pointer",
          }}
          onClick={() => onEditUser(userID)}
        />
      </Link>
    </div>
  );
};

export default UserActions;
