import React from "react";
import moment from "moment";
import {
  Modal,
  Button,
  ButtonGroup,
  Form,
  Col,
  Row,
  ListGroup,
  Spinner,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import Dropzone from "react-dropzone";
import { AiOutlineUserAdd, AiFillEdit, AiFillDelete } from "react-icons/ai";
import MaskedInput from "react-text-mask";
import DatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import Select from "react-select";

import { provinces } from "../../utils/provinces";

const MIN_AGE = 16;

const packages = [
  {
    id: 0,
    name: "Basic Package",
    description:
      "20 hours online learning, 10 hours online homework, 10 hours in-car",
    price: 600,
    active: true,
  },
  {
    id: 1,
    name: "Preferred Package",
    description:
      "20 hours online learning 10 hours online homework, 10 hours in-car, PLUS 1 hour of in-car test preparation, and the instructor’s car for the test",
    price: 750,
    active: true,
  },
  {
    id: 2,
    name: "Test Preperation",
    description:
      "1 hour in-car training 2 hours in-car training 1 hour in-car training plus the instructor’s car for the test 2 hour in-car training plus the instructor’s car for the test",
    price: 0,
    active: true,
  },
];

const driversLicenseMask = [
  /[a-zA-Z]/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

const PaymentRecordPopover = ({ popoverProps, notes }) => (
  <Popover {...popoverProps}>
    <Popover.Title as="h3">Notes</Popover.Title>
    <Popover.Content>{notes}</Popover.Content>
  </Popover>
);

const UserModal = ({
  show,
  close,
  submit,
  type,
  user,
  paymentRecord,
  onRecordChange,
  addPaymentRecord,
  removePaymentRecord,
  onChange,
  onConfigChange,
  onUserTypeSelect,
  handlePackageSelect,
  handleAddressChange,
  onAddSchedule,
  removeScheduleItem,
  imageHandle,
  handleLicenseChange,
  onToggleIsPrivate,
  onInstructorSelect,
}) => {
  const modalType = {
    add: {
      title: "Add New User",
      submitButton: "Add User",
      icon: <AiOutlineUserAdd />,
    },
    edit: {
      title: "Edit User",
      submitButton: "Edit User",
      icon: <AiFillEdit />,
    },
  }[type];

  const { userLoading: loading, users } = useSelector((state) => state.users);

  const setMaxDate = () => {
    const [year, month, day] = [
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate(),
    ];

    const maxDate = new Date(year - MIN_AGE, month, day);
    return maxDate;
  };

  return (
    <Modal show={show} onHide={close} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          {modalType.icon} {modalType.title}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form>
          <h4>Basic Information</h4>
          <Form.Row>
            <Col md>
              <Form.Group controlId="type">
                <Form.Label>User Type</Form.Label>

                <Form.Control
                  as="select"
                  onChange={onUserTypeSelect}
                  value={user.config?.role || ""}
                >
                  <option disabled value="">
                    Select User Type
                  </option>

                  <option value="student">Student</option>
                  <option value="instructor">Instructor</option>
                </Form.Control>
              </Form.Group>
            </Col>

            <Col md>
              <Form.Group controlId="username">
                <Form.Label>Username</Form.Label>

                <Form.Control
                  type="text"
                  placeholder="Username..."
                  onChange={onChange}
                  value={user?.username || ""}
                />
              </Form.Group>
            </Col>
          </Form.Row>

          <Form.Row>
            {user.config?.role === "student" && (
              <>
                <Col sm>
                  <Form.Group>
                    <Form.Label>Associated Instructor</Form.Label>

                    <Select
                      options={users
                        .filter((user) => user.config.role === "instructor")
                        .map((user) => ({
                          label: `${user.firstName} ${user.lastName}`,
                          value: user._id,
                        }))}
                      value={{
                        label: (() => {
                          const instructor = users.find(
                            ({ _id }) =>
                              _id === user.config?.associatedInstructor
                          );

                          if (instructor) {
                            return `${instructor.firstName} ${instructor.lastName}`;
                          } else {
                            return "Select Instructor...";
                          }
                        })(),
                      }}
                      onChange={onInstructorSelect}
                    />
                  </Form.Group>
                </Col>
                <Col sm={2}>
                  <Form.Group controlId="isPrivate">
                    <Form.Label>Is Private</Form.Label>

                    <Form.Check
                      type="switch"
                      onChange={onToggleIsPrivate}
                      checked={user?.config?.isPrivate || false}
                    />
                  </Form.Group>
                </Col>
              </>
            )}
          </Form.Row>

          <Form.Row>
            <Col>
              <Form.Group controlId="firstName">
                <Form.Label>First Name</Form.Label>

                <Form.Control
                  type="text"
                  placeholder="First Name..."
                  onChange={onChange}
                  value={user?.firstName || ""}
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group controlId="lastName">
                <Form.Label>Last Name</Form.Label>

                <Form.Control
                  type="text"
                  placeholder="Last Name..."
                  onChange={onChange}
                  value={user?.lastName || ""}
                />
              </Form.Group>
            </Col>
          </Form.Row>

          <Form.Row>
            <Col lg>
              <Form.Group controlId="email">
                <Form.Label>Email</Form.Label>

                <Form.Control
                  type="text"
                  placeholder="Email Address..."
                  onChange={onChange}
                  value={user?.email || ""}
                />
              </Form.Group>
            </Col>

            <Col lg>
              <Form.Group controlId="phoneNumber">
                <Form.Label>Phone Number</Form.Label>

                <Form.Control
                  type="text"
                  placeholder="Phone Number..."
                  onChange={onChange}
                  value={user?.phoneNumber || ""}
                />
              </Form.Group>
            </Col>

            <Col lg>
              <Form.Group controlId="dateOfBirth">
                <Form.Label style={{ display: "block" }}>
                  Date of Birth
                </Form.Label>
                <DatePicker
                  className="form-control"
                  selected={
                    user?.dateOfBirth ? new Date(user.dateOfBirth) : null
                  }
                  dateFormat="MMMM d, yyyy"
                  showMonthDropdown
                  showYearDropdown
                  yearDropdownItemNumber={16}
                  openToDate={setMaxDate()}
                  maxDate={setMaxDate()}
                  scrollableYearDropdown
                  placeholderText="Date of Birth..."
                  withPortal
                  onChange={(date) => {
                    const e = { target: { id: "dateOfBirth", value: date } };

                    onChange(e);
                  }}
                />
              </Form.Group>
            </Col>
          </Form.Row>

          <Form.Row>
            <Col>
              <Form.Group controlId="password">
                <Form.Label>Password</Form.Label>

                <Form.Control
                  type="password"
                  placeholder="Password"
                  onChange={onChange}
                  value={user?.password || ""}
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group controlId="registeredDate">
                <Form.Label>Date Registered</Form.Label>

                <div style={{ display: "grid" }}>
                  <DatePicker
                    className="form-control"
                    disabled
                    selected={new Date()}
                    minDate={new Date()}
                    dateFormat="eeee, MMMM do, y"
                  />
                </div>
              </Form.Group>
            </Col>
          </Form.Row>

          <Form.Row>
            <Col>
              <Form.Group controlId="streetAddress">
                <Form.Label>Street Address</Form.Label>

                <Form.Control
                  type="text"
                  placeholder="Street Address..."
                  onChange={handleAddressChange}
                  value={user.address?.streetAddress || ""}
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group controlId="city">
                <Form.Label>City</Form.Label>

                <Form.Control
                  type="text"
                  placeholder="City..."
                  onChange={handleAddressChange}
                  value={user?.address?.city || ""}
                />
              </Form.Group>
            </Col>
          </Form.Row>

          <Form.Row>
            <Col>
              <Form.Group controlId="province">
                <Form.Label>Province</Form.Label>

                <Form.Control
                  as="select"
                  onChange={handleAddressChange}
                  value={user?.address?.province || ""}
                >
                  <option value="" disabled>
                    Select Province
                  </option>
                  {provinces.map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.text}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>

            <Col>
              <Form.Group controlId="postalCode">
                <Form.Label>Postal Code</Form.Label>

                <Form.Control
                  type="text"
                  placeholder="Postal Code..."
                  onChange={handleAddressChange}
                  value={user?.address?.postalCode || ""}
                />
              </Form.Group>
            </Col>
          </Form.Row>

          <h4 className="mt-4">Additional Information</h4>

          <Form.Row>
            <Col lg>
              <Form.Group>
                <Form.Label>Drivers License Number</Form.Label>

                <MaskedInput
                  mask={driversLicenseMask}
                  placeholder="ex. A0123-45678-90123"
                  id="number"
                  onChange={handleLicenseChange}
                  value={user?.driverLicense?.number}
                  className="form-control"
                />
              </Form.Group>
            </Col>

            <Col lg>
              <Form.Group controlId="license.expiryDate">
                <Form.Label style={{ display: "block" }}>
                  Expiry Date
                </Form.Label>
                <DatePicker
                  className="form-control"
                  showMonthDropdown
                  showYearDropdown
                  withPortal
                  dateFormat="MMMM d, yyyy"
                  selected={
                    user.driversLicense?.expiryDate
                      ? new Date(user.driversLicense?.expiryDate)
                      : null
                  }
                  placeholderText="Drivers License Expiry Date..."
                  minDate={new Date()}
                  onChange={(date) => {
                    const e = {
                      target: {
                        id: "expiryDate",
                        value: date,
                      },
                    };

                    handleLicenseChange(e);
                  }}
                />
              </Form.Group>
            </Col>
          </Form.Row>

          <Form.Row>
            <Col lg>
              <Form.Group>
                <Form.Label>Drivers License Image</Form.Label>
                <Dropzone
                  onDropAccepted={imageHandle?.onFrontImageAccepted}
                  onDropRejected={imageHandle?.handleReject}
                  maxSize={imageHandle?.maxFileSize}
                  maxFiles={1}
                  accept="image/*"
                >
                  {({ getRootProps, getInputProps, isDragActive }) => (
                    <div {...getRootProps()} className="license-upload">
                      <input {...getInputProps()} />
                      {isDragActive ? (
                        <p className="text-primary">Drop Here...</p>
                      ) : (
                        <p className="label">
                          Click or Drop Image of the License Here...
                        </p>
                      )}
                    </div>
                  )}
                </Dropzone>
              </Form.Group>
              {user.driversLicense?.imageFront && (
                <div className="thumbnail">
                  <AiFillDelete
                    className="delete-icon"
                    onClick={() => imageHandle?.onImageClear("imageFront")}
                  />

                  <img
                    src={user.driversLicense?.imageFront}
                    alt="License Thumbnail"
                  />
                </div>
              )}
            </Col>
          </Form.Row>

          {user.config?.role === "student" && (
            <>
              <h5>Set Payment Method and Package</h5>
              <Form.Row>
                <Col lg>
                  <Form.Group controlId="paymentMethod">
                    <Form.Label>Payment Method</Form.Label>

                    <Form.Control
                      type="text"
                      value={user?.config?.paymentMethod || ""}
                      placeholder="Payment Method..."
                      onChange={onConfigChange}
                    />
                  </Form.Group>
                </Col>

                <Col lg>
                  <Form.Group controlId="selectedPackage">
                    <Form.Label>Package</Form.Label>

                    <div>
                      <ButtonGroup>
                        {packages().map((packageItem) => (
                          <Button
                            key={packageItem.id}
                            variant={
                              user.config?.selectedPackage?.id ===
                              packageItem.id
                                ? "primary"
                                : "secondary"
                            }
                            onClick={() => handlePackageSelect(packageItem)}
                          >
                            {packageItem.name}
                          </Button>
                        ))}
                      </ButtonGroup>
                    </div>
                  </Form.Group>
                </Col>
              </Form.Row>

              <h5>Add Payment Record</h5>
              <Form.Row>
                <Col lg>
                  <Form.Group controlId="method">
                    <Form.Label>Payment Method</Form.Label>

                    <Form.Control
                      type="text"
                      value={paymentRecord?.method || ""}
                      placeholder="Payment Method..."
                      onChange={onRecordChange}
                    />
                  </Form.Group>

                  <Form.Group controlId="amount">
                    <Form.Label>Amount ($)</Form.Label>

                    <Form.Control
                      type="number"
                      value={paymentRecord?.amount || ""}
                      placeholder="Payment Amount..."
                      onChange={onRecordChange}
                    />
                  </Form.Group>

                  <Form.Group controlId="date">
                    <Form.Label>Date</Form.Label>

                    <Form.Control
                      type="text"
                      disabled
                      value={moment(paymentRecord.date).format(
                        "dddd, MMMM Do, YYYY"
                      )}
                    />
                  </Form.Group>

                  <Form.Group controlId="notes">
                    <Form.Label>Notes</Form.Label>

                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={paymentRecord?.notes || ""}
                      onChange={onRecordChange}
                      placeholder="Additional Notes..."
                    />
                  </Form.Group>

                  <Button
                    variant="secondary"
                    size="sm"
                    block
                    disabled={loading}
                    onClick={addPaymentRecord}
                  >
                    Add Payment Record
                  </Button>
                </Col>

                <Col lg>
                  <Form.Group controlId="selectedPackage">
                    <Form.Label>Payment Records</Form.Label>
                    <div style={{ height: "375px", overflowY: "auto" }}>
                      <ListGroup>
                        {!user.config.payments && (
                          <ListGroup.Item className="d-flex justify-content-center">
                            No Payment Records...
                          </ListGroup.Item>
                        )}
                        {user.config.payments?.length === 0 && (
                          <ListGroup.Item className="d-flex justify-content-center">
                            No Payment Records...
                          </ListGroup.Item>
                        )}
                        {user.config.payments?.map((payment) => (
                          <OverlayTrigger
                            key={payment.id}
                            trigger={["hover", "click"]}
                            placement="auto"
                            overlay={(props) => (
                              <PaymentRecordPopover
                                popoverProps={props}
                                notes={payment.notes}
                              />
                            )}
                          >
                            <ListGroup.Item className="d-flex justify-content-between">
                              <div>
                                {payment.method}: ${payment.amount}{" "}
                                <small>
                                  {moment(payment.date).format(
                                    "dddd, MMMM Do, YYYY"
                                  )}
                                </small>
                              </div>
                              <AiFillDelete
                                className="text-danger"
                                style={{
                                  fontSize: "1.5rem",
                                  cursor: "pointer",
                                  alignSelf: "center",
                                }}
                                onClick={() => removePaymentRecord(payment.id)}
                              />
                            </ListGroup.Item>
                          </OverlayTrigger>
                        ))}
                      </ListGroup>
                    </div>
                  </Form.Group>
                </Col>
              </Form.Row>
            </>
          )}

          {user.config?.role === "instructor" && (
            <>
              <h5>Set User Schedule</h5>
              <Form.Row>
                <Col>
                  <Row>
                    <Col>
                      <Form.Group controlId="day">
                        <Form.Label>Select Day</Form.Label>

                        <Form.Control
                          as="select"
                          defaultValue=""
                          onChange={onConfigChange}
                        >
                          <option disabled value="">
                            Select Day
                          </option>

                          <option value={1}>Monday</option>
                          <option value={2}>Tuesday</option>
                          <option value={3}>Wednesday</option>
                          <option value={4}>Thursday</option>
                          <option value={5}>Friday</option>
                          <option value={6}>Saturday</option>
                          <option value={0}>Sunday</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group controlId="from">
                        <Form.Label>From</Form.Label>

                        <Form.Control
                          as="select"
                          defaultValue=""
                          onChange={onConfigChange}
                        >
                          <option disabled value="">
                            Select Start Time
                          </option>

                          <option value={9}>9:00am</option>
                          <option value={10}>10:00am</option>
                          <option value={11}>11:00am</option>
                          <option value={12}>12:00pm</option>
                          <option value={13}>1:00pm</option>
                          <option value={14}>2:00pm</option>
                          <option value={15}>3:00pm</option>
                          <option value={16}>4:00pm</option>
                          <option value={17}>5:00pm</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group controlId="to">
                        <Form.Label>To</Form.Label>

                        <Form.Control
                          as="select"
                          defaultValue=""
                          onChange={onConfigChange}
                        >
                          <option disabled value="">
                            Select End Time
                          </option>

                          <option value={9}>9:00am</option>
                          <option value={10}>10:00am</option>
                          <option value={11}>11:00am</option>
                          <option value={12}>12:00pm</option>
                          <option value={13}>1:00pm</option>
                          <option value={14}>2:00pm</option>
                          <option value={15}>3:00pm</option>
                          <option value={16}>4:00pm</option>
                          <option value={17}>5:00pm</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Button block variant="secondary" onClick={onAddSchedule}>
                        Add Time
                      </Button>
                    </Col>
                  </Row>
                </Col>

                <Col>
                  <Form.Group controlId="selectedPackage">
                    <Form.Label>Schedule</Form.Label>

                    <ListGroup>
                      {!user.config?.schedule && (
                        <ListGroup.Item className="d-flex justify-content-center">
                          No Dates Set...
                        </ListGroup.Item>
                      )}
                      {user.config?.schedule?.map((item) => {
                        const d = [
                          "Sundays",
                          "Mondays",
                          "Tuesdays",
                          "Wednesdays",
                          "Thursdays",
                          "Fridays",
                          "Saturdays",
                        ];
                        const date = d[parseInt(item.day)];

                        const time = {
                          9: "9:00am",
                          10: "10:00am",
                          11: "11:00am",
                          12: "12:00pm",
                          13: "1:00pm",
                          14: "2:00pm",
                          15: "3:00pm",
                          16: "4:00pm",
                          17: "5:00pm",
                        };

                        return (
                          <ListGroup.Item
                            key={date}
                            className="d-flex justify-content-between"
                          >
                            <span>{`${date} from ${time[item.from]} to ${
                              time[item.to]
                            }`}</span>
                            <AiFillDelete
                              className="text-danger"
                              style={{ fontSize: "1.5rem", cursor: "pointer" }}
                              onClick={() => removeScheduleItem(item.day)}
                            />
                          </ListGroup.Item>
                        );
                      })}
                    </ListGroup>
                  </Form.Group>
                </Col>
              </Form.Row>
            </>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={submit} disabled={loading}>
          {loading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            modalType.submitButton
          )}
        </Button>
        <Button variant="secondary" onClick={close}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UserModal;
