import React from "react";
import UserForm from "../form";

const AddNewUser = () => {
  return (
    <div className="container my-4">
      <h1 className="text-center text-primary mb-3">Add New User</h1>

      <UserForm type="add" />
    </div>
  );
};

export default AddNewUser;
