import React, { useState } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import i18n from "i18next";

const LanguageSelector = () => {
  const [current, setCurrent] = useState("en");

  const languagesSelection = [
    { id: 0, name: "English", value: "en" },
    { id: 1, name: "Español", value: "es" },
  ];

  return (
    <div className="mb-4">
      <ButtonGroup>
        {languagesSelection.map((langauge) => (
          <Button
            key={langauge.id}
            onClick={() => {
              i18n.changeLanguage(langauge.value);
              setCurrent(langauge.value);
            }}
            variant={
              current === langauge.value ? "secondary" : "outline-secondary"
            }
          >
            {langauge.name}
          </Button>
        ))}
      </ButtonGroup>
    </div>
  );
};

export default LanguageSelector;
