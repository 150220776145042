import {
  GET_USERS,
  ADD_USER,
  EDIT_USER,
  DELETE_USER,
  SET_CURRENT_USER,
  CLEAR_CURRENT_USER,
  SET_USERS_LOADING,
  SET_ERRORS,
  CLEAR_ERRORS,
  LOGOUT,
} from "../actions/types";

const initialState = {
  users: [],
  currentUser: null,
  usersError: null,
  usersLoading: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        users: action.payload,
        usersLoading: false,
      };

    case ADD_USER:
      return {
        ...state,
        users: [...state.users, action.payload],
        usersLoading: false,
      };

    case EDIT_USER:
      return {
        ...state,
        users: state.users.map((user) =>
          user._id === action.payload._id ? action.payload : user
        ),
        usersLoading: false,
      };

    case DELETE_USER:
      return {
        ...state,
        users: state.users.filter((user) => user._id === action.payload),
        usersLoading: false,
      };

    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload,
      };

    case CLEAR_CURRENT_USER:
      return {
        ...state,
        currentUser: null,
      };

    case SET_USERS_LOADING:
      return {
        ...state,
        usersLoading: true,
      };

    case SET_ERRORS:
      return {
        ...state,
        usersError: action.payload,
        usersLoading: false,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        usersError: null,
      };

    case LOGOUT:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default userReducer;
