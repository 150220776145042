import React from "react";
import { Modal, Button, Popover, OverlayTrigger } from "react-bootstrap";
import { AiFillEdit, AiFillPlusCircle } from "react-icons/ai";

import AppointmentForm from "./AppointmentForm";

const AppointmentModal = ({
  type,
  show,
  handleClick,
  handleCancel,
  handleClose,
  formProps,
}) => {
  const confirmPopover = (
    <Popover>
      <Popover.Title as="h3">Cancel Appointment</Popover.Title>
      <Popover.Content>
        <p>Are you sure you want to cancel this appointment?</p>
        <Button variant="danger" size="sm" onClick={handleCancel}>
          Cancel Appointment
        </Button>
      </Popover.Content>
    </Popover>
  );

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          {type === "add" && (
            <>
              <AiFillPlusCircle className="text-secondary mb-1" /> Book New
              Appointment
            </>
          )}
          {type === "edit" && (
            <>
              <AiFillEdit className="text-secondary mb-1" /> Edit Appointment
            </>
          )}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <AppointmentForm {...formProps} />
      </Modal.Body>

      <Modal.Footer className={type === "edit" && "justify-content-between"}>
        {type === "edit" && (
          <div>
            <OverlayTrigger
              trigger="click"
              placement="auto"
              overlay={confirmPopover}
              rootClose
            >
              <Button variant="danger">Cancel Appointment</Button>
            </OverlayTrigger>
          </div>
        )}
        <div className="d-flex">
          <Button variant="primary" onClick={handleClick} className="mr-2">
            {type === "add" && "Add Appointment"}
            {type === "edit" && "Edit Appointment"}
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default AppointmentModal;
