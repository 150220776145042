import React, { useEffect } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { useFieldArray, useFormContext } from "react-hook-form";
import { AiFillDelete } from "react-icons/ai";

import SessionInfo from "./SessionInfo";
import SignatureArea from "./SignatureArea";
import Button from "../../layout/ButtonWithIcon";

const SessionItems = () => {
  const { control } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "sessions",
  });

  useEffect(() => {
    fields.length === 0 && append();
  }, []);

  return (
    <div>
      <Button
        variant="secondary"
        size="sm"
        onClick={() => append()}
        className="mb-3"
        disabled={fields.length >= 10}
        text={fields.length === 0 ? "Add Session" : "Add Another Session"}
      />
      <Tabs>
        {fields.map((field, i) => (
          <Tab
            key={field.id}
            title={
              <div className="d-flex">
                <span className="mr-2">{`Session ${i + 1}`} </span>
                <Button
                  icon={AiFillDelete}
                  variant="outline-danger"
                  size="sm"
                  onClick={() => remove(i)}
                />
              </div>
            }
            eventKey={`session${i}`}
            className="mt-2"
          >
            <SessionInfo index={i} />
            <SignatureArea index={i} />
          </Tab>
        ))}
      </Tabs>
    </div>
  );
};

export default SessionItems;
