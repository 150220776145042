import React, { useMemo } from "react";
import moment from "moment";
import Select from "react-select";
import Pagination from "react-bootstrap/Pagination";
import Form from "react-bootstrap/Form";
import {
  useTable,
  usePagination,
  useFilters,
  useGlobalFilter,
  useSortBy,
} from "react-table";
import { matchSorter } from "match-sorter";

import AdditionalFiltering from "./AdditionalFiltering";
import Table from "./Table";

const UserList = ({ users, loading, onEdit }) => {
  const columns = useMemo(
    () => [
      { Header: "Username", accessor: "username" },
      { Header: "Full Name", accessor: "fullName" },
      {
        Header: "Type",
        accessor: "type",
        Filter: UserTypeColumnFilter,
        filter: "includes",
      },
      {
        Header: "Status",
        accessor: "status",
        Filter: StatusColumnFilter,
        filter: "includes",
      },
      {
        id: "isCertified",
        Header: "Certified",
        accessor: ({ isCertified }) => isCertified.toString(),
        Filter: CertifiedColumnFilter,
        filter: "equals",
      },
      {
        Header: "Registrered",
        accessor: "registrationDate",
        sortType: (a, b) => moment(a.registrationDate).isAfter(moment(b)),
      },
      { Header: "Actions", accessor: "userID" },
      {
        Header: "Assigned Instructor",
        accessor: "assignedInstructor",
        filter: "includes",
        show: false,
      },
      {
        Header: "Package",
        accessor: "packageID",
        filter: "equals",
        show: false,
      },
      {
        Header: "Payment Type",
        accessor: "paymentType",
        filter: "equals",
        show: false,
      },
      {
        id: "isPrivate",
        Header: "Is Private",
        accessor: ({ isPrivate }) => isPrivate.toString(),
        filter: "equals",
        show: false,
      },
      {
        Header: "Address",
        accessor: "address",
        filter: "includes",
        show: false,
      },
      {
        Header: "Phone Number",
        accessor: "phoneNumber",
        filter: "includes",
        show: false,
      },
    ],
    []
  );

  const data = useMemo(
    () =>
      users.map((user) => {
        const {
          _id,
          firstName,
          lastName,
          config,
          registrationDate,
          address,
          phoneNumber,
        } = user;

        return {
          ...user,
          userID: _id,
          fullName: `${firstName} ${lastName}`,
          type: (() => {
            const roleMap = {
              student: "Student",
              instructor: "Instructor",
              admin: "Admin",
            }[config?.role];
            return roleMap;
          })(),
          registrationDate: moment(registrationDate).format(
            "dddd, MMMM Do, YYYY"
          ),
          address: JSON.stringify(address),
          phoneNumber,
          assignedInstructor: user.config?.associatedInstructor || "",
          packageID: user.config?.selectedPackage?.id || -1,
          paymentType: user.config?.paymentMethod,
          isPrivate: user.config?.isPrivate,
        };
      }),
    [users]
  );

  function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
  }

  fuzzyTextFilterFn.autoRemove = (val) => !val;

  const filterTypes = useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  function DefaultColumnFilter({ column: { filterValue, setFilter, Header } }) {
    return (
      <Form.Control
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
        placeholder="Search..."
      />
    );
  }

  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setFilter,
    state,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      autoResetPage: false,
      initialState: {
        pageIndex: 0,
        sortBy: [{ id: "registrationDate", desc: true }],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { pageIndex } = state;

  function CertifiedColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) {
    const options = useMemo(() => {
      const options = new Set();
      preFilteredRows.forEach((row) => {
        options.add(row.values[id]);
      });
      return [...options.values()];
    }, [id, preFilteredRows]);

    const current = {
      value: filterValue,
    };

    switch (filterValue) {
      case "true": {
        current.label = "Yes";
        break;
      }
      case "false": {
        current.label = "No";
        break;
      }
      case undefined: {
        current.label = "All";
        break;
      }

      default: {
        current.label = "All";
        break;
      }
    }

    return (
      <div style={{ width: "115px" }}>
        <Select
          value={current}
          onChange={(option) => {
            if (!option) {
              setFilter(undefined);
              return;
            }
            const { value } = option;
            setFilter(value);
          }}
          options={[
            { label: "All", value: null },
            ...options.map((option) => ({
              label: option === "true" ? "Yes" : "No",
              value: option,
            })),
          ]}
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          isClearable
        />
      </div>
    );
  }

  function StatusColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) {
    const options = useMemo(() => {
      const options = new Set();
      preFilteredRows.forEach((row) => {
        options.add(row.values[id]);
      });
      return [...options.values()];
    }, [id, preFilteredRows]);

    return (
      <div style={{ width: "150px" }}>
        <Select
          value={{ label: filterValue || "All", value: filterValue }}
          onChange={(option) => {
            if (!option) {
              setFilter(undefined);
              return;
            }
            const { value } = option;
            setFilter(value);
          }}
          options={[
            { label: "All", value: "" },
            ...options.map((option) => ({ label: option, value: option })),
          ]}
          isClearable
        />
      </div>
    );
  }

  function UserTypeColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) {
    const options = useMemo(() => {
      const options = new Set();
      preFilteredRows.forEach((row) => {
        options.add(row.values[id]);
      });
      return [...options.values()];
    }, [id, preFilteredRows]);

    return (
      <div style={{ width: "150px" }}>
        <Select
          value={{ label: filterValue || "All", value: filterValue }}
          onChange={(option) => {
            if (!option) {
              setFilter(undefined);
              return;
            }
            const { value } = option;
            setFilter(value);
          }}
          options={[
            { label: "All", value: "" },
            ...options.map((option) => ({ label: option, value: option })),
          ]}
          isClearable
        />
      </div>
    );
  }

  return (
    <>
      <AdditionalFiltering users={users} onFilter={setFilter} />

      <Table
        tableProps={getTableProps()}
        bodyProps={getTableBodyProps()}
        headers={headerGroups}
        page={page}
        onPrepareRow={prepareRow}
        onEdit={onEdit}
        loading={loading}
      />

      <div className="d-flex justify-content-center">
        <Pagination>
          <Pagination.First
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          />
          <Pagination.Prev
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          />
          {pageOptions.map((index) => (
            <Pagination.Item
              key={index}
              active={index === pageIndex}
              onClick={() => gotoPage(index)}
              className="user-list-pagination"
            >
              {index + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next onClick={() => nextPage()} disabled={!canNextPage} />
          <Pagination.Last
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          />
        </Pagination>
      </div>
    </>
  );
};

export default UserList;
