import React from "react";

const StepWrapper = ({ title, component, order }) => {
  return (
    <div className="booking-step-wrapper">
      <h3 className="mb-4">
        <u>{`Step ${order}: ${title}`}</u>
      </h3>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ width: "100%" }}>{component}</div>
      </div>
    </div>
  );
};

export default StepWrapper;
