import React from "react";

import SessionLogForm from "../form";

const AddNewSessionLog = () => {
  return (
    <div className="container my-4">
      <h1 className="text-center text-primary mb-3">Add New Session Log</h1>
      <SessionLogForm type="add" />
    </div>
  );
};

export default AddNewSessionLog;
