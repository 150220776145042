import React from "react";

import ResetForm from "./ResetForm";

const ResetPassword = ({ match }) => {
  const token = match.params.token;
  return (
    <div className="container mx-auto mt-4 mb-4">
      <h1 className="text-center text-secondary mb-3">Reset Password</h1>
      <ResetForm token={token} />
    </div>
  );
};

export default ResetPassword;
