import React from "react";
import moment from "moment";
import {
  AiFillClockCircle,
  AiOutlineUser,
  AiFillCalendar,
  AiOutlineBook,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";

const ResultCard = ({
  totalHours,
  privateHours,
  nonPrivateHours,
  instructor,
  startDate,
  endDate,
  appointments,
  loading,
}) => {
  if (loading)
    return (
      <div className="payroll-card d-flex bg-info justify-content-center align-items-center">
        <Spinner animation="border" style={{ width: "4rem", height: "4rem" }} />
      </div>
    );

  if (!instructor || !startDate || !endDate)
    return (
      <div className="payroll-card bg-info d-flex justify-content-center align-items-center">
        <h4 className="m-0 font-weight-light text-center">
          * Select an Instructror and Date Range *
        </h4>
      </div>
    );

  return (
    <>
      <h1 className="mb-4 title">Payroll Summary</h1>
      <div className="payroll-card bg-info">
        <div className="instructor-label font-weight-light">
          Instructor Payroll Summary
        </div>
        <h2 className="amount">
          <AiFillClockCircle /> {totalHours}
        </h2>
        <h5 className="hour-stats">
          <AiOutlineBook />{" "}
          {`${privateHours} private hour(s) and ${nonPrivateHours} regular hour(s)`}
        </h5>
        <h5 className="number-of-sessions">
          <AiOutlineBook /> {appointments}
        </h5>
        <h5 className="instructor-name">
          <AiOutlineUser />{" "}
          <Link
            to={`/users/${instructor?._id}`}
            className="text-light"
          >{`${instructor?.firstName} ${instructor?.lastName}`}</Link>
        </h5>
        <h5 className="date-range p-0 m-0">
          <AiFillCalendar /> From{" "}
          {moment(startDate).format("dddd, MMMM Do, YYYY")} to{" "}
          {moment(endDate).format("dddd, MMMM Do, YYYY")}
        </h5>
      </div>
    </>
  );
};

export default ResultCard;
