import React from "react";
import Select from "react-select";
import { Form, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Controller, useFormContext } from "react-hook-form";

const MainInformation = () => {
  const {
    formState: { errors },
  } = useFormContext();
  const { students, instructors } = useSelector(
    ({ sessionLogs }) => sessionLogs.config
  );

  const { profile } = useSelector(({ auth }) => auth);

  const role = profile?.config?.role;

  const userMap = ({ _id, firstName, lastName }) => ({
    value: _id,
    label: `${firstName} ${lastName}`,
  });

  const studentMap = students?.map(userMap);
  const instructorMap = instructors?.map(userMap);

  return (
    <>
      <Form.Row>
        <Col lg>
          <Form.Group>
            <Form.Label>Select Student</Form.Label>

            <Controller
              name="student"
              render={({ field: { onChange, value } }) => (
                <Select
                  options={studentMap}
                  onChange={({ value }) => onChange(value)}
                  value={studentMap.find((student) => student.value === value)}
                />
              )}
            />
            {errors.student && (
              <p className="text-danger">* {errors.student?.message}</p>
            )}
          </Form.Group>
        </Col>

        {role === "admin" && (
          <Col lg>
            <Form.Group>
              <Form.Label>Select Instructor</Form.Label>

              <Controller
                name="instructor"
                render={({ field: { onChange, value } }) => (
                  <Select
                    options={instructorMap}
                    onChange={({ value }) => onChange(value)}
                    value={instructorMap.find(
                      (instructor) => instructor.value === value
                    )}
                  />
                )}
              />
            </Form.Group>
          </Col>
        )}
      </Form.Row>
    </>
  );
};

export default MainInformation;
