import React from "react";
import { useSelector } from "react-redux";
import { Form, Col } from "react-bootstrap";

import { provinces } from "../../../../utils/provinces";
import AutoComplete from "../../../layout/AutoComplete";

const PickupLocation = ({
  onChange,
  onAddressChange,
  onOwnAddressSelect,
  onAddressAutoFill,
  address,
  notes,
}) => {
  const profile = useSelector(({ auth }) => auth.profile);

  const onUseProfileAddress = (e) => {
    const selected = e.target.checked;
    const currentAddress = profile.address;

    selected ? onOwnAddressSelect(currentAddress) : onOwnAddressSelect(false);
  };

  return (
    <>
      <Form.Group controlId="currentAddress">
        <Form.Check
          type="checkbox"
          label="Use my current address"
          onChange={onUseProfileAddress}
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Find Address</Form.Label>
        <AutoComplete
          onChange={onAddressAutoFill}
          placeholder="Type in Your Pickup Address Here..."
        />
      </Form.Group>

      <Form.Row>
        <Col lg>
          <Form.Group controlId="streetAddress">
            <Form.Label>Current Address</Form.Label>
            <Form.Control
              type="text"
              placeholder="Your address"
              value={address.streetAddress}
              onChange={onAddressChange}
              required
            />
          </Form.Group>
        </Col>

        <Col lg>
          <Form.Group controlId="city">
            <Form.Label>City</Form.Label>
            <Form.Control
              type="text"
              placeholder="Your city"
              value={address.city}
              onChange={onAddressChange}
              required
            />
          </Form.Group>
        </Col>
      </Form.Row>

      <Form.Row>
        <Col lg>
          <Form.Group controlId="province">
            <Form.Label>Province</Form.Label>
            <Form.Control
              as="select"
              onChange={onAddressChange}
              required
              value={address.province}
            >
              <option disabled hidden>
                Select Province
              </option>
              {provinces.map((province) => (
                <option key={province.id} value={province.text}>
                  {province.text}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>

        <Col lg>
          <Form.Group controlId="postalCode">
            <Form.Label>Postal Code</Form.Label>
            <Form.Control
              type="text"
              placeholder="Your postal code"
              value={address.postalCode}
              onChange={onAddressChange}
              required
            />
          </Form.Group>
        </Col>
      </Form.Row>

      <Form.Group controlId="publicNotes">
        <Form.Label>Additional Notes</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          placeholder="Any additional notes"
          value={notes}
          onChange={onChange}
        />
      </Form.Group>
    </>
  );
};

export default PickupLocation;
