export const provinces = [
  { id: 0, value: "Alberta", text: "Alberta" },
  { id: 1, value: "British Columbia", text: "British Columbia" },
  { id: 2, value: "Manitoba", text: "Manitoba" },
  { id: 3, value: "New Brunswick", text: "New Brunswick" },
  {
    id: 4,
    value: "Newfoundland and Labrador",
    text: "Newfoundland and Labrador",
  },
  { id: 5, value: "Nova Scotia", text: "Nova Scotia" },
  { id: 6, value: "Ontario", text: "Ontario" },
  { id: 7, value: "Prince Edward Island", text: "Prince Edward Island" },
  { id: 8, value: "Quebec", text: "Quebec" },
  { id: 9, value: "Saskatchewan", text: "Saskatchewan" },
];
