import React, { useState, useEffect } from "react";
import Select from "react-select";
import Form from "react-bootstrap/Form";

import { getPackages, packages } from "../../../utils/register";
import axios from "axios";

const AdditionalFiltering = ({ users, onFilter }) => {
  const [packageOptions, setPackageOptions] = useState([
    {
      label: "Old Packages",
      options: packages().map((packageItem) => ({
        label: packageItem.name,
        value: packageItem.id,
      })),
    },
  ]);

  useEffect(() => {
    getPackages().then((data) =>
      setPackageOptions((prev) => [
        {
          label: "New Packages",
          options: data.map((item) => ({
            label: item?.title?.rendered,
            value: item.id,
          })),
        },
        ...prev,
      ])
    );
  }, []);

  const fetchPackages = async () => {
    try {
      return await getPackages();
    } catch (error) {
      return [];
    }
  };
  return (
    <div className="user-table-filter">
      <h5 className="text-secondary mb-3">Additional Filtering</h5>

      <div className="user-table-filter-options">
        <Select
          placeholder="Assigned Instructor..."
          onChange={(option) => {
            if (!option) {
              onFilter("assignedInstructor", undefined);
              return;
            }
            const { value } = option;
            onFilter("assignedInstructor", value);
          }}
          options={users
            .filter((user) => user.config?.role === "instructor")
            .map((user) => ({
              label: `${user.firstName} ${user.lastName}`,
              value: user._id,
            }))}
          isClearable
        />
        <Select
          placeholder="Student Type..."
          onChange={(option) => {
            if (!option) {
              onFilter("isPrivate", undefined);
              return;
            }
            const { value } = option;
            onFilter("isPrivate", value);
          }}
          options={[
            { label: "Regular", value: "false" },
            { label: "Private", value: "true" },
          ]}
          isClearable
        />
        <Select
          placeholder="Package..."
          onChange={(option) => {
            if (!option) {
              onFilter("packageID", undefined);
              return;
            }
            const { value } = option;
            onFilter("packageID", value);
          }}
          options={packageOptions}
          isClearable
        />
        <Select
          placeholder="Payment Type..."
          onChange={(option) => {
            if (!option) {
              onFilter("paymentType", undefined);
              return;
            }
            const { value } = option;
            onFilter("paymentType", value);
          }}
          options={[
            { label: "Debit/Credit Card", value: "Debit/Credit Card" },
            { label: "E-Transfer", value: "E-Transfer" },
            { label: "Cash", value: "Cash" },
          ]}
          isClearable
        />

        <Form.Control
          placeholder="Search by Phone Number"
          onChange={(e) => {
            const value = e.target.value;

            if (!value) {
              onFilter("phoneNumber", undefined);
              return;
            }

            onFilter("phoneNumber", value);
          }}
        />

        <Form.Control
          placeholder="Search by Address"
          onChange={(e) => {
            const value = e.target.value;

            if (!value) {
              onFilter("address", undefined);
              return;
            }

            onFilter("address", value);
          }}
        />
      </div>
    </div>
  );
};

export default AdditionalFiltering;
