export const EventColors = {
  Approved: {
    backgroundColor: "#28a745",
    textColor: "#fff",
  },
  Pending: {
    backgroundColor: "#ffc107",
    textColor: "#343a40",
  },
  Cancelled: {
    backgroundColor: "#dc3545",
    textColor: "#fff",
  },
};
