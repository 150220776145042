import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getUserDetails } from "../actions/authActions";

import setToken from "../utils/setToken";

const Authentication = ({ children }) => {
  const dispatch = useDispatch();
  const { isAuthenticated, authLoading } = useSelector((state) => state.auth);

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      setToken(token);
      dispatch(getUserDetails());
    }
  }, []);

  return <>{children}</>;
};

export default Authentication;
