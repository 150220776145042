import React from "react";
import Spinner from "react-bootstrap/Spinner";

const Loading = () => {
  return (
    <div className="table-loading-area">
      <Spinner animation="border" size="lg" variant="secondary" />
    </div>
  );
};

export default Loading;
