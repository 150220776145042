import React from "react";
import { Form, Col } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import { DatePicker } from "@material-ui/pickers";
import moment from "moment";

import FileUploadArea from "../../../layout/FileUploadArea";
import { getBase64 } from "../../../../utils/base64";
import { AiFillDelete } from "react-icons/ai";
import Button from "../../../layout/ButtonWithIcon";

const DriversLicense = () => {
  const { setValue, getValues } = useFormContext();

  const handleUpload = (file) => {
    getBase64(file[0], (error, base64String) => {
      if (error) return;
      setValue("driversLicense.imageFront", base64String, {
        shouldValidate: true,
      });
    });
  };

  const imgURL = getValues("driversLicense.imageFront");

  return (
    <>
      <Form.Row>
        <Col lg>
          <Form.Group>
            <Form.Label>Drivers License Number</Form.Label>

            <Controller
              name="driversLicense.number"
              render={({ field }) => (
                <Form.Control
                  placeholder="Drivers License Number..."
                  {...field}
                />
              )}
            />
          </Form.Group>
        </Col>
        <Col lg>
          <Form.Group>
            <Form.Label>Expiry Date</Form.Label>

            <Controller
              name="driversLicense.expiryDate"
              render={({ field: { value, onChange } }) => (
                <DatePicker
                  onChange={onChange}
                  TextFieldComponent={({ onClick }) => {
                    return (
                      <Form.Control
                        onChange={() => {}}
                        onClick={onClick}
                        value={value && moment(value).format("dddd, MMMM Do")}
                        placeholder="Expiry Date..."
                      />
                    );
                  }}
                  value={value}
                />
              )}
            />
          </Form.Group>
        </Col>
      </Form.Row>

      <Form.Row>
        <Col lg>
          <Form.Group>
            <Form.Label>Drivers License Image</Form.Label>

            <FileUploadArea
              text="Drop License Image Here, or Click to Select Image"
              options={{
                accept: "image/*",
                onDropAccepted: handleUpload,
              }}
            />

            {imgURL && (
              <div className="mt-3">
                <Button
                  text="Remove Image"
                  icon={AiFillDelete}
                  variant="outline-danger"
                  size="sm"
                  className="mb-2"
                  onClick={() =>
                    setValue("driversLicense.imageFront", "", {
                      shouldValidate: true,
                    })
                  }
                />
                <img src={imgURL} style={{ width: "200px" }} alt="" />
              </div>
            )}
          </Form.Group>
        </Col>
      </Form.Row>
    </>
  );
};

export default DriversLicense;
