import axios from "axios";
import {
  LOGIN,
  LOGOUT,
  REGISTER,
  GET_PROFILE,
  EDIT_PROFILE,
  ADD_TIME_OFF,
  DELETE_TIME_OFF,
  SET_AUTH_LOADING,
  ADD_GOOGLE_AUTH,
  REMOVE_GOOGLE_AUTH,
  SET_ERRORS,
  CLEAR_ERRORS,
} from "../actions/types";

const initialState = {
  token: null,
  isAuthenticated: false,
  authLoading: false,
  authErrors: null,
  message: null,
  profile: {},
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      localStorage.setItem("token", action.payload.token);

      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        authLoading: false,
        authErrors: null,
      };

    case LOGOUT:
      localStorage.removeItem("token");
      delete axios.defaults.headers.common["x-authentication-token"];

      return {
        ...initialState,
        message: "Logged Out Successfully!",
      };

    case REGISTER:
      // localStorage.setItem("token", action.payload.token);

      return {
        ...state,
        // ...action.payload,
        // isAuthenticated: true,
        authLoading: false,
      };

    case GET_PROFILE:
      return {
        ...state,
        isAuthenticated: true,
        authLoading: false,
        profile: action.payload.profile,
      };

    case EDIT_PROFILE:
      return {
        ...state,
        ...action.payload,
        authLoading: false,
      };

    case ADD_TIME_OFF:
      return {
        ...state,
        profile: {
          ...state.profile,
          config: {
            ...state.profile?.config,
            timeOff: [...action.payload, ...state.profile?.config?.timeOff],
          },
        },
        authLoading: false,
      };

    case DELETE_TIME_OFF:
      return {
        ...state,
        profile: {
          ...state.profile,
          config: {
            ...state.profile?.config,
            timeOff: state.profile?.config?.timeOff?.filter((item) => {
              console.log(item, action.payload);
              return item.id !== action.payload;
            }),
          },
        },
        authLoading: false,
      };

    case ADD_GOOGLE_AUTH:
      return {
        ...state,
        profile: {
          ...state.profile,
          isConnectedToGoogle: true,
        },
        authLoading: false,
      };

    case REMOVE_GOOGLE_AUTH:
      return {
        ...state,
        profile: {
          ...state.profile,
          isConnectedToGoogle: false,
        },
        authLoading: false,
      };

    case SET_AUTH_LOADING:
      return {
        ...state,
        authLoading: true,
      };

    case SET_ERRORS:
      return {
        ...state,
        authLoading: false,
        authErrors: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        authErrors: null,
      };

    default:
      return state;
  }
};

export default authReducer;
