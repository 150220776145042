import React from "react";
import { useFormContext } from "react-hook-form";
import { AiFillCheckCircle } from "react-icons/ai";
import { useTranslation } from "react-i18next";

const Confirmation = () => {
  const { getValues } = useFormContext();
  const { t } = useTranslation(null, {
    keyPrefix: "register.steps.confirmation",
  });

  const { selectedPackage, firstName, lastName, email, address } = getValues();

  const paymentURL = {
    FULL_AMOUNT: {
      "Basic Package": "https://link.waveapps.com/jxx5zc-rnmyek",
      "Preferred Package": "https://link.waveapps.com/6x4vnd-y7egg5",
    },
    PARTIAL_AMOUNT: {
      "Basic Package": "https://link.waveapps.com/jxd4x3-czd32x",
      "Preferred Package": "https://link.waveapps.com/6afh9b-k2934w",
    },
  };

  const URL = ({ paymentAmount, packageName }) => `
      ${
        paymentURL[paymentAmount][packageName]
      }?first_name=${firstName}&last_name=${lastName}&email=${email}&address=${address?.streetAddress?.replace(
    / /g,
    "+"
  )}&city=${address?.city}&region=${address?.province}&postal=${
    address?.postalCode
  }&country=CA`;

  return (
    <>
      <h3 className="mb-3 text-secondary">{t("title")}</h3>

      <span
        style={{ fontSize: "7rem" }}
        className="d-flex justify-content-center text-success mb-3"
      >
        <AiFillCheckCircle />
      </span>

      <h4 style={{ lineHeight: "1.5" }} className="mb-4">
        {t("info1")} <a href="tel:905-517-0798">905-517-0798</a>.
      </h4>

      <h4 className="mb-5">{t("info2")}</h4>

      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          gap: "1rem",
          flexWrap: "wrap",
        }}
      >
        <a
          href={URL({
            paymentAmount: "FULL_AMOUNT",
            packageName: selectedPackage?.name,
          })}
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn-outline-secondary"
        >
          {t("fullPaymentButton")}
        </a>{" "}
        <a
          href={URL({
            paymentAmount: "PARTIAL_AMOUNT",
            packageName: selectedPackage?.name,
          })}
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn-outline-secondary"
        >
          {t("partialPaymentButton")}
        </a>
      </div>
    </>
  );
};

export default Confirmation;
