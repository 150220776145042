import React, { Suspense } from "react";

import "./sass/App.scss";
import "react-datepicker/dist/react-datepicker.css";

import Navbar from "./components/layout/Header";
import Footer from "./components/layout/Footer";

import { BrowserRouter as Router } from "react-router-dom";
import Authentication from "./routing/Authentication";
import Routes from "./routing/Routes";

import { Provider } from "react-redux";

import store from "./Store";

const App = () => {
  return (
    <Suspense fallback="loading">
      <Provider store={store}>
        <Router>
          <Authentication>
            <Navbar />

            <Routes />

            <Footer />
          </Authentication>
        </Router>
      </Provider>
    </Suspense>
  );
};

export default App;
