import React from "react";
import { Badge } from "react-bootstrap";
import { AiOutlineUser } from "react-icons/ai";
import { GrCircleInformation } from "react-icons/gr";
import { GiBookmarklet } from "react-icons/gi";
import { BsFillCalendarDateFill } from "react-icons/bs";
import moment from "moment";

const SessionLogItemDetails = ({ session }) => {
  const isCompleted =
    session.sessions.length === 10 &&
    session.sessions.every((item) => item.status === "completed");

  const { student, instructor, timestamp } = session;

  return (
    <>
      <div className="session-content mb-3">
        <div className="item">
          <div className="item">
            <BsFillCalendarDateFill />
            {moment(timestamp).format("dddd, MMMM Do, YYYY")}
          </div>

          <div style={{ marginLeft: "auto" }}>
            <div>
              <strong>{`${student.firstName} ${student.lastName}`} </strong>
            </div>

            {student.driversLicense?.number && (
              <div>
                <strong>({student.driversLicense?.number})</strong>
              </div>
            )}
          </div>
        </div>

        <div className="item">
          <GrCircleInformation />{" "}
          {isCompleted ? (
            <Badge variant="success">Completed</Badge>
          ) : (
            <Badge variant="warning">Not Completed</Badge>
          )}
        </div>

        <div className="item">
          <GiBookmarklet /> {`${session?.finalMark}/100`}{" "}
          {isCompleted ? "(Final)" : "(Not Final)"}
        </div>

        <div className="item">
          <AiOutlineUser />
          <span>
            Instructor{" "}
            <strong>
              {`${instructor.firstName} ${instructor.lastName}`}{" "}
              {instructor.driversLicense?.number && (
                <>({instructor.driversLicense?.number})</>
              )}
            </strong>
          </span>
        </div>
      </div>
    </>
  );
};

export default SessionLogItemDetails;
