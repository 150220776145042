import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Button, Spinner } from "react-bootstrap";
import { HiUser } from "react-icons/hi";

import { editProfile } from "../../actions/authActions";
import EditForm from "./ProfileEditForm";

const ProfileEditModal = ({ show, handleClose }) => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.auth.profile);
  const { authLoading } = useSelector((state) => state.auth);

  const [profileData, setProfileData] = useState(profile);

  const handleAutoComplete = async (address) => {
    setProfileData((prevState) => ({
      ...prevState,
      address,
    }));
  };

  const onSubmit = () => {
    dispatch(editProfile(profileData));

    handleClose();
  };

  const handleChange = (e) => {
    const id = e.target.id;
    const value = e.target.value;

    setProfileData((prevState) => ({ ...prevState, [id]: value }));
  };

  const handleAddressChange = (e) => {
    const id = e.target.id;
    const value = e.target.value;

    setProfileData((prevState) => ({
      ...prevState,
      address: { ...prevState.address, [id]: value },
    }));
  };

  return (
    <Modal show={show} onHide={handleClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title className="text-secondary">
          <HiUser /> Edit Profile
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-secondary">
        <EditForm
          profileData={profileData}
          handleChange={handleChange}
          handleAddressChange={handleAddressChange}
          handleAutoComplete={handleAutoComplete}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" disabled={authLoading} onClick={onSubmit}>
          {authLoading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            "Edit"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProfileEditModal;
