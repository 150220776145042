import {
  GET_SESSION_LOGS,
  GET_SESSION_TYPES,
  ADD_SESSION_LOG,
  EDIT_SESSION_LOG,
  DELETE_SESSION_LOG,
  SORT_SESSION_LOGS,
  FILTER_SESSION_LOGS,
  SET_CURRENT_SESSION_LOG,
  CLEAR_CURRENT_SESSION_LOG,
  SET_SESSION_LOGS,
  CLEAR_SESSION_LOGS,
  SET_SESSION_LOGS_LOADING,
  // SET_ERRORS,
  // CLEAR_ERRORS,
} from "./types";

import axios from "axios";

import * as api from "../api";
import { handleError, toastify } from "../utils/actionsHandler";

export const getSessionLogs = (role) => async (dispatch) => {
  dispatch({ type: SET_SESSION_LOGS_LOADING });

  try {
    const res = await api.getSessionLogs(role);

    dispatch({
      type: GET_SESSION_LOGS,
      payload: {
        sessionLogs: res.data.sessionLogs,
        students: res.data.students,
        instructors: res.data.instructors,
      },
    });
  } catch (error) {
    console.error(error);
    handleError(error, dispatch);
  }
};

export const getSessionTypes = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/sessionlogs/types");

    if (res.data.success) {
      dispatch({ type: GET_SESSION_TYPES, payload: res.data.types });
    }
  } catch (error) {
    console.error(error);
    handleError(error, dispatch);
  }
};

export const addSessionLog =
  ({ data }) =>
  async (dispatch, state) => {
    const {
      auth: { profile },
    } = state();

    dispatch({ type: SET_SESSION_LOGS_LOADING });

    try {
      const res = await api.addSessionLog(data, profile?.config?.role);

      dispatch({ type: ADD_SESSION_LOG, payload: res.data.sessionLog });

      toastify(res.data.message, "success", 4000);
    } catch (error) {
      console.error(error);
      handleError(error, dispatch);
    }
  };

export const editSessionLog =
  ({ id, data }) =>
  async (dispatch, state) => {
    const {
      auth: { profile },
    } = state();

    dispatch({ type: SET_SESSION_LOGS_LOADING });

    try {
      const res = await api.editSessionLog(id, data, profile?.config?.role);

      dispatch({ type: EDIT_SESSION_LOG, payload: res.data.sessionLog });

      toastify(res.data.message, "success", 4000);
    } catch (error) {
      console.error(error);
      handleError(error, dispatch);
    }
  };

export const deleteSessionLog = (sessionLogID) => async (dispatch, state) => {
  const {
    auth: { profile },
  } = state();

  dispatch({ type: SET_SESSION_LOGS_LOADING });
  try {
    const res = await api.deleteSessionLog(sessionLogID, profile?.config?.role);

    dispatch({ type: DELETE_SESSION_LOG, payload: res.data.sessionLog });

    toastify(res.data.message, "success", 4000);
  } catch (error) {
    console.error(error);
    handleError(error, dispatch);
  }
};

export const sortSessionLogs = (value) => (dispatch) =>
  dispatch({ type: SORT_SESSION_LOGS, payload: value });

export const filterSessionLogs = (value) => (dispatch) =>
  dispatch({ type: FILTER_SESSION_LOGS, payload: value });

export const setSessionLogs = (sessionLogs) => (dispatch) =>
  dispatch({ type: SET_SESSION_LOGS, payload: sessionLogs });

export const clearSessionLogs = (sessionLogs) => (dispatch) =>
  dispatch({ type: CLEAR_SESSION_LOGS });

export const setCurrentSessionLog = (sessionLog) => (dispatch) =>
  dispatch({ type: SET_CURRENT_SESSION_LOG, payload: sessionLog });

export const clearCurrentSessionLog = () => (dispatch) =>
  dispatch({ type: CLEAR_CURRENT_SESSION_LOG });
