import axios from "axios";

export const URL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_DEV_API_LINK
    : process.env.REACT_APP_API_LINK;

/** FOR AUTH ACTIONS */
export const login = async (username, password) =>
  await axios.post(`${URL}/authenticate/login`, { username, password });

export const register = async (formData) =>
  await axios.post(`${URL}/authenticate/register`, formData);
export const getProfile = async () => await axios.get(`${URL}/authenticate/`);

export const editProfile = async (formData) =>
  await axios.put(`${URL}/authenticate/`, formData);

export const checkUsername = async (username) =>
  await axios.post(`${URL}/authenticate/register/checkusername`, { username });

export const checkEmail = async (email) =>
  await axios.post(`${URL}/authenticate/register/checkemail`, { email });

export const addTimeoff = async (timeoff) =>
  await axios.post(`${URL}/authenticate/timeoff/`, timeoff);

export const deleteTimeoff = async (id) =>
  await axios.delete(`${URL}/authenticate/timeoff/${id}`);

export const registerGoogleAuth = async (data) =>
  await axios.post(`${URL}/authenticate/addgoogleauth`, { data });

export const removeGoogleAuth = async () =>
  await axios.delete(`${URL}/authenticate/removegoogleauth`);
/**----------------- */

/** FOR SCHEDULE ACTIONS */
export const getAppointmentSettings = async () =>
  await axios.get(`${URL}/appointments/settings`);

export const getInstructorAvailability = async (params) =>
  await axios.get(`${URL}/appointments/instructor-availability`, { params });

export const getAppointments = async (role, instructors) =>
  await axios.get(`${URL}/appointments/${role}`, { params: { instructors } });

export const getStudentAppointments = async () =>
  await axios.get(`${URL}/appointments/student`);

export const getInstructorAppointments = async () =>
  await axios.get(`${URL}/appointments/instructor`);

export const getAdminAppointments = async () =>
  await axios.get(`${URL}/appointments/instructor`);

export const bookAppointment = async (role, appointment) =>
  await axios.post(`${URL}/appointments/${role}`, appointment);

export const bookAppointmentStudent = async (appointment) =>
  await axios.post(`${URL}/appointments/student`, appointment);

export const bookAppointmentInstructor = async (appointment) =>
  await axios.post(`${URL}/appointments/instructor`, appointment);

export const editAppointment = async (appointment, role) =>
  await axios.put(
    `${URL}/appointments/${role}/${appointment._id}`,
    appointment
  );

export const cancelAppointment = async (role, id) =>
  await axios.delete(`${URL}/appointments/${role}/${id}`);

export const addPrivateStudent = async (data) =>
  await axios.post(`${URL}/appointments/instructor/addPrivateStudent`, data);
/**--------------------- */

/** FOR SESSION LOGS */
export const getSessionLogs = async (role) =>
  await axios.get(`${URL}/sessionlogs/${role}`);

export const addSessionLog = async (data, role) =>
  await axios.post(`${URL}/sessionlogs/${role}`, data);

export const editSessionLog = async (id, data, role) =>
  await axios.put(`${URL}/sessionlogs/${role}/${id}`, data);

export const deleteSessionLog = async (sessionLogID, role) =>
  await axios.delete(`${URL}/sessionlogs/${role}/${sessionLogID}`);
/**----------------- */

/** FOR USERS */
export const getUsers = async () => await axios.get(`${URL}/administrator/`);

export const getUser = async (id) =>
  await axios.get(`${URL}/administrator/${id}`);

export const addUser = async (userInfo) =>
  await axios.post(`${URL}/administrator/`, { ...userInfo });

export const editUser = async (id, userInfo) =>
  await axios.put(`${URL}/administrator/${id}`, { ...userInfo });

export const deleteUser = async (id) =>
  await axios.delete(`${URL}/administrator/${id}`);
/**----------------- */

/** FOR PUBLIC */
export const requestResetPassword = async (email) =>
  await axios.post(`${URL}/authenticate/resetpassword`, { email });

export const resetPassword = async (password, token) =>
  await axios.post(`${URL}/authenticate/resetpassword/${token}`, { password });
/**----------- */
