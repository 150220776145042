import Toastify from "toastify-js";

export const handleError = (error, dispatch, customMessage) => {
  dispatch({ type: "SET_ERRORS", payload: error });

  //Chceks to see if API is offline
  if (error.message === "Network Error")
    return toastify(error.message, "danger");

  if (customMessage) return toastify(customMessage, "danger");

  //Checks to see if JWT token has expired
  if (error.response?.data?.sessionExpired) dispatch({ type: "LOGOUT" });

  //Handles API's 400 errors
  toastify(error.response?.data?.message, "danger", 4000);
};

export const toastify = (message, type, duration = 3000) => {
  if (!message || typeof type !== "string") return;

  Toastify({
    text: message,
    duration,
    close: true,
    gravity: "top",
    position: "center",
    className: type ? `background-${type}` : `background-primary`,
  }).showToast();
};
