import {
  GET_SESSION_LOGS,
  GET_SESSION_TYPES,
  ADD_SESSION_LOG,
  EDIT_SESSION_LOG,
  DELETE_SESSION_LOG,
  SORT_SESSION_LOGS,
  FILTER_SESSION_LOGS,
  SET_SESSION_LOGS,
  CLEAR_SESSION_LOGS,
  SET_CURRENT_SESSION_LOG,
  CLEAR_CURRENT_SESSION_LOG,
  SET_SESSION_LOGS_LOADING,
  SET_ERRORS,
  CLEAR_ERRORS,
  LOGOUT,
} from "../actions/types";

const initialState = {
  sessionLogs: [],
  sessionTypes: [],
  currentSessionLog: null,
  filteredSessions: [],
  sortedSessions: [],
  sessionLogErrors: null,
  sessionLogsLoading: false,
  config: {},
};

const sessionLogReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SESSION_LOGS:
      return {
        ...state,
        sessionLogs: action.payload.sessionLogs,
        config: {
          students: action.payload.students,
          instructors: action.payload.instructors,
        },
        sessionLogsLoading: false,
      };

    case GET_SESSION_TYPES:
      return {
        ...state,
        sessionTypes: action.payload,
        sessionLogsLoading: false,
      };

    case ADD_SESSION_LOG:
      return {
        ...state,
        sessionLogs: [action.payload, ...state.sessionLogs],
        currentSessionLog: action.payload,
        sessionLogsLoading: false,
      };

    case EDIT_SESSION_LOG:
      return {
        ...state,
        sessionLogs: state.sessionLogs.filter((sessionLog) =>
          sessionLog._id === action.payload._id ? action.payload : sessionLog
        ),
        sessionLogsLoading: false,
      };

    case DELETE_SESSION_LOG:
      return {
        ...state,
        sessionLogs: state.sessionLogs.filter(
          (sessionLog) => sessionLog._id !== action.payload._id
        ),
        sessionLogsLoading: false,
      };

    case SORT_SESSION_LOGS:
      return {
        ...state,
        sortedSessions: state.sessionLogs.sort(
          (a, b) => a[action.payload] - b[action.payload]
        ),
        sessionLogsLoading: false,
      };

    case FILTER_SESSION_LOGS:
      return {
        ...state,
        filteredSessions: state.sessionLogs.filter((session) =>
          session.contains(action.payload)
        ),
        sessionLogsLoading: false,
      };

    case SET_SESSION_LOGS:
      return {
        ...state,
        sessionLogs: action.payload,
        sessionLogsLoading: false,
      };

    case CLEAR_SESSION_LOGS:
      return {
        ...state,
        sessionLogs: [],
        sessionLogsLoading: false,
      };

    case SET_CURRENT_SESSION_LOG:
      return {
        ...state,
        currentSessionLog: action.payload,
        sessionLogsLoading: false,
      };

    case CLEAR_CURRENT_SESSION_LOG:
      return {
        ...state,
        currentSessionLog: null,
      };

    case SET_SESSION_LOGS_LOADING:
      return {
        ...state,
        sessionLogsLoading: true,
      };

    case SET_ERRORS:
      return {
        ...state,
        sessionLogErrors: action.payload,
        sessionLogsLoading: false,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        sessionLogErrors: null,
      };

    case LOGOUT:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default sessionLogReducer;
