import React from "react";
import { Form, Col } from "react-bootstrap";
import PropTypes from "prop-types";

const InstructorAndSession = ({
  instructorList,
  sessionList,
  onSelect,
  selectedInstructor,
  selectedSession,
}) => {
  return (
    <>
      <Form>
        <Form.Row>
          <Col sm>
            <Form.Group controlId="instructorID">
              <Form.Label>Select Instructor</Form.Label>
              <Form.Control
                as="select"
                onChange={onSelect}
                disabled
                value={selectedInstructor || ""}
              >
                <option disabled></option>
                {instructorList.map(({ _id, firstName, lastName }) => (
                  <option
                    key={_id}
                    value={_id}
                  >{`${firstName} ${lastName}`}</option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>

          <Col sm>
            <Form.Group controlId="sessionID">
              <Form.Label>Select Session</Form.Label>
              <Form.Control
                as="select"
                onChange={onSelect}
                disabled
                value={selectedSession}
              >
                <option disabled></option>
                {sessionList.map(({ _id, name }) => (
                  <option key={_id} value={_id}>
                    {name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Form.Row>
      </Form>

      <p className="text-secondary">
        * This area is filled out for you automatically based on your profile
      </p>
    </>
  );
};

InstructorAndSession.propTypes = {
  instructorList: PropTypes.array,
  sessionList: PropTypes.array,
  onSelect: PropTypes.func,
};

InstructorAndSession.defaultProps = {
  instructorList: [],
  sessionList: [],
};

export default InstructorAndSession;
