import React from "react";
import PrivateStudentForm from "../form";

const AddPrivateStudent = () => {
  return (
    <div className="container my-4">
      <h1 className="text-center text-primary mb-3">Add New Private Student</h1>

      <PrivateStudentForm type="add" />
    </div>
  );
};

export default AddPrivateStudent;
