import React from "react";
import { Tabs, Tab, Form } from "react-bootstrap";
import { useFieldArray, useFormContext } from "react-hook-form";
import { AiFillDelete } from "react-icons/ai";

import Button from "../../../layout/ButtonWithIcon";
import ScheduleItem from "./ScheduleItem";

const InstructorSchedule = () => {
  const { control, getValues } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "config.schedule",
  });

  return (
    <>
      <Form.Label>Schedule</Form.Label>
      <Button
        text="Add New Schedule Item"
        onClick={() => append()}
        className="mb-3"
        size="sm"
        variant="secondary"
      />

      {fields.length > 0 && (
        <Tabs>
          {fields.map((field, i) => {
            return (
              <Tab
                key={field.id}
                title={
                  <div className="d-flex">
                    <span className="mr-2">{`Schedule ${i + 1}`} </span>
                    <Button
                      icon={AiFillDelete}
                      variant="outline-danger"
                      size="sm"
                      onClick={() => remove(i)}
                    />
                  </div>
                }
                eventKey={`session${i}`}
                className="mt-2"
              >
                <ScheduleItem index={i} />
              </Tab>
            );
          })}
        </Tabs>
      )}
    </>
  );
};

export default InstructorSchedule;
