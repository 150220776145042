import {
  GET_APPOINTMENTS,
  GET_APPOINTMENT_SETTINGS,
  GET_INSTRUCTOR_AVAILABILITY,
  GET_STATUSES,
  ADD_APPOINTMENT,
  EDIT_APPOINTMENT,
  DELETE_APPOINTMENT,
  SORT_APPOINTMENTS,
  FILTER_APPOINTMENTS,
  SET_CURRENT_APPOINTMENT,
  CLEAR_CURRENT_APPOINTMENT,
  SET_SCHEDULE_LOADING,
  SCHEDULE_MODAL_CONTROL,
  SET_USERS_LOADING,
  ADD_USER,
} from "./types";

import axios from "axios";
import { toastify, handleError } from "../utils/actionsHandler";

import * as api from "../api";

export const getInstructorAvailability = (params) => async (dispatch) => {
  try {
    dispatch({ type: SET_SCHEDULE_LOADING });

    const res = await api.getInstructorAvailability(params);

    dispatch({ type: GET_INSTRUCTOR_AVAILABILITY, payload: res.data });
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const getAppointmentSettings = () => async (dispatch) => {
  try {
    dispatch({ type: SET_SCHEDULE_LOADING });

    const res = await api.getAppointmentSettings();

    dispatch({ type: GET_APPOINTMENT_SETTINGS, payload: res.data });
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const getAppointments =
  (userRole, instructors = []) =>
  async (dispatch) => {
    try {
      dispatch({ type: SET_SCHEDULE_LOADING });

      const res = await api.getAppointments(userRole, instructors);

      dispatch({ type: GET_APPOINTMENTS, payload: res.data.appointments });
    } catch (error) {
      handleError(error, dispatch);
    }
  };

export const getStatuses = () => async (dispatch) => {
  try {
    dispatch({ type: SET_SCHEDULE_LOADING });

    const res = await axios.get("/api/appointments/status");

    if (res.data.success) {
      dispatch({ type: GET_STATUSES, payload: res.data.statuses });
    }
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const bookAppointment = (appointment, role) => async (dispatch) => {
  try {
    dispatch({ type: SET_SCHEDULE_LOADING });

    delete appointment.studentName;

    const res = await api.bookAppointment(role, appointment);

    dispatch({ type: ADD_APPOINTMENT, payload: res.data.appointments });

    toastify(res.data.message, "success", 4000);
  } catch (error) {
    console.log(error);
    handleError(error, dispatch);
  }
};

export const editAppointment = (appointment, role) => async (dispatch) => {
  try {
    dispatch({ type: SET_SCHEDULE_LOADING });

    const res = await api.editAppointment(appointment, role);

    toastify(res.data.message, "success", 4000);

    dispatch({ type: EDIT_APPOINTMENT, payload: appointment });
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const cancelAppointment = (role, appointmentID) => async (dispatch) => {
  try {
    dispatch({ type: SET_SCHEDULE_LOADING });

    const res = await api.cancelAppointment(role, appointmentID);

    dispatch({ type: DELETE_APPOINTMENT, payload: res.data.appointment });

    toastify(res.data.message, "success", 4000);
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const modalToggle = () => (dispatch) =>
  dispatch({ type: SCHEDULE_MODAL_CONTROL });

export const sortAppointments = (value) => (dispatch) =>
  dispatch({ type: SORT_APPOINTMENTS, payload: value });

export const filterAppointments = (value) => (dispatch) =>
  dispatch({ type: FILTER_APPOINTMENTS, payload: value });

export const setCurrentAppointment = (appointment) => (dispatch) =>
  dispatch({ type: SET_CURRENT_APPOINTMENT, payload: appointment });

export const clearCurrentAppointment = (appointment) => (dispatch) =>
  dispatch({ type: CLEAR_CURRENT_APPOINTMENT, payload: appointment });

export const createPrivateStudent = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_USERS_LOADING });

    const res = await api.addPrivateStudent(data);

    toastify(res.data.message, "success", 5000);

    dispatch({ type: ADD_USER, payload: res.data.user });
  } catch (error) {
    handleError(error, dispatch);
  }
};
