import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Col, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Select from "react-select";

import BookingTimes from "../booking/BookingTimes";
import AutoComplete from "../../layout/AutoComplete";
import { getAppointmentSettings } from "../../../actions/scheduleActions";
import { provinces } from "../../../utils/provinces";

const AppointmentForm = ({
  data,
  onSubmit,
  handleDateSelect,
  handleTimeSelect,
  onUseStudentAddress,
  onAddressChange,
  onAddressAutoFill,
  onSelect,
  onChange,
  handleDurationSelect,
}) => {
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.schedule.scheduleLoading);
  const { role } = useSelector((state) => state.auth?.profile?.config);
  const {
    students = [],
    instructors = [],
    sessionTypes = [],
  } = useSelector((state) => state.schedule.settings);

  useEffect(() => {
    dispatch(getAppointmentSettings());
  }, []);

  const handleSlotSelect = (time) => {
    handleTimeSelect(time);
  };

  const statusOptions = [
    { id: "status", label: "Approved", value: "Approved" },
    { id: "status", label: "Pending", value: "Pending" },
    { id: "status", label: "Cancelled", value: "Cancelled" },
  ];

  return (
    <Form>
      <div className="mb-3">
        <h5 className="mb-3">Appointment Information</h5>
        <Form.Row>
          <Col>
            <Form.Group controlId="studentID">
              <Form.Label>Select Student</Form.Label>
              <Select
                options={students.map(({ _id, firstName, lastName }) => ({
                  id: "studentID",
                  value: _id,
                  label: `${firstName} ${lastName}`,
                }))}
                value={{
                  label: (() => {
                    const student = students.find(
                      ({ _id }) =>
                        _id === (data.studentID?._id || data.studentID)
                    );

                    if (student) {
                      return `${student.firstName} ${student.lastName}`;
                    } else {
                      return "Student...";
                    }
                  })(),
                  value: data.studentID?._id || data.studentID || "",
                }}
                onChange={onSelect}
              />
            </Form.Group>
          </Col>

          {role === "admin" && (
            <Col>
              <Form.Group controlId="instructorID">
                <Form.Label>Select Instructor</Form.Label>

                <Select
                  options={instructors.map(({ _id, firstName, lastName }) => ({
                    id: "instructorID",
                    value: _id,
                    label: `${firstName} ${lastName}`,
                  }))}
                  value={{
                    label: (() => {
                      const instructor = students.find(
                        ({ _id }) =>
                          _id === (data.instructorID?._id || data.instructorID)
                      );

                      if (instructor) {
                        return `${instructor.firstName} ${instructor.lastName}`;
                      } else {
                        return "Instructor...";
                      }
                    })(),
                    value: data.instructorID?._id || data.instructorID || "",
                  }}
                  onChange={onSelect}
                />
              </Form.Group>
            </Col>
          )}

          <Col>
            <Form.Group controlId="status">
              <Form.Label>Select Status</Form.Label>

              <Select
                options={statusOptions}
                value={statusOptions.find(
                  (status) => status.value === data.status
                )}
                onChange={onSelect}
              />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group controlId="sessionID">
              <Form.Label>Select Session</Form.Label>

              <Select
                options={sessionTypes.map(({ _id, name }) => ({
                  id: "sessionID",
                  value: _id,
                  label: name,
                }))}
                value={{
                  label: (() => {
                    const session = sessionTypes.find(
                      ({ _id }) =>
                        _id === (data.sessionID?._id || data.sessionID)
                    );

                    if (session) {
                      return session.name;
                    } else {
                      return "Session...";
                    }
                  })(),
                  value: data.sessionID?._id || data.sessionID || "",
                }}
                onChange={onSelect}
              />
            </Form.Group>
          </Col>
        </Form.Row>
      </div>

      <div className="mb-2">
        <h5 className="mb-2">Pickup Location</h5>
        <Form.Row>
          <Col>
            <Button
              variant="primary"
              size="sm"
              onClick={onUseStudentAddress}
              className="mb-3"
              disabled={!data.studentID}
            >
              Use Student's Address
            </Button>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <Form.Group>
              <Form.Label>Find Address</Form.Label>
              <AutoComplete
                onChange={onAddressAutoFill}
                placeholder="Type in Address Here..."
              />
            </Form.Group>
          </Col>
        </Form.Row>

        <Form.Row>
          <Col lg>
            <Form.Group controlId="address">
              <Form.Label>Current Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Your address"
                value={data.pickupLocation?.streetAddress || ""}
                onChange={onAddressChange}
                required
              />
            </Form.Group>
          </Col>

          <Col lg>
            <Form.Group controlId="city">
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                placeholder="Your city"
                value={data.pickupLocation?.city || ""}
                onChange={onAddressChange}
                required
              />
            </Form.Group>
          </Col>
        </Form.Row>

        <Form.Row>
          <Col>
            <Form.Group controlId="province">
              <Form.Label>Province</Form.Label>
              <Form.Control
                as="select"
                onChange={onAddressChange}
                value={data.pickupLocation?.province || ""}
                required
              >
                <option disabled hidden>
                  Select Province
                </option>
                {provinces.map((province) => (
                  <option key={province.id} value={province.text}>
                    {province.text}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>

          <Col>
            <Form.Group controlId="postalCode">
              <Form.Label>Postal Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Your postal code"
                value={data.pickupLocation?.postalCode || ""}
                onChange={onAddressChange}
                required
              />
            </Form.Group>
          </Col>
        </Form.Row>
      </div>

      <Form.Row>
        <Col lg>
          <Form.Group>
            <Form.Label>Select Date</Form.Label>
            <DatePicker
              selected={data.dateAndTime ? new Date(data.dateAndTime) : null}
              onChange={handleDateSelect}
              inline
              minDate={new Date()}
            />
          </Form.Group>
        </Col>

        <Col lg={8}>
          <Form.Group>
            <Form.Label>Select Time</Form.Label>
            <BookingTimes handleSlotSelect={handleSlotSelect} />
          </Form.Group>
        </Col>
      </Form.Row>

      <Form.Row>
        <Col>
          <Form.Group controlId="duration">
            <Form.Label>Appointment Duration</Form.Label>
            <Form.Control
              as="select"
              onChange={handleDurationSelect}
              value={data.duration || "1"}
            >
              <option value="1">1 Hour</option>
              <option value="2">2 Hours</option>
              <option value="3">3 Hours</option>
              <option value="4">4 Hours</option>
            </Form.Control>
          </Form.Group>
        </Col>
      </Form.Row>

      <Form.Group controlId="publicNotes">
        <Form.Label>Additional Notes</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          placeholder="Any additional notes"
          value={data.publicNotes || ""}
          onChange={onChange}
        />
      </Form.Group>

      <Form.Group controlId="privateNotes">
        <Form.Label>Private Notes</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          placeholder="Any private notes"
          value={data.privateNotes || ""}
          onChange={onChange}
        />
      </Form.Group>
    </Form>
  );
};

export default AppointmentForm;
