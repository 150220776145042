import React from "react";
import { Form, Col } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { LanguageContext } from ".";
import { LANGUAGE } from "../../utils/languages";

const EMAIL = "pay@new-drivers.ca";

const PaymentInfoSection = () => {
  const { language } = React.useContext(LanguageContext);
  const {
    formState: { errors },
    setValue,
  } = useFormContext();
  const { t } = useTranslation(null, {
    keyPrefix: "register.steps.paymentInformation",
  });

  const LANGUAGE_SET = {
    paymentMethod: {
      main: {
        [LANGUAGE.ENGLISH]: "Payment Method",
        [LANGUAGE.SPANISH]: "Metodo de Pago",
      },
      options: {
        placeholder: {
          [LANGUAGE.ENGLISH]: "Select a Payment Type...",
          [LANGUAGE.SPANISH]: "Seleccione un tipo de pago...",
        },
        cash: {
          [LANGUAGE.ENGLISH]: "Cash",
          [LANGUAGE.SPANISH]: "Efectivo",
        },
      },
      cash: {
        [LANGUAGE.ENGLISH]: (
          <ul>
            <li>
              Your receipt will be emailed to you once payment is processed
            </li>
            <li>Please email or call us to arrange a cash payment</li>
            <li>
              E-transfers can be sent to <a href={`mailto:${EMAIL}`}>{EMAIL}</a>
            </li>
          </ul>
        ),
        [LANGUAGE.SPANISH]: (
          <>
            Envienos un correo electronico o llamenos para concretar un pago en
            efectivo.
            <br />
            Su recibo le sera enviado por correo electronico una vez que se
            procese el pago
          </>
        ),
      },
    },
  };

  const handlePaymentMethodSelect = (e) => {
    const paymentMethod = e.target.value;

    setValue("paymentMethod", paymentMethod, { shouldValidate: true });
  };

  return (
    <>
      <Form.Row>
        <Col lg>
          <Form.Group>
            <Form.Control
              as="select"
              onChange={handlePaymentMethodSelect}
              defaultValue=""
            >
              <option disabled value="">
                {t("placeholder")}
              </option>
              <option value="Debit/Credit Card">Debit/Credit Card</option>
              <option value="E-Transfer">E-Transfer</option>
              <option value="Cash">
                {LANGUAGE_SET.paymentMethod.options.cash[language]}
              </option>
            </Form.Control>
            {errors.paymentMethod && (
              <Form.Text className="text-danger">* {t("error")}</Form.Text>
            )}
          </Form.Group>
        </Col>
      </Form.Row>

      <Form.Row>
        <Col lg>
          <ul>
            <li>{t("information.bullet1")}</li>
            <li>{t("information.bullet2")}</li>
            <li>
              {t("information.bullet3")} <a href={`mailto:${EMAIL}`}>{EMAIL}</a>
            </li>
          </ul>
        </Col>
      </Form.Row>
    </>
  );
};

export default PaymentInfoSection;
