import * as yup from "yup";
import i18next from "i18next";

import GetStarted from "../components/register/steps/GetStarted";
import PackageSection from "../components/register/PackageSection";
import UserInfoSection from "../components/register/UserInfoSection";
import LicenseInfoSection from "../components/register/LicenseInfoSection";
import PaymentInfoSection from "../components/register/PaymentInfoSection";
import ReleaseSection from "../components/register/ReleaseSection";

import { LANGUAGE } from "./languages";
import ReviewInformation from "../components/register/steps/ReviewInformation";
import axios from "axios";

export const MAX_FILE_SIZE = 1024 * 1024 * 10; //5MB
export const MIN_AGE = 15; //The minimum age of student

export const SPECIAL_CHARACTER_REGEX = /[-’/`~!#*$@%+=.,^&(){}[\]|;:”<>?\\]/g;
export const NUMBER_REGEX = /[0-9]/g;

export const usernameRequirements = { minLength: 5, maxLength: 20 };
export const passwordRequirements = { minLength: 5, maxLength: 32 };

export const phoneNumberMask = [
  "(",
  /[1-9]/,
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
export const postalCodeMask = [
  /[a-zA-z]/,
  /\d/,
  /[a-zA-Z]/,
  " ",
  /\d/,
  /[a-zA-z]/,
  /\d/,
];
export const driversLicenseMask = [
  /[a-zA-Z]/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const packages = () => [
  {
    id: 0,
    name: i18next.t("register.steps.packageSelection.basicPackage.title"),
    description: i18next.t(
      "register.steps.packageSelection.basicPackage.description"
    ),
    price: 620,
    active: true,
  },
  {
    id: 1,
    name: i18next.t("register.steps.packageSelection.preferredPackage.title"),
    description: i18next.t(
      "register.steps.packageSelection.preferredPackage.description"
    ),
    price: 750,
    active: true,
  },
  {
    id: 2,
    name: i18next.t("register.steps.packageSelection.private/car.title"),
    description: i18next.t(
      "register.steps.packageSelection.private/car.description"
    ),
    price: 0,
    active: true,
    url: null,
  },
  {
    id: 3,
    name: i18next.t("register.steps.packageSelection.carRental.title"),
    description: i18next.t(
      "register.steps.packageSelection.carRental.description"
    ),
    price: 0,
    active: true,
    url: null,
  },
];

export const stepsList = () => [
  {
    id: 0,
    title: i18next.t("register.steps.gettingStarted.title"),
    Step: GetStarted,
  },
  {
    id: 1,
    title: i18next.t("register.steps.packageSelection.title"),
    required: true,
    Step: PackageSection,
  },
  {
    id: 2,
    title: i18next.t("register.steps.basicInformation.title"),
    required: true,
    Step: UserInfoSection,
  },
  {
    id: 3,
    title: i18next.t("register.steps.driversLicense.title"),
    required: false,
    Step: LicenseInfoSection,
  },
  {
    id: 4,
    title: i18next.t("register.steps.paymentInformation.title"),
    required: true,
    Step: PaymentInfoSection,
  },
  {
    id: 5,
    title: i18next.t("register.steps.releaseStatement.title"),
    required: true,
    Step: ReleaseSection,
  },
  {
    id: 6,
    title: i18next.t("register.steps.reviewInformation.title"),
    required: true,
    Step: ReviewInformation,
  },
];

export const registerSchema = yup
  .object({
    selectedPackage: yup.object().required("Please select a package"),
    testInfo: yup
      .object({
        date: yup.date(),
        location: yup.string(),
        testType: yup.string(),
      })
      .nullable()
      .default(null),
    firstName: yup.string().required("Please provide your first name"),
    lastName: yup.string().required("Please provide your last name"),
    dateOfBirth: yup.date().optional(),
    email: yup
      .string()
      .email("Please provide a valid email address")
      .required("Please provide your email address"),
    phoneNumber: yup.string().required("Please provide your phone number"),
    address: yup
      .object({
        streetAddress: yup
          .string()
          .required("Please provide your street address"),
        city: yup.string().required("Please proivde the city of your address"),
        province: yup.string().default("Ontario"),
        postalCode: yup
          .string()
          .required("Please provide the postal code of your address"),
      })
      .required("Please provide your full address"),
    driversLicense: yup
      .object({
        number: yup.string().nullable(),
        expiryDate: yup.date().nullable(),
        imageFront: yup.string().nullable(),
      })
      .nullable(),
    paymentMethod: yup.string().required("Please select a method of payment"),
    eSignature: yup.string().required("Please provide your signature"),
    acceptedReleaseStatement: yup
      .bool()
      .oneOf([true], "Please accept the release statement")
      .required("Please accept the release statement"),
  })
  .required();

export const getPackages = async () => {
  const ENDPOINT = process.env.REACT_APP_MAINSITE_API_LINK;
  const res = await axios.get(`${ENDPOINT}/packages`, {
    transformRequest: (data, headers) => {
      delete headers.common["x-authentication-token"];
      return data;
    },
  });
  return res.data;
};

//https://stackoverflow.com/a/34064434
export const htmlDecode = (input) => {
  const doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
};
