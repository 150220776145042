import {
  LOGIN,
  LOGOUT,
  REGISTER,
  GET_PROFILE,
  EDIT_PROFILE,
  SET_AUTH_LOADING,
  CLEAR_ERRORS,
  ADD_TIME_OFF,
  DELETE_TIME_OFF,
  ADD_GOOGLE_AUTH,
  REMOVE_GOOGLE_AUTH,
} from "./types";

import * as api from "../api/index";
import setToken from "../utils/setToken";
import { toastify, handleError } from "../utils/actionsHandler";

export const login = (username, password) => async (dispatch) => {
  try {
    dispatch({ type: SET_AUTH_LOADING });
    dispatch({ type: CLEAR_ERRORS });

    const res = await api.login(username, password);
    setToken(res.data.token);

    dispatch({ type: LOGIN, payload: res.data });

    toastify(res.data.message, "success", 2000);
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const register = (formData) => async (dispatch) => {
  dispatch({ type: SET_AUTH_LOADING });
  dispatch({ type: CLEAR_ERRORS });

  try {
    const res = await api.register(formData);
    setToken(res.data.token);

    dispatch({ type: REGISTER, payload: res.data });

    toastify(res.data.message, "success");
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const getUserDetails = () => async (dispatch) => {
  dispatch({ type: SET_AUTH_LOADING });
  dispatch({ type: CLEAR_ERRORS });

  try {
    const res = await api.getProfile();

    dispatch({ type: GET_PROFILE, payload: res.data });
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const editProfile = (profileData) => async (dispatch) => {
  dispatch({ type: SET_AUTH_LOADING });
  dispatch({ type: CLEAR_ERRORS });

  try {
    const res = await api.editProfile(profileData);

    dispatch({ type: EDIT_PROFILE, payload: res.data });

    toastify(res.data.message, "success");
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const logout = () => (dispatch) => {
  dispatch({ type: LOGOUT });

  toastify("Logged Out Succesfully", "success");
};

export const setLoading = () => (dispatch) =>
  dispatch({ type: SET_AUTH_LOADING });

export const clearErrors = () => (dispatch) => dispatch({ type: CLEAR_ERRORS });

export const addTimeOff = (timeoff) => async (dispatch) => {
  dispatch({ type: SET_AUTH_LOADING });

  try {
    const res = await api.addTimeoff(timeoff);

    dispatch({ type: ADD_TIME_OFF, payload: res.data.timesoff });

    toastify(res.data.message, "success");
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const deleteTimeOff = (id) => async (dispatch) => {
  dispatch({ type: SET_AUTH_LOADING });

  try {
    const res = await api.deleteTimeoff(id);

    dispatch({ type: DELETE_TIME_OFF, payload: id });

    toastify(res.data.message, "success");
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const registerGoogleAuthorization = (data) => async (dispatch) => {
  dispatch({ type: SET_AUTH_LOADING });

  console.log(data);

  try {
    const res = await api.registerGoogleAuth(data);

    dispatch({ type: ADD_GOOGLE_AUTH });

    toastify(res.data.message, "success");
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const removeGoogleAuthorization = () => async (dispatch) => {
  dispatch({ type: SET_AUTH_LOADING });

  try {
    const res = await api.removeGoogleAuth();

    dispatch({ type: REMOVE_GOOGLE_AUTH });

    toastify(res.data.message, "success");
  } catch (error) {
    handleError(error, dispatch);
  }
};
