import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import { Form, Spinner, Pagination } from "react-bootstrap";
import { AiOutlineUserSwitch, AiOutlineUserAdd } from "react-icons/ai";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { UserSchema } from "../../../schemas/user";
import UserInformation from "./UserInformation";
import AdditionalInformation from "./additionalInformation";
import DriversLicenseInformation from "./driversLicense";
import Button from "../../layout/ButtonWithIcon";
import { addUser, editUser } from "../../../actions/userActions";
import { useSelector } from "react-redux";

const UserForm = ({ type, value }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(0);
  const { userLoading: loading } = useSelector((state) => state.users);

  const formMethods = useForm({
    resolver: yupResolver(UserSchema),
    defaultValues: value,
  });

  const submitUser = {
    add: addUser,
    edit: editUser,
  }[type];

  const onSubmit = async (data) => {
    try {
      await dispatch(submitUser(data));
      history.push(type === "add" ? "/users" : `/users/${data?._id}`);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePageChange = (page) => {
    if (page < 0 || page >= pages.length) return;

    setCurrentPage(page);
  };

  const pages = [
    UserInformation,
    DriversLicenseInformation,
    AdditionalInformation,
  ];

  const button = {
    text: type === "edit" ? "Edit User" : "Add User",
    icon: type === "edit" ? AiOutlineUserSwitch : AiOutlineUserAdd,
  };

  const backButton = {
    text: type === "edit" ? "Back to User Profile" : "Back to Users",
    link: type === "edit" ? `/users/${value._id}` : "/users",
  };

  const CurrentPage = pages[currentPage];

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <FormProvider {...formMethods}>
        <Form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <div className="d-flex mb-3">
            <Link to={backButton.link} className="btn btn-outline-secondary">
              {backButton.text}
            </Link>
            <Button
              text={
                loading ? <Spinner animation="border" size="sm" /> : button.text
              }
              icon={button.icon}
              type="submit"
              variant="secondary"
              className="ml-1"
              disabled={loading}
            />
          </div>

          <CurrentPage />

          <div className="mt-5 d-flex justify-content-center">
            <Pagination>
              <Pagination.First
                onClick={() => handlePageChange(0)}
                disabled={currentPage - 1 < 0}
              />
              <Pagination.Prev
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage - 1 < 0}
              />
              {pages.map((_, i) => (
                <Pagination.Item
                  key={i}
                  onClick={() => setCurrentPage(i)}
                  active={i === currentPage}
                >
                  {i + 1}
                </Pagination.Item>
              ))}
              <Pagination.Next
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage + 1 >= pages.length}
              />
              <Pagination.Last
                onClick={() => handlePageChange(pages.length - 1)}
                disabled={currentPage + 1 >= pages.length}
              />
            </Pagination>
          </div>
        </Form>
      </FormProvider>
    </MuiPickersUtilsProvider>
  );
};

export default UserForm;
