import React from "react";
import { Form } from "react-bootstrap";

const Certified = ({ userID, isCertified, type, onEditUser }) => {
  return type === "Student" ? (
    <Form.Check
      id={userID}
      type="switch"
      checked={isCertified}
      onChange={async (e) =>
        await onEditUser({ _id: userID, isCertified: e.target.checked })
      }
      style={{
        cursor: "pointer",
        transform: "scale(1.75)",
        marginLeft: "1rem",
        zIndex: 0,
      }}
    />
  ) : (
    <></>
  );
};

export default Certified;
