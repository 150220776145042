import React, { useState } from "react";
import { Button, OverlayTrigger, Popover, Spinner } from "react-bootstrap";

const Status = ({ loading, onEditUser, status, userID, cellProps }) => {
  const [currentPopover, setCurrentPopover] = useState({
    id: null,
    show: false,
  });

  const statusBackgroundRender = (status) => {
    const extraClass = "user-status ";
    const statusMap = {
      Approved: "approved",
      Pending: "pending",
      "Partially Paid": "partially-paid",
    }[status];
    return extraClass + statusMap;
  };

  const StatusPopover = ({ popoverProps, title, user }) => {
    const userStatuses = ["Approved", "Pending", "Partially Paid"];

    const updateUserStatus = async (status) => {
      onEditUser({ ...user, status });
      setCurrentPopover((prev) => ({ ...prev, show: false }));
    };

    return (
      <Popover {...popoverProps}>
        <Popover.Title as="h3">{title}</Popover.Title>
        <Popover.Content>
          <div
            style={{
              display: "grid",
              gridGap: "0.5rem",
              gridTemplateColumns: loading ? "" : "1fr 1fr",
              justifyContent: "center",
            }}
          >
            {loading ? (
              <Spinner animation="border" variant="primary" />
            ) : (
              userStatuses.map((status) => (
                <Button
                  key={status}
                  size="sm"
                  variant={
                    user.status === status ? "secondary" : "outline-secondary"
                  }
                  disabled={user.status === status}
                  style={{
                    cursor: user.status === status ? "not-allowed" : "pointer",
                  }}
                  onClick={() => updateUserStatus(status)}
                >
                  {status}
                </Button>
              ))
            )}
          </div>
        </Popover.Content>
      </Popover>
    );
  };

  return (
    <OverlayTrigger
      rootClose
      trigger={["click"]}
      placement="auto"
      overlay={(props) => (
        <StatusPopover
          popoverProps={props}
          title="User Status"
          user={{ _id: userID, status }}
        />
      )}
      show={currentPopover.id === userID ? currentPopover.show : false}
      onToggle={(show) => setCurrentPopover({ id: userID, show })}
    >
      <td {...cellProps} className={statusBackgroundRender(status)}>
        {status}
      </td>
    </OverlayTrigger>
  );
};

export default Status;
