import React from "react";
import BootstrapTable from "react-bootstrap/Table";
import { BsSortAlphaUp, BsSortAlphaDown } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Certified from "./Certified";
import Status from "./Status";
import UserActions from "./UserActions";
import { editUser } from "../../../actions/userActions";
import Loading from "../../layout/Loading";

const Table = ({
  tableProps,
  bodyProps,
  headers,
  page,
  onPrepareRow,
  onEdit,
  loading,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <div
      style={{
        position: "relative",
        marginBottom: "1rem",
        minHeight: "400px",
      }}
    >
      {loading && <Loading />}
      <BootstrapTable
        striped
        bordered
        hover
        responsive
        {...tableProps}
        className="mb-0"
      >
        <thead
          style={{
            position: "sticky",
            top: 0,
          }}
        >
          {headers.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers
                .filter(
                  (column) =>
                    ![
                      "Assigned Instructor",
                      "Payment Type",
                      "Package",
                      "Is Private",
                      "Address",
                      "Phone Number",
                    ].includes(column.Header)
                )
                .map((column) => (
                  <th {...column.getHeaderProps()}>
                    <div
                      {...column.getSortByToggleProps()}
                      className="text-secondary"
                      style={{ fontSize: "1.1rem", cursor: "pointer" }}
                    >
                      {column.render("Header")}{" "}
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <BsSortAlphaDown />
                        ) : (
                          <BsSortAlphaUp />
                        )
                      ) : (
                        ""
                      )}
                    </div>
                    {column.canFilter && column.Header !== "Actions"
                      ? column.render("Filter")
                      : null}
                  </th>
                ))}
            </tr>
          ))}
        </thead>
        <tbody {...bodyProps}>
          {page.length === 0 && (
            <tr>
              <td colSpan={7}>No Users...</td>
            </tr>
          )}
          {page.map((row, i) => {
            onPrepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells
                  .filter(
                    ({ column }) =>
                      ![
                        "Assigned Instructor",
                        "Payment Type",
                        "Package",
                        "Is Private",
                        "Address",
                        "Phone Number",
                      ].includes(column.Header)
                  )
                  .map((cell, i) => {
                    const { value, row, column } = cell;

                    if (column.Header === "Status") {
                      const { userID } = row.values;
                      return (
                        <Status
                          key={userID}
                          onEditUser={(user) => dispatch(editUser(user))}
                          loading={loading}
                          status={value}
                          userID={userID}
                          cellProps={cell.getCellProps()}
                        />
                      );
                    }

                    if (column.Header === "Certified") {
                      const { userID, type, isCertified } = row.values;
                      const certified = isCertified === "true";

                      return (
                        <td
                          {...cell.getCellProps()}
                          style={{
                            verticalAlign: "middle",
                            textAlign: "center",
                          }}
                        >
                          <div>
                            <Certified
                              onEditUser={(user) => dispatch(editUser(user))}
                              userID={userID}
                              isCertified={certified}
                              type={type}
                            />
                          </div>
                        </td>
                      );
                    }

                    if (column.Header === "Actions") {
                      const { userID } = row.values;
                      return (
                        <td
                          {...cell.getCellProps()}
                          style={{
                            verticalAlign: "middle",
                          }}
                        >
                          <UserActions
                            userID={userID}
                            onEditUser={onEdit}
                            onRedirect={(id) => history.push(`/users/${id}`)}
                          />
                        </td>
                      );
                    }

                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
              </tr>
            );
          })}
        </tbody>
      </BootstrapTable>
    </div>
  );
};

export default Table;
