import { combineReducers } from "redux";
import authReducer from "./authReducer";
import scheduleReducer from "./scheduleReducer";
import sessionLogReducer from "./sessionLogReducer";
import payrollReducer from "./payrollReducer";
import usersReducer from "./usersReducer";

export default combineReducers({
  auth: authReducer,
  schedule: scheduleReducer,
  sessionLogs: sessionLogReducer,
  payroll: payrollReducer,
  users: usersReducer
});
