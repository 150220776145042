import React from "react";
import { AiOutlineFileAdd } from "react-icons/ai";

import SessionLogsList from "./SessionLogsList";
import Button from "../layout/ButtonWithIcon";

const SessionLogs = () => {
  return (
    <div className="container my-4">
      <h1 className="text-center text-primary mb-3">Session Logs</h1>

      <Button
        text="Add New Session Log"
        icon={AiOutlineFileAdd}
        link="/newsessionlog"
        variant="secondary"
        className="mb-3"
      />

      <SessionLogsList />
    </div>
  );
};

export default SessionLogs;
