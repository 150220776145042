import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { BsCalendarPlus } from "react-icons/bs";
import { AiOutlineUserAdd } from "react-icons/ai";

import AppointmentsList from "./AppointmentsList";
import AppointmentModal from "./instructor/AppointmentModal";
import GoogleCalendar from "./instructor/GoogleCalendar";
import AppointmentDetailsModal from "./instructor/AppointmentDetailsModal";
import Calendar from "./Calendar";
import AppointmentBar from "./admin/AppointmentBar";

import {
  getAppointments,
  getAppointmentSettings,
} from "../../actions/scheduleActions";

import {
  getInstructorAvailability,
  bookAppointment,
  editAppointment,
  cancelAppointment,
} from "../../actions/scheduleActions";
import { useQuery } from "../../hooks/useQuery";
import { addressToString } from "../../utils/address";
import Button from "../layout/ButtonWithIcon";
import StudentStatus from "./student/StudentStatus";
import { getUsers } from "../../actions/userActions";

const Appointments = () => {
  const dispatch = useDispatch();
  const loggedInUser = useSelector((state) => state.auth.profile);
  const loading = useSelector((state) => state.schedule.scheduleLoading);
  const { students } = useSelector((state) => state.schedule.settings);
  const { appointments = [] } = useSelector((state) => state.schedule);
  const role = loggedInUser?.config?.role;

  const [currentAppointment, setCurrentAppointment] = useState({
    dateAndTime: new Date(),
    duration: "1", //Default 1 hour appointment duration
  });
  const [showModal, setShowModal] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [modalType, setModalType] = useState("add");

  const query = useQuery();

  const handleStudentQuery = () => {
    const studentID = query.get("student");

    if (!studentID) return;

    const foundStudent = students.find((user) => user._id === studentID);

    if (!foundStudent) return;

    setCurrentAppointment((prev) => ({
      ...prev,
      studentID: foundStudent._id,
      studentName: `${foundStudent.firstName} ${foundStudent.lastName}`,
    }));

    openModal();
  };

  const openModal = () => {
    setShowModal(true);
  };

  const handleClick = async () => {
    modalType === "add"
      ? await dispatch(bookAppointment(currentAppointment, role))
      : await dispatch(editAppointment(currentAppointment, role));

    setShowModal(false);
  };

  const handleDateSelect = (date) => {
    setCurrentAppointment((prevState) => ({ ...prevState, dateAndTime: date }));

    role === "instructor"
      ? dispatch(
          getInstructorAvailability({
            instructor: loggedInUser?._id,
            day: date,
            duration: currentAppointment.duration,
            address: addressToString(currentAppointment.pickupLocation),
          })
        )
      : dispatch(
          getInstructorAvailability({
            instructor:
              typeof currentAppointment.instructorID === "object"
                ? currentAppointment.instructorID._id
                : currentAppointment.instructorID,
            day: date,
            duration: currentAppointment.duration,
            address: addressToString(currentAppointment.pickupLocation),
          })
        );
  };

  const handleDurationSelect = (e) => {
    const duration = e.target.value;

    const { instructorID, dateAndTime } = currentAppointment;

    const id =
      typeof instructorID === "object" ? instructorID._id : instructorID;

    setCurrentAppointment((prev) => ({ ...prev, duration }));

    if (dateAndTime && instructorID) {
      dispatch(
        getInstructorAvailability({
          instructor: id,
          day: dateAndTime,
          duration,
          address: addressToString(currentAppointment.pickupLocation),
        })
      );
    }
  };

  const onUseStudentAddress = (e) => {
    if (!currentAppointment.studentID) return;

    const student = students.find(
      (student) => student._id === currentAppointment.studentID
    );

    if (!student) return;

    setCurrentAppointment((prev) => ({
      ...prev,
      pickupLocation: student.address,
    }));

    if (currentAppointment.instructor) {
      dispatch(
        getInstructorAvailability({
          instructor: currentAppointment.instructor,
          day: currentAppointment.dateAndTime,
          duration: currentAppointment.duration,
          address: addressToString(student.address),
        })
      );
    }
  };

  const onAddressChange = (e) => {
    const id = e.target.id;
    const value = e.target.value;

    setCurrentAppointment((prevState) => ({
      ...prevState,
      pickupLocation: { ...prevState.pickupLocation, [id]: value },
    }));
  };

  const onAddressAutoFill = (address) => {
    setCurrentAppointment((prev) => ({ ...prev, pickupLocation: address }));
  };

  const handleTimeSelect = (time) => {
    const [hour, minute] = [moment(time).hour(), moment(time).minute()];
    console.log(hour, minute);
    const addTimeToDate = moment(currentAppointment.dateAndTime)
      .startOf("day")
      .add(hour, "hours")
      .add(minute, "minutes")
      .toDate();

    setCurrentAppointment((prevState) => ({
      ...prevState,
      dateAndTime: addTimeToDate,
    }));
  };

  const onChange = (e) => {
    const id = e.target.id;
    const value = e.target.value;

    setCurrentAppointment((prevState) => ({ ...prevState, [id]: value }));
  };

  const onSelect = ({ id, value, label }) => {
    console.log({ id, value, label });
    if (id === "instructorID" && currentAppointment.dateAndTime) {
      dispatch(
        getInstructorAvailability({
          instructor: value,
          day: currentAppointment.dateAndTime,
          duration: currentAppointment.duration,
          address: currentAppointment.address,
        })
      );
    }

    setCurrentAppointment((prevState) => ({
      ...prevState,
      [id]: value,
      [id === "studentID" ? "studentName" : "sessionName"]: label,
    }));
  };

  const handleAdd = (date) => {
    if (date) {
      setCurrentAppointment({
        dateAndTime: date,
        duration: "1",
      });
      dispatch(
        getInstructorAvailability({
          instructor: loggedInUser?._id,
          day: date,
          address: addressToString(currentAppointment.pickupLocation),
        })
      );
    } else {
      setCurrentAppointment({
        dateAndTime: new Date(),
        duration: "1",
      });
    }

    setModalType("add");
    openModal();
  };

  const handleEdit = (appointment) => {
    dispatch(
      getInstructorAvailability({
        instructor: loggedInUser?._id,
        day: appointment.dateAndTime,
        duration: currentAppointment.duration,
        address: currentAppointment.address,
      })
    );
    setShowDetails(false);
    setCurrentAppointment(appointment);
    setModalType("edit");
    openModal();
  };

  const handleShowDetails = (appointment) => {
    setCurrentAppointment(appointment);
    setShowDetails(true);
  };

  const handleCancel = async () => {
    await dispatch(cancelAppointment(role, currentAppointment._id));
    setShowModal(false);
    setShowDetails(false);
  };

  useEffect(() => {
    dispatch(getAppointments(role));
    dispatch(getAppointmentSettings());

    if (role === "admin") dispatch(getUsers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!students) return;

    handleStudentQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [students]);

  return (
    <div className="container my-4">
      <h1 className="text-center text-primary mb-3">Appointments</h1>

      <div className="d-flex flex-wrap">
        {role !== "student" && (
          <Button
            text="Add New Appointment"
            icon={BsCalendarPlus}
            variant="secondary"
            className="mb-3 mr-3"
            onClick={() => handleAdd()}
          />
        )}

        {role === "instructor" && (
          <Button
            text="Add New Private Student"
            icon={AiOutlineUserAdd}
            variant="secondary"
            className="mb-3"
            link="/newPrivateStudent"
          />
        )}
      </div>

      {role === "student" && loggedInUser.status === "Pending" ? (
        <StudentStatus user={loggedInUser} />
      ) : (
        <>
          {role === "admin" && <AppointmentBar />}

          <AppointmentDetailsModal
            show={showDetails}
            appointment={currentAppointment}
            role={role}
            onClose={() => setShowDetails(false)}
            onEdit={handleEdit}
            onCancel={handleCancel}
          />

          <AppointmentModal
            show={showModal}
            type={modalType}
            handleClick={handleClick}
            handleCancel={handleCancel}
            handleClose={() => setShowModal(false)}
            formProps={{
              data: currentAppointment,
              handleDateSelect,
              handleTimeSelect,
              onSelect,
              onUseStudentAddress,
              onAddressChange,
              onAddressAutoFill,
              onChange,
              handleDurationSelect,
            }}
          />

          {role === "student" && (
            <>
              {loggedInUser?.status === "Partially Paid" &&
              appointments.length === 1 ? (
                <p className="text-danger text-bold">
                  * Please make a full payment to book more classes
                </p>
              ) : (
                <Link className="btn btn-secondary mb-3" to="/book">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.5rem",
                    }}
                  >
                    <BsCalendarPlus /> <span>Book New Appointment</span>
                  </div>
                </Link>
              )}
              <AppointmentsList handleEdit={handleEdit} />
            </>
          )}

          {role !== "student" && (
            <Calendar
              appointments={appointments}
              onAdd={handleAdd}
              onEdit={handleEdit}
              onEventClick={handleShowDetails}
            />
          )}

          {role === "instructor" && <GoogleCalendar />}
        </>
      )}
    </div>
  );
};

export default Appointments;
