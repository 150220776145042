import React from "react";
import { Form, Col } from "react-bootstrap";
import { useFormContext, Controller } from "react-hook-form";
import Select from "react-select";
import { useSelector } from "react-redux";
import { DatePicker } from "@material-ui/pickers";
import moment from "moment";

import Autocomplete from "../../layout/AutoComplete";
import { provinces } from "../../../utils/provinces";
import Error from "../../layout/InputError";

const UserInformation = ({ isInstructor }) => {
  const {
    formState: { errors },
    getValues,
    setValue,
    trigger,
  } = useFormContext();
  const { userLoading: loading, users } = useSelector((state) => state.users);

  const handleAutoComplete = (address) => {
    setValue("address", address, { shouldValidate: true });
  };

  const userTypeOptions = [
    { label: "Student", value: "student" },
    { label: "Instructor", value: "instructor" },
  ];

  const userType = getValues("config.role");

  const instructorOptions = users
    .filter((user) => user.config.role === "instructor")
    .map((user) => ({
      label: `${user.firstName} ${user.lastName}`,
      value: user._id,
    }));

  return (
    <>
      <h3>User Information</h3>

      {!isInstructor && (
        <Form.Row>
          <Col lg>
            <Form.Group>
              <Form.Label>Username</Form.Label>

              <Controller
                name="username"
                render={({ field }) => (
                  <Form.Control placeholder="Username..." {...field} />
                )}
              />
              {errors.username && <Error message={errors.username.message} />}
            </Form.Group>
          </Col>

          <Col lg>
            <Form.Group>
              <Form.Label>User Role</Form.Label>

              <Controller
                name="config.role"
                render={({ field: { onChange, value, ...rest } }) => (
                  <Select
                    placeholder="Select User Role..."
                    options={userTypeOptions}
                    value={userTypeOptions.find(
                      (option) => option.value === value
                    )}
                    onChange={({ value }) => {
                      onChange(value);
                      trigger("config.role");
                    }}
                    {...rest}
                  />
                )}
              />
              {errors.type && <Error message={errors.type.message} />}
            </Form.Group>
          </Col>

          {userType === "student" && (
            <>
              <Col lg>
                <Form.Group>
                  <Form.Label>Associated Instructor</Form.Label>

                  <Controller
                    name="config.associatedInstructor"
                    render={({ field: { onChange, value, ...rest } }) => (
                      <Select
                        placeholder="Select Instructor..."
                        isLoading={loading}
                        options={instructorOptions}
                        onChange={({ value }) => onChange(value)}
                        value={
                          value &&
                          instructorOptions.find(
                            (instructor) => instructor.value === value
                          )
                        }
                        {...rest}
                      />
                    )}
                  />
                </Form.Group>
              </Col>

              <Col lg={1.5}>
                <Form.Group>
                  <Form.Label>Private Student</Form.Label>

                  <Controller
                    name="config.isPrivate"
                    render={({ field }) => (
                      <Form.Check
                        className="form-checkbox"
                        checked={!!field.value}
                        {...field}
                      />
                    )}
                  />
                </Form.Group>
              </Col>
            </>
          )}
        </Form.Row>
      )}

      <Form.Row>
        <Col lg>
          <Form.Group>
            <Form.Label>First Name</Form.Label>

            <Controller
              name="firstName"
              render={({ field }) => (
                <Form.Control placeholder="First Name..." {...field} />
              )}
            />
            {errors.firstName && <Error message={errors.firstName.message} />}
          </Form.Group>
        </Col>

        <Col lg>
          <Form.Group>
            <Form.Label>Last Name</Form.Label>

            <Controller
              name="lastName"
              render={({ field }) => (
                <Form.Control placeholder="Last Name..." {...field} />
              )}
            />
            {errors.lastName && <Error message={errors.lastName.message} />}
          </Form.Group>
        </Col>

        <Col lg>
          <Form.Group>
            <Form.Label>Email</Form.Label>

            <Controller
              name="email"
              render={({ field }) => (
                <Form.Control placeholder="Email Address..." {...field} />
              )}
            />
            {errors.email && <Error message={errors.email.message} />}
          </Form.Group>
        </Col>
      </Form.Row>

      <Form.Row>
        {!isInstructor && (
          <Col lg>
            <Form.Group>
              <Form.Label>Password</Form.Label>

              <Controller
                name="password"
                render={({ field }) => (
                  <Form.Control
                    placeholder="Password..."
                    type="password"
                    {...field}
                  />
                )}
              />
            </Form.Group>
          </Col>
        )}

        <Col lg>
          <Form.Group>
            <Form.Label>Phone Number</Form.Label>

            <Controller
              name="phoneNumber"
              render={({ field }) => (
                <Form.Control placeholder="Phone Number..." {...field} />
              )}
            />
          </Form.Group>
        </Col>

        <Col lg>
          <Form.Group>
            <Form.Label>Date of Birth</Form.Label>

            <Controller
              name="dateOfBirth"
              render={({ field: { value, onChange } }) => (
                <DatePicker
                  onChange={onChange}
                  TextFieldComponent={({ onClick }) => {
                    return (
                      <Form.Control
                        onChange={() => {}}
                        onClick={onClick}
                        value={
                          value && moment(value).format("dddd, MMMM Do, YYYY")
                        }
                        placeholder="Date of Birth..."
                      />
                    );
                  }}
                  value={value}
                />
              )}
            />
          </Form.Group>
        </Col>
      </Form.Row>

      <Form.Row>
        <Col lg>
          <Form.Group>
            <Form.Label>Address</Form.Label>

            <Autocomplete
              placeholder="Type Address Here..."
              onChange={handleAutoComplete}
            />
          </Form.Group>
        </Col>
      </Form.Row>

      <Form.Row>
        <Col lg>
          <Form.Group>
            <Form.Label>Street Address</Form.Label>

            <Controller
              name="address.streetAddress"
              render={({ field }) => (
                <Form.Control placeholder="Street Address..." {...field} />
              )}
            />
            {errors.address?.street && (
              <Error message={errors.address?.street.message} />
            )}
          </Form.Group>
        </Col>

        <Col lg>
          <Form.Group>
            <Form.Label>City</Form.Label>

            <Controller
              name="address.city"
              render={({ field }) => (
                <Form.Control placeholder="City..." {...field} />
              )}
            />
            {errors.address?.city && (
              <Error message={errors.address?.city.message} />
            )}
          </Form.Group>
        </Col>

        <Col lg>
          <Form.Group>
            <Form.Label>Province</Form.Label>

            <Controller
              name="address.province"
              render={({ field: { onChange, value, ...rest } }) => (
                <Select
                  placeholder="Select Province..."
                  options={provinces.map((province) => ({
                    label: province.text,
                    value: province.text,
                  }))}
                  value={value ? { label: value, value } : null}
                  onChange={({ value }) => onChange(value)}
                  {...rest}
                />
              )}
            />
            {errors.address?.province && (
              <Error message={errors.address?.province.message} />
            )}
          </Form.Group>
        </Col>

        <Col lg>
          <Form.Group>
            <Form.Label>Postal Code</Form.Label>

            <Controller
              name="address.postalCode"
              render={({ field }) => (
                <Form.Control placeholder="Postal Code..." {...field} />
              )}
            />
            {errors.address?.postalCode && (
              <Error message={errors.address?.postalCode.message} />
            )}
          </Form.Group>
        </Col>
      </Form.Row>
    </>
  );
};

export default UserInformation;
