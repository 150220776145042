import React from "react";

import DriversLicense from "./DriversLicense";

const DriversLicenseInformation = () => {
  return (
    <>
      <h3>Drivers License Information</h3>
      <DriversLicense />
    </>
  );
};

export default DriversLicenseInformation;
