import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const PrivateRoute = ({ component: Component, userType, allowed, ...rest }) => {
  const {
    isAuthenticated,
    authLoading,
    profile: { config = {} },
  } = useSelector((state) => state.auth);

  return (
    <Route
      {...rest}
      render={(props) =>
        !isAuthenticated && !authLoading ? (
          <Redirect
            to={{
              pathname: "/login",
              state: { message: "Please login to access that page" },
            }}
          />
        ) : allowed.includes(config.role) ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/profile",
              state: {
                message: "You do not have permission to access that page",
              },
            }}
          />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  isAuthenticated: PropTypes.bool,
  loading: PropTypes.bool,
  allowed: PropTypes.array,
};

export default PrivateRoute;
