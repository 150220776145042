import React from "react";
import { Modal, Button } from "react-bootstrap";
import moment from "moment";

import { GiCancel } from "react-icons/gi";

import {
  AiFillCalendar,
  AiFillClockCircle,
  AiOutlineUser,
} from "react-icons/ai";

const AppointmentCancelModal = ({
  appointment,
  show,
  close,
  role,
  onCancel,
}) => {
  return (
    <Modal show={show} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>
          <GiCancel className="text-danger mb-1" /> Cancel Appointment
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        Are you sure you want to cancel your appointment?
        <br />
        <br />
        <AiOutlineUser />{" "}
        {role === "Student"
          ? `${appointment.instructorID.firstName} ${appointment.instructorID.firstName}`
          : `${appointment.studentID.firstName} ${appointment.studentID.lastName}`}
        <br />
        <AiFillCalendar />{" "}
        {moment(appointment.dateAndTime).format("dddd, MMMM Do, YYYY")}
        <br />
        <AiFillClockCircle /> {moment(appointment.dateAndTime).format("h:mm a")}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" onClick={onCancel}>
          Yes
        </Button>
        <Button variant="secondary" onClick={close}>
          No
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AppointmentCancelModal;
