import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, ButtonGroup, ProgressBar } from "react-bootstrap";
import moment from "moment";
import { Link } from "react-router-dom";
import { BsArrowBarLeft } from "react-icons/bs";

import InstructorAndSession from "./sections/InstructorAndSession";
import DateAndTime from "./sections/DateAndTime";
import PickupLocation from "./sections/PickupLocation";

import Confirmation from "./Confirmation";

import { getAppointmentSettings } from "../../../actions/scheduleActions";
import StepWrapper from "./StepWrapper";

const TOTAL_PAGES = 4;

const BookAppointment = () => {
  const dispatch = useDispatch();
  const { instructors = [], sessionTypes = [] } = useSelector(
    (state) => state.schedule.settings
  );
  const { profile } = useSelector((state) => state.auth);
  const { appointments } = useSelector((state) => state.schedule);
  const [currentPage, setCurrentPage] = useState(1);
  const [appointment, setAppointment] = useState({
    pickupLocation: {
      streetAddress: "",
      city: "",
      province: "",
      postalCode: "",
    },
    instructorID: "",
    instructorName: "",
    dateAndTime: new Date(),
    sessionID: "",
    sessionName: "",
    publicNotes: "",
    duration: "1",
  });

  const isPrivate = profile?.config?.isPrivate;

  useEffect(() => {
    dispatch(getAppointmentSettings());
  }, []);

  useEffect(() => {
    if (isPrivate && sessionTypes.length > 0) {
      const privateLesson = sessionTypes.find(
        (session) => session.name === "Private Lesson"
      );

      if (!privateLesson) return;

      setAppointment((prev) => ({
        ...prev,
        sessionID: privateLesson._id,
        sessionName: privateLesson.name,
      }));

      return;
    }

    if (appointments?.length > 0 && sessionTypes.length > 0) {
      const filteredAppointments = appointments.filter(
        (appt) => appt.status === "Approved"
      );

      //Each session is a predecessor of one another and must be followed in that order
      //Example: If you have completed driving session 1, your next session will be
      //driving session 2 only, and once you finish that the one after will be driving session 3
      let sessionTypesList = sessionTypes.sort((a, b) => a.name > b.name);
      for (const appointment of filteredAppointments) {
        sessionTypesList = sessionTypesList.filter(
          (sessionType) => sessionType.name !== appointment.sessionID?.name
        );
      }

      if (sessionTypesList.length > 0) {
        const nextSession = sessionTypesList[0];

        setAppointment((prev) => ({
          ...prev,
          sessionID: nextSession?._id,
          sessionName: nextSession?.name,
        }));
      }
    } else {
      setAppointment((prev) => ({
        ...prev,
        sessionID: sessionTypes[0],
        sessionName: sessionTypes[0]?.name,
      }));
    }
  }, [appointments, sessionTypes]);

  useEffect(() => {
    const { associatedInstructor } = profile?.config;

    //If user does not have an associated instructor
    //for whatever reason, set the first one for them
    const fallbackInstructor = instructors[0];

    if (instructors.length > 0) {
      const foundInstructor = instructors.find(
        ({ _id }) => _id === associatedInstructor
      );

      if (foundInstructor) {
        setAppointment((prev) => ({
          ...prev,
          instructorID: foundInstructor._id,
          instructorName: `${foundInstructor.firstName} ${foundInstructor.lastName}`,
        }));
      } else {
        setAppointment((prev) => ({
          ...prev,
          instructorID: fallbackInstructor,
          instructorName: `${fallbackInstructor.firstName} ${fallbackInstructor.lastName}`,
        }));
      }
    }
  }, [profile, instructors]);

  const previousPage = () =>
    setCurrentPage((prevState) => (prevState <= 1 ? prevState : prevState - 1));

  const nextPage = () =>
    setCurrentPage((prevState) =>
      prevState >= TOTAL_PAGES ? prevState : prevState + 1
    );

  const onChange = (e) => {
    const id = e.target.id;
    const value = e.target.value;

    setAppointment((prevState) => ({ ...prevState, [id]: value }));
  };

  const onSelect = (e) => {
    const id = e.target.id;
    const value = e.target.value;
    const text = e.target.selectedOptions;

    setAppointment((prevState) => ({
      ...prevState,
      [id]: value,
      [id === "instructorID" ? "instructorName" : "sessionName"]:
        text[0].innerText,
    }));
  };

  const onAddressChange = (e) => {
    const id = e.target.id;
    const value = e.target.value;

    setAppointment((prevState) => ({
      ...prevState,
      pickupLocation: { ...prevState.pickupLocation, [id]: value },
    }));
  };

  const onAddressAutoFill = (address) => {
    setAppointment((prevState) => ({
      ...prevState,
      pickupLocation: address,
    }));
  };

  const onOwnAddressSelect = (address) => {
    address
      ? setAppointment((prevState) => ({
          ...prevState,
          pickupLocation: address,
        }))
      : setAppointment((prevState) => ({
          ...prevState,
          pickupLocation: {
            streetAddress: "",
            city: "",
            province: "",
            postalCode: "",
          },
        }));
  };

  const handleDateSelect = (date) => {
    setAppointment((prevState) => ({ ...prevState, dateAndTime: date }));
  };
  const handleTimeSelect = (time) => {
    const [hour, minute] = [moment(time).hour(), moment(time).minute()];

    const addTimeToDate = moment(appointment.dateAndTime)
      .startOf("day")
      .add(hour, "hours")
      .add(minute, "minutes")
      .toDate();

    setAppointment((prevState) => ({
      ...prevState,
      dateAndTime: addTimeToDate,
    }));
  };

  const steps = [
    {
      title: "Selecting Instructor and Session",
      component: (
        <InstructorAndSession
          instructorList={instructors}
          sessionList={sessionTypes}
          onSelect={onSelect}
          selectedInstructor={appointment.instructorID}
          selectedSession={appointment.sessionID}
        />
      ),
      order: 1,
    },
    {
      title: "Pickup Location",
      component: (
        <PickupLocation
          address={appointment.pickupLocation}
          notes={appointment.publicNotes}
          onAddressChange={onAddressChange}
          onChange={onChange}
          onOwnAddressSelect={onOwnAddressSelect}
          onAddressAutoFill={onAddressAutoFill}
        />
      ),
      order: 2,
    },
    {
      title: "Date and Time",
      component: (
        <DateAndTime
          instructor={appointment.instructorID}
          handleDateSelect={handleDateSelect}
          handleTimeSelect={handleTimeSelect}
          date={appointment.dateAndTime}
          address={appointment.pickupLocation}
          duration={appointment.duration}
          onChange={onChange}
        />
      ),
      order: 3,
    },
    {
      title: "Finalize Appointment Details",
      component: <Confirmation appointment={appointment} />,
      order: 4,
    },
  ];

  return (
    <div className="container my-4">
      <h1 className="text-center text-primary mb-3">Book Appointment</h1>

      <Link
        to="/appointments"
        className="btn btn-secondary mb-3 d-flex align-items-center w-auto"
        style={{ maxWidth: "225px" }}
      >
        <BsArrowBarLeft style={{ fontSize: "1.5rem" }} />{" "}
        <span style={{ marginLeft: "0.5rem" }}>Back to Appointments</span>
      </Link>

      <ProgressBar
        now={(currentPage / TOTAL_PAGES) * 100}
        label={`${(currentPage / TOTAL_PAGES) * 100}%`}
        style={{ height: "2rem", fontSize: "1rem" }}
        className="mb-4"
      />

      {steps.map(
        (step) =>
          step.order === currentPage && (
            <StepWrapper {...step} key={step.order} />
          )
      )}

      <div className="text-center mt-4">
        <ButtonGroup size="lg">
          <Button
            variant="secondary"
            disabled={currentPage <= 1}
            onClick={previousPage}
          >
            Previous
          </Button>
          <Button
            variant="primary"
            disabled={
              currentPage >= TOTAL_PAGES ||
              !appointment.instructorID ||
              !appointment.sessionID
            }
            onClick={nextPage}
          >
            {currentPage === TOTAL_PAGES - 1 ? "Finalize" : "Next"}
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
};

export default BookAppointment;
