import React from "react";
import { Form, Col, Button } from "react-bootstrap";
import Select from "react-select";
import { Controller, useFormContext } from "react-hook-form";
import {
  DateTimePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { useSelector } from "react-redux";

import Error from "../../layout/InputError";

const SessionInfo = ({ index }) => {
  const {
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext();

  const { students, instructors } = useSelector(
    ({ sessionLogs }) => sessionLogs.config
  );

  const { profile } = useSelector(({ auth }) => auth);

  const role = profile?.config?.role;

  const handleSetLicense = (user) => {
    const userID = getValues(user);

    const users = {
      student: students,
      instructor: instructors,
    }[user];

    const found = users.find((current) => current?._id === userID);

    if (found && found.driversLicense)
      setValue(
        `sessions.${index}.${user}.licenseNumber`,
        found.driversLicense?.number,
        {
          shouldValidate: true,
        }
      );
  };

  const statusOptions = [
    { label: "Ongoing", value: "ongoing" },
    { label: "Completed", value: "completed" },
  ];

  const { status, student, instructor, start, end } =
    errors?.sessions?.[index] ?? {};

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Form.Row>
        {index === 9 && (
          <Col lg>
            <Form.Group>
              <Form.Label>Final Mark (out of 100)</Form.Label>
              <Controller
                name="finalMark"
                render={({ field }) => (
                  <Form.Control type="number" min={0} max={100} {...field} />
                )}
              />
            </Form.Group>
          </Col>
        )}
        <Col lg>
          <Form.Group>
            <Form.Label>Status</Form.Label>
            <Controller
              name={`sessions.${index}.status`}
              render={({ field: { onChange, value } }) => (
                <Select
                  options={statusOptions}
                  onChange={({ value }) => onChange(value)}
                  value={statusOptions.find((status) => status.value === value)}
                />
              )}
            />

            {status && <Error message={status.message} />}
          </Form.Group>
        </Col>

        <Col lg>
          <Form.Group>
            <Form.Label style={{ display: "block" }}>
              Start Date and Time
            </Form.Label>
            <Controller
              name={`sessions.${index}.start`}
              render={({ field: { value, onChange } }) => (
                <DateTimePicker
                  onChange={onChange}
                  TextFieldComponent={({ onClick }) => {
                    return (
                      <Form.Control
                        onChange={() => {}}
                        onClick={onClick}
                        value={
                          value &&
                          moment(value).format("dddd, MMMM Do - h:mm a")
                        }
                      />
                    );
                  }}
                  value={value}
                />
              )}
            />

            {start && <Error message={start.message} />}
          </Form.Group>
        </Col>

        <Col lg>
          <Form.Group>
            <Form.Label style={{ display: "block" }}>End Time</Form.Label>

            <Controller
              name={`sessions.${index}.end`}
              render={({ field: { value, onChange } }) => (
                <TimePicker
                  onChange={onChange}
                  TextFieldComponent={({ onClick }) => {
                    return (
                      <Form.Control
                        onChange={() => {}}
                        onClick={onClick}
                        value={value && moment(value).format("h:mm a")}
                      />
                    );
                  }}
                  value={value}
                />
              )}
            />

            {end && <Error message={end.message} />}
          </Form.Group>
        </Col>
      </Form.Row>

      <Form.Row className="mt-3">
        <Col lg>
          <Form.Group>
            <Form.Label
              style={{
                display: "flex",
                gap: "1rem",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Student Drivers License Number{" "}
              <Button
                size="sm"
                variant="outline-secondary"
                onClick={() => handleSetLicense("student")}
              >
                Use Students License
              </Button>
            </Form.Label>

            <Controller
              name={`sessions.${index}.student.licenseNumber`}
              render={({ field }) => {
                return <Form.Control type="text" {...field} />;
              }}
            />

            {student?.licenseNumber && (
              <Error message={student.licenseNumber.message} />
            )}
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label
              style={{
                display: "flex",
                gap: "1rem",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Instructor Drivers License Number{" "}
              <Button
                size="sm"
                variant="outline-secondary"
                onClick={() => {
                  role === "instructor"
                    ? setValue(
                        `sessions.${index}.instructor.licenseNumber`,
                        profile?.driversLicense?.number,
                        { shouldValidate: true }
                      )
                    : handleSetLicense("instructor");
                }}
              >
                {role === "admin"
                  ? "Use Instructors License"
                  : "Use Your License"}
              </Button>
            </Form.Label>

            <Controller
              name={`sessions.${index}.instructor.licenseNumber`}
              render={({ field }) => {
                return <Form.Control type="text" {...field} />;
              }}
            />

            {instructor?.licenseNumber && (
              <Error message={instructor.licenseNumber.message} />
            )}
          </Form.Group>
        </Col>
      </Form.Row>

      <Form.Row className="mt-3">
        <Col lg>
          <Form.Group>
            <Form.Label>Session Notes</Form.Label>

            <Controller
              name={`sessions.${index}.notes`}
              render={({ field }) => (
                <Form.Control
                  as="textarea"
                  placeholder="Notes..."
                  rows={4}
                  {...field}
                />
              )}
            />
          </Form.Group>
        </Col>
      </Form.Row>
    </MuiPickersUtilsProvider>
  );
};

export default SessionInfo;
