import axios from "axios";

import {
  GET_USERS,
  SET_CURRENT_USER,
  CLEAR_CURRENT_USER,
  SET_USERS_LOADING,
  ADD_USER,
  EDIT_USER,
  DELETE_USER,
} from "./types";
import { toastify, handleError } from "../utils/actionsHandler";

import * as api from "../api";

export const getUsers = () => async (dispatch) => {
  try {
    dispatch({ type: SET_USERS_LOADING });

    const res = await api.getUsers();

    dispatch({ type: GET_USERS, payload: res.data.users });
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const getStudents = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/users/students");

    dispatch({ type: GET_USERS, payload: res.data });
  } catch (error) {}
};

export const getInstructors = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/users/instructors");

    dispatch({ type: GET_USERS, payload: res.data });
  } catch (error) {}
};

export const addUser = (user) => async (dispatch) => {
  try {
    dispatch({ type: SET_USERS_LOADING });

    const res = await api.addUser(user);

    dispatch({ type: ADD_USER, payload: res.data.user });

    toastify(res.data.message, "success", 4000);
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const editUser = (user) => async (dispatch) => {
  try {
    dispatch({ type: SET_USERS_LOADING });

    const res = await api.editUser(user._id, user);

    dispatch({ type: EDIT_USER, payload: res.data.user });

    toastify(res.data.message, "success", 4000);
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const deleteUser = (userID) => async (dispatch) => {
  try {
    dispatch({ type: SET_USERS_LOADING });

    const res = await api.deleteUser(userID);

    dispatch({ type: DELETE_USER, payload: userID });

    toastify(res.data.message, "success", 4000);
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const setCurrentUser = (user) => (dispatch) => {
  dispatch({ type: SET_CURRENT_USER, payload: user });
};

export const clearCurrentUser = () => (dispatch) => {
  dispatch({ type: CLEAR_CURRENT_USER });
};
