import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { AiOutlineUserAdd } from "react-icons/ai";

import { getUsers, addUser, editUser } from "../../actions/userActions";
import { getBase64 } from "../../utils/base64";
import { toastify } from "../../utils/actionsHandler";
import UserList from "./UserList";
import UserModal from "./UserModal";
import Button from "../layout/ButtonWithIcon";

const MAX_FILE_SIZE = 1024 * 1024 * 10; //5MB

const User = () => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users.users);
  const loading = useSelector((state) => state.users.usersLoading);

  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("add");
  const [user, setUser] = useState({});
  const [paymentRecord, setPaymentRecord] = useState({
    method: "",
    amount: "",
    date: new Date(),
    notes: "",
  });

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  const handleChange = (e) => {
    setUser((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const handleConfigChange = (e) => {
    setUser((prev) => ({
      ...prev,
      config: { ...prev.config, [e.target.id]: e.target.value },
    }));
  };

  const selectUser = (id) => {
    const user = users.find((user) => user._id === id);

    if (!user) return;

    setUser(user);
    setModalType("edit");
    setShowModal(true);
  };

  const onPackageSelect = (selectedPackage) => {
    setUser((prev) => ({
      ...prev,
      config: { ...prev.config, selectedPackage },
    }));
  };

  const handleAddSchedule = () => {
    const { day, from, to } = user.config;

    if (!user.config?.schedule) {
      setUser((prev) => ({
        ...prev,
        config: {
          ...prev.config,
          schedule: [{ day, from, to }],
        },
      }));
      return;
    }

    const dayAlreadyThere = user.config?.schedule?.find(
      (item) => item.day === day
    );

    if (dayAlreadyThere) {
      const newSchedule = user.config?.schedule?.map((item) =>
        item.day === day ? { day, from, to } : item
      );

      setUser((prev) => ({
        ...prev,
        config: { ...prev.config, schedule: newSchedule },
      }));
    } else {
      setUser((prev) => ({
        ...prev,
        config: {
          ...prev.config,
          schedule: [{ day, from, to }, ...prev.config?.schedule],
        },
      }));
    }
  };

  const removeScheduleItem = (day) => {
    const filteredSchedule = user.config?.schedule?.filter(
      (item) => item.day !== day
    );

    setUser((prev) => ({
      ...prev,
      config: { ...prev.config, schedule: filteredSchedule },
    }));
  };

  const handleSubmit = async () => {
    // console.log(user);
    modalType === "add"
      ? await dispatch(addUser(user))
      : await dispatch(editUser(user));
    setShowModal(false);
  };

  const handleAddUser = () => {
    setUser({});
    setModalType("add");
    setShowModal(true);
  };

  const handleAddressChange = (e) => {
    const value = e.target.value;

    if (user.address) {
      setUser((prev) => ({
        ...prev,
        address: { ...prev.address, [e.target.id]: value },
      }));
    } else {
      setUser((prev) => ({ ...prev, address: { [e.target.id]: value } }));
    }
  };

  const onUserTypeSelect = (e) => {
    const role = e.target.value;
    setUser((prev) => ({ ...prev, config: { ...prev.config, role } }));
  };

  const onFrontImageAccepted = (file) => {
    const givenFile = file[0];

    getBase64(givenFile, (error, base64Image) => {
      if (error) {
        return;
      }
      setUser((prev) => ({
        ...prev,
        driversLicense: { ...prev.driversLicense, imageFront: base64Image },
      }));
    });
  };

  const onBackImageAccepted = async (file) => {
    const givenFile = file[0];

    getBase64(givenFile, (error, base64Image) => {
      if (error) {
        return;
      }

      setUser((prev) => ({
        ...prev,
        driversLicense: { ...prev.driversLicense, imageBack: base64Image },
      }));
    });
  };

  const handleReject = (errorArray) => {
    const errors = errorArray[0].errors;

    const responseMessage = {
      "file-invalid-type": "File must be an Image",
      "file-too-large": `File size must be ${MAX_FILE_SIZE}MBs or below`,
    };

    errors.forEach((error) =>
      toastify(responseMessage[error.code], "danger", 5000)
    );
  };

  const onImageClear = (key) => {
    setUser((prev) => ({
      ...prev,
      driversLicense: { ...prev.driversLicense, [key]: "" },
    }));
  };

  const handleLicenseChange = (e) => {
    const id = e.target.id;
    const value = e.target.value;
    setUser((prev) => ({
      ...prev,
      driversLicense: { ...prev?.driversLicense, [id]: value },
    }));
  };

  const handleRecordChange = (e) => {
    setPaymentRecord((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const addPaymentRecord = () => {
    const record = {
      ...paymentRecord,
      id: uuidv4(),
    };

    setUser((prev) => ({
      ...prev,
      config: {
        ...prev.config,
        payments: prev.config?.payments
          ? [record, ...prev.config?.payments]
          : [record],
      },
    }));
    setPaymentRecord({
      method: "",
      amount: "",
      date: new Date(),
      notes: "",
    });
  };

  const removePaymentRecord = (id) => {
    const filteredPayments = user.config?.payments?.filter(
      (payment) => payment.id !== id
    );

    setUser((prev) => ({
      ...prev,
      config: {
        ...prev.config,
        payments: filteredPayments,
      },
    }));
  };

  return (
    <div className="container my-4">
      <h1 className="text-center text-primary mb-3">Users</h1>

      <Button
        text="Add New User"
        icon={AiOutlineUserAdd}
        variant="secondary"
        link="/newUser"
        className="mb-3"
      />

      <UserList users={users} loading={loading} onEdit={selectUser} />

      <UserModal
        show={showModal}
        close={() => setShowModal(false)}
        type={modalType}
        onChange={handleChange}
        onConfigChange={handleConfigChange}
        user={user}
        paymentRecord={paymentRecord}
        addPaymentRecord={addPaymentRecord}
        removePaymentRecord={removePaymentRecord}
        onRecordChange={handleRecordChange}
        handlePackageSelect={onPackageSelect}
        handleLicenseChange={handleLicenseChange}
        imageHandle={{
          onFrontImageAccepted,
          onBackImageAccepted,
          handleReject,
          onImageClear,
          maxFileSize: MAX_FILE_SIZE,
        }}
        onUserTypeSelect={onUserTypeSelect}
        onAddSchedule={handleAddSchedule}
        removeScheduleItem={removeScheduleItem}
        handleAddressChange={handleAddressChange}
        submit={handleSubmit}
      />
    </div>
  );
};

export default User;
