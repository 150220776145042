import React from "react";
import { useFormContext } from "react-hook-form";

import PaymentTypeAndPackage from "./PaymentTypeAndPackage";
import Payments from "./Payments";
import InstructorSchedule from "./InstructorSchedule";
import OnlineCourse from "./OnlineCourse";

const AdditionalInformation = () => {
  const { getValues } = useFormContext();

  const userRole = getValues("config.role");

  return (
    <>
      <h3>Additional Information</h3>

      {userRole === "student" && (
        <>
          <PaymentTypeAndPackage />
          <OnlineCourse />
          <Payments />
        </>
      )}

      {userRole === "instructor" && (
        <>
          <InstructorSchedule />
        </>
      )}
    </>
  );
};

export default AdditionalInformation;
