import React, { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Pagination } from "react-bootstrap";
import moment from "moment";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";
import { matchSorter } from "match-sorter";

import { getSessionLogs } from "../../actions/sessionLogActions";
import Table from "./Table";

const SessionLogsList = ({ userID, userRole }) => {
  const dispatch = useDispatch();
  const { sessionLogs } = useSelector((state) => state.sessionLogs);
  const loading = useSelector((state) => state.sessionLogs.sessionLogsLoading);
  const loggedInUser = useSelector((state) => state.auth.profile);
  const role = loggedInUser.config.role;

  const columns = useMemo(
    () => [
      {
        Header: "Student",
        accessor: "student",
        id: "student",
      },
      {
        Header: "Date",
        accessor: "date",
        sortType: (a, b) => moment(a.date).isAfter(moment(b)),
      },
      {
        Header: "Sessions",
        accessor: "sessions",
      },
      { Header: "Action", accessor: "id" },
    ],
    []
  );

  useEffect(() => {
    dispatch(getSessionLogs(role));
    if (userID && userRole) toggleHideColumn("student");
  }, []);

  const styles = {
    completed: "bg-success text-light",
    incomplete: "bg-danger text-dark",
  };

  const data = useMemo(
    () =>
      sessionLogs
        .filter((session) => session?.[userRole]?._id === userID)
        .map((session) => ({
          student: `${session.student.firstName} ${session.student.lastName}`,
          date: moment(session?.timestamp).format("dddd, MMMM Do, YYYY"),
          sessions: String(session.sessions.length || 0),
          id: session._id,
        })),
    [sessionLogs]
  );

  function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
  }

  fuzzyTextFilterFn.autoRemove = (val) => !val;

  const filterTypes = useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  function DefaultColumnFilter({ column }) {
    const { filterValue, setFilter, Header } = column;
    return (
      <Form.Control
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
        placeholder="Search..."
      />
    );
  }

  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state,
    toggleHideColumn,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      autoResetPage: false,
      initialState: {
        pageIndex: 0,
        sortBy: [{ id: "date", desc: true }],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { pageIndex } = state;

  return (
    <>
      <Table
        tableProps={getTableProps()}
        bodyProps={getTableBodyProps()}
        headers={headerGroups}
        page={page}
        onPrepareRow={prepareRow}
        loading={loading}
      />

      <div className="d-flex justify-content-center">
        <Pagination>
          <Pagination.First
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          />
          <Pagination.Prev
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          />
          {pageOptions.map((index) => (
            <Pagination.Item
              key={index}
              active={index === pageIndex}
              onClick={() => gotoPage(index)}
              className="user-list-pagination"
            >
              {index + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next onClick={() => nextPage()} disabled={!canNextPage} />
          <Pagination.Last
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          />
        </Pagination>
      </div>
    </>
  );
};

export default SessionLogsList;
