import { object, string, date, number, array } from "yup";

export const SessionSchema = object({
  student: string().required("Student is required"),
  instructor: string().optional(),
  finalMark: number().optional(),
  sessions: array()
    .of(
      object({
        status: string().required("Status is required"),
        start: date().required("Start date and time is required"),
        end: date().required("End time is required"),
        notes: string().optional(),
        student: object({
          signature: string().required("Student signature is required"),
          licenseNumber: string().required(
            "Student drivers license is required"
          ),
        }),
        instructor: object({
          signature: string().required("Instructor signature is required"),
          licenseNumber: string().required(
            "Instructor drivers license is required"
          ),
        }),
      })
    )
    .default([]),
});
